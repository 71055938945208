.date-picker-container {
  height: 2.5rem !important;
  display: flex;
  align-items: center; /* 可选，确保内容垂直居中 */
}

.spectrum-Field {
  flex-grow: 1; /* 让内部元素适应容器高度 */
}

.date-picker-container .spectrum-Field {
  height: 100%;
}

.date-picker-container .spectrum-InputGroup {
  height: 100%;
}