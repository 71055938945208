/*---跳出視窗的Body-輸入欄 START ↓ ↓ ↓ */

.modal-view,
.gp {
    box-sizing: border-box;
    width: 100%;
}

.modal-view .gp h2 {
    font-size: 1rem;
    line-height: 100%;
    margin-top: 1rem;
    margin-bottom: .25rem;
}

.modal-view .gp .gp-greyline {
    box-sizing: border-box;
    border: #CCC 1px solid;
    width: 100%;
    border-radius: .25rem;
    padding: .5rem;
    margin: 0;
}

.modal-view .gp .gp-greyline:not(:first-child) {
    margin-top: .5rem;
}

.modal-view .gp .gp-greyline li {
    display: flex;
    flex-direction: row;
    cursor: default;
}

.modal-view .gp .gp-greyline li:hover {
    background-color: #F5F5F5;
    border-radius: .25rem;
}

.modal-view .gp .gp-greyline li.btn {
    padding: .25rem 0;
    cursor: pointer;
}

.modal-view .gp .gp-greyline li.btn:focus {
    background-color: #EBEEFB;
}

@media (width<=500px) {
    .modal-view .gp .gp-greyline li.btn {
        padding: .75rem 0;
    }
}


.modal-view .gp .gp-greyline li>div {
    padding: .5rem;
}

.modal-view .gp .gp-greyline hr {
    border: none;
    border-top: #CCC 1px solid;
}

.modal-view .gp .side1 {
    justify-content: start;
}

.modal-view .gp .side2 {
    justify-content: space-between;
}

.modal-view .gp .gp-greyline .lv1 .name {
    font-size: 1rem;
    line-height: 120%;
}

.modal-view .gp .gp-greyline .lv1 .content {
    font-size: 1rem;
    line-height: 120%;
    font-weight: 600;
    text-align: right;
}

.modal-view .gp .gp-greyline .lv1 .content.noAssociatedData {
    color: #F66F6F;
}

.modal-view .gp .gp-greyline .lv2 .name {
    font-size: .875rem;
    line-height: 120%;
    color: #AAA
}

.modal-view .gp .gp-greyline .lv2 .content {
    font-size: .875rem;
    line-height: 120%;
    font-weight: 400;
}

.modal-view .gp .gp-greyline .lv2.side1 .content {
    text-align: left;
}

.modal-view .gp .gp-greyline .lv2.side2 .content {
    text-align: right;
}

.gp-BGgrey {
    background: #F5F5F5;
    border-radius: .25rem;
    padding: 1.25rem;
    display: block;
}

.gp-BGgrey .title {
    color: #000;
    font-weight: 700;
    font-size: .875rem;
    margin-bottom: .5rem;
}

.gp-BGgrey .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.gp-BGgrey .item .label {
    color: #AAA;
    font-size: .625rem;
    margin-top: 0 !important;
    line-height: 1.2;
}

.gp-BGgrey .item .content {
    color: #000;
    font-size: .875rem;
    padding: .5rem 0;
}

.grid3-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media (width<=991px) {
    .grid3-1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

.gap20 {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
}

.bold {
    font-weight: 700;
}