.borderLeft{
    line-height: 1.2;
    border-left: 3px solid #9FBAFF;
    padding-left: 5px;
}

.programName{
    color: #3D71F8;
    font-size: 14px;
}

.workpiece{
    color: #000;
    font-size: 16px;
}

/* .dmtableMain .tleft {
    text-align: left;
}

.dmtableMain .tright {
    text-align: right;
} */