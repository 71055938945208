.pageErrDiv{
    display: flex;
    justify-content: center;
    align-items: center !important;
    width: 100vw;
    height: 100vh;
}

.pageErrImg{
    width: 80vw;
    max-width: 43.75rem;
}

.pageErrTitle{
    color: #3259CA;
    font-size: 1.5rem;
    text-align: center;
}

.pageErrContent{
    color: #3259CA;
    font-size: 0.9rem;
    text-align: center;
}

.pageErrButton{
    display: flex;
    justify-content: center;
    align-content: center;
}