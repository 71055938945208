/*舊版「班別稼動」樣式*/

/*幻燈片套件Button樣式*/
.slick-prev:before,
.slick-next:before {
  color: #3D71F8 !important;
}

/*稼動率右上角或左上角方框*/
.utilizationRate-great-text {
    background: #7CC629;
    color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 7rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
  }
  
  .utilizationRate-good-text {
    background: #37B9CC;
    color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 7rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  
  .utilizationRate-fair-text {
    background: #FFAA00;
    color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 7rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  
  .utilizationRate-poor-text {
    background: #F66F6F;
    color: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 7rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
  
  @media (max-width: 576px) {
  
    .utilizationRate-fair-text,
    .utilizationRate-great-text,
    .utilizationRate-good-text,
    .utilizationRate-poor-text {
      width: 5rem;
      height: 4rem;
      margin-left: 1.5rem;
    }
  }
  
  .circle-container {
    display: flex;
    align-items: center;
    /* Align items vertically */
  }


  
/*稼動率summary-border設定*/
.utilizationRate-great-summary {
    background: #DCF2C4;
    border: 2px solid #7CC629;
  }
  
  .utilizationRate-good-summary {
    background: #D3EDF3;
    border: 2px solid #37B9CC;
  }
  
  .utilizationRate-fair-summary {
    background: #FFF3B6;
    border: 2px solid #FFAA00;
  }
  
  .utilizationRate-poor-summary {
    background: #FFD4D4;
    border: 2px solid #F66F6F;
  }

/*稼動率機台狀態圓形*/
.utilizationRate-operating-circle {
    width: 1rem;
    height: 1rem;
    background: #7CC629;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-idle-circle {
    width: 1rem;
    height: 1rem;
    background: #FFAA00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-error-circle {
    width: 1rem;
    height: 1rem;
    background: #F66F6F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-notuse-circle {
    width: 1rem;
    height: 1rem;
    background: #888888;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*稼動率機台比率*/
.utilizationRate-great-rate {
    height: 1.8rem;
    background: #DCF2C4;
    border: 1px solid #7CC629;
    color: #7CC629;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-good-rate {
    height: 1.8rem;
    background: #D3EDF3;
    border: 1px solid #37B9CC;
    color: #37B9CC;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-fair-rate {
    height: 1.8rem;
    background: #FFF3B6;
    border: 1px solid #FFAA00;
    color: #FFAA00;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-poor-rate {
    height: 1.8rem;
    background: #FFD4D4;
    border: 1px solid #F66F6F;
    color: #F66F6F;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-holiday-rate {
    height: 1.8rem;
    border: 1px solid #AAAAAA;
    color: #AAAAAA;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .height05 {
    height: 0.5rem !important;
  }
  
  .height025 {
    height: 0.25rem !important;
  }
  /*稼動率機台班別停機*/
.utilizationRate-shift-great-shutdowns {
    height: 1.8rem;
    background: #FFF0F0;
    color: #F66F6F;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-good-shutdowns {
    height: 1.8rem;
    background: #FCD3D3;
    color: #F66F6F;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-fair-shutdowns {
    height: 1.8rem;
    background: #F9A8A8;
    color: #B53838;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-poor-shutdowns {
    height: 1.8rem;
    background: #F66F6F;
    color: #fff;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
/*班別title顏色*/
.utilizationRate-shift-title1 {
    height: 2rem;
    background: #F08976;
    color: #fff;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-title2 {
    height: 2rem;
    background: #F4D096;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-title3 {
    height: 2rem;
    background: #8FB9AB;
    color: #fff;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .utilizationRate-shift-title4 {
    height: 2rem;
    background: #314D63;
    color: #fff;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.utilizationCardTitle{
    font-size: 0.9rem !important;
    margin-bottom: 0;
    font-weight: 600;
}

.utilizationCardSubTitle{
    font-size: 0.75rem !important;
    margin-bottom: 0;
    color: #888;
    font-weight: 600;
}

.utilizationCardShowRate{
    font-size: 0.9rem !important;
    margin-bottom: 0;
}


/* .utilizationContent{
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    .utilizationContent{
        height: calc(100vh - 13rem);
    }
}

@media (max-width: 480px) {
    .utilizationContent{
        height: calc(100vh - 17rem);
    }
} */

.descriptionHeight{
    display: flex;
    align-items: flex-end;
    height: 2rem;
}

/* .block-title {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 1.25rem 1rem 0 1rem;
} */