/* .reportHeader{
    position: fixed !important;
    top: 50px !important;
    width: calc(100vw - 56px) !important;
} */

/*2024/05/27 新增：比較報告*/
.filterline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem;
}

.filterline>div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.filterline .rightgp>div,
.filterline .rightgp>button {
    margin-left: 0.625rem;
}

/* .btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    color: #FFF;
    background-color: #000;
    border-radius: 0.25rem;
    padding: 0.125rem 0.5rem;
}

.btn-outline-secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: #3D71F8 1px solid;
    color: #3D71F8;
    background-color: #FFF;
    border-radius: 0.25rem;
    padding: 0.125rem 0.5rem;
}

.btn-outline-secondary:hover {
    background-color: #F8F9FC;
}

.btn-outline-secondary:active {
    background-color: #F8F9FC;
} */

/* .btn .icon {
    font-family: 'Material Symbols Rounded', serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 130%;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
    box-sizing: border-box;
    margin-right: 0.25rem;
} */

button div:last-child {
    margin-right: 0;
}

.pg-report {
    box-sizing: border-box;
    padding: 1.5rem;
    background-color: #FFF;
    border-radius: 0.625rem;
    border: 1px solid #dce5ff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
}


@media (width <=768px) {
    .filterline {
        margin: 0.5rem;
    }

    .pg-report {
        margin: 0.5rem;
        padding: 1rem;
    }
}

.pg-report .titleline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.pg-report .titleline .titlegp {
    display: flex;
    flex-direction: column;
}

.pg-report .titleline .titlegp .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 0.25rem;
}

.pg-report .titleline .titlegp .subtitleBefore {
    font-size: 0.875rem;
    color: #3D71F8;
}

.pg-report .titleline .titlegp .subtitleAfter {
    font-size: 0.875rem;
    color: #AAA;
}

.pg-report .titleline .rightgp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pg-report .titleline .rightgp>div {
    margin-left: 1rem;
}


@media (width<=1024px) {
    #power_report_once .titleline {
        flex-direction: column;
    }

    #power_report_once .titleline .titlegp {
        width: 100%;
        margin-bottom: 1rem;
    }

    .pg-report .titleline .rightgp>div:first-child {
        margin-left: 0;
    }
}

.pg-report .inputgp {
    display: flex;
    flex-direction: column;
}

.pg-report .inputgp label {
    color: #888;
    font-size: 0.75rem;
}

.pg-report .inputgp .input input {
    margin: none;
    background-color: #FFF;
    border: #CCC 1px solid;
    height: 2rem;
}

.pg-report .inputgp .input input:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}

.pg-report .inputgp .input input:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
}

.pg-report .inputgp .input input:not(:first-child) {
    border-left: none;
}

.pg-report .inputgp .input .narrow {
    box-sizing: border-box;
    width: 4.5rem;
}

.pg-report .inputgp .input .wide {
    box-sizing: border-box;
    width: 7rem;
    /* background-color: #EEEEEE; */
}

.pg-report .inputgp .input .disabled{
    background-color: #EEEEEE;
}


@media (width<=600px) {
    .pg-report .titleline .rightgp>div {
        margin-left: 0;
        width: 100%;
        margin-top: 0.75rem;
    }

    .pg-report .inputgp .input .narrow {
        width: 5rem;
    }

    .pg-report .inputgp .input .wide {
        width: calc(100% - 5rem);
    }

    .pg-report .inputgp .input input {
        height: 3rem;
    }
}


.pg-report table {
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-collapse: collapse;
    text-indent: initial;
    border-spacing: 0;
}

.pg-report table th,
.pg-report table td {
    box-sizing: border-box;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    text-align: left;
}

.pg-report table td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.pg-report thead{
    border-bottom: #CCC 1px solid;
}

.pg-report table thead>tr th {
    background-color: #FFF;
    color: #3D71F8;
    font-size: 0.75rem;
    font-weight: 400;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.table-compare table tbody>tr:first-child {
    background-color: #EBEEFB !important;
    border-bottom: #444 2px solid;
}

.table-compare table tbody>tr:not(:first-child) {
    border-bottom: #CCC 1px solid;
}

.table-compare table tbody>tr:not(:first-child):hover {
    background-color: #F8F9FC;
}

.pg-report table .tdgp .subtd {
    font-size: 0.875rem;
    color: #3D71F8;
}

.pg-report table .bold {
    font-size: 1.125rem;
    font-weight: 600;
}

.pg-report table .bold .subtd {
    font-size: 0.75rem;
    font-weight: 400;
}

.pg-report table .lower {
    color: #7CC629;
}

.pg-report table .higher {
    color: #F66F6F;
}

.pg-report table .equal {
    color: #AAAAAA;
}

.pg-report .img-wp {
    width: 3.75rem;
    height: 2.875rem;
    border-radius: 0.25rem;
}

.pg-report .imgreplace {
    background-color: #EBEEFB;
    width: 3.75rem;
    height: 2.875rem;
    border-radius: 0.25rem;
}

.min-width6 {
    min-width: 6rem;
}

.min-width8 {
    min-width: 8rem;
}

.min-width10 {
    min-width: 10rem;
}

.sticky-left {
    position: sticky;
    left: 0;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-compare .dmtable>tbody>tr:first-child>.sticky-left {
    background-color: #EBEEFB;
    border-bottom: #444 2px solid;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.table-compare .dmtable>tbody>tr:not(:first-child)>.sticky-left {
    background-color: #FFF;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

/*.table-compare 中的 .tr 的 hover 和 active效果*/
.table-compare .dmtable>tbody>tr:not(:first-child):hover>.sticky-left {
    background-color: #f5f6fc;
}

.table-compare .dmtable>.sticky-top {
    background-color: #FFF;
}

.table-compare .dmtable>.tbody>.tr>.sticky-left {
    background-color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

@media (width <=350px) {
    .table-compare .dmtable>.tbody>.tr>.sticky-left {
        min-width: 5rem !important;
    }
}

.table-scroll-justify {
    box-sizing: border-box;
}

@media (width <=1104px) {
    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_report_once .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }
}
