.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 使容器充滿整個視窗高度 */
}

.login-board{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #A2B1D880;
    width: 380px;
    padding: 30px;
    border-radius: 1rem;
}

.input-error{
    border: 1px solid #F66F6F !important;
}

.create-Account-btn{
    background-color: #fff;
    border-radius: 0.5rem;
    color: #3259CA;
    padding: 10px 20px;
    border: 1px solid #9FBAFF;
}

.logoHeaderLoggin{
    width: 166px;
    height: 43px;
    display: flex;
    justify-content: center;
}

.message-board{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1.3rem #A2B1D880;
    width: 26.75rem;
    padding: 3rem;
    border-radius: 0.6rem;
}

.message-board-title{
    text-align: center;
    font-size: 1.875rem;
    line-height: 1.875rem;
}

.message-board-content{
    font-size: 1rem;
    margin: 0.5rem 0.45rem;
}