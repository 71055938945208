.noWorkpiece{
    color: #3D71F8;
    font-size: 1rem !important;
    cursor: pointer;
    display: flex;
    justify-items: center;
}

.button-cancel{
    border: #0d6efd;
    border: 1px solid #0d6efd;
    height: 2.5rem;
    font-size: 0.875rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    background-color: #fff;
    border-radius: 0.25rem;
}

/* .timePeriodCapture .titleline .rightgp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.timePeriodCapture .titleline .rightgp>div {
    margin-left: 1rem;
}

.timePeriodCapture .inputgp {
    display: flex;
    flex-direction: column;
} */
/* 
.inputgp label.grey, 
.inputgp .text.grey{
    color: #888;
    font-size: 0.75rem;
} */

/* 
.timePeriodCapture .inputgp .input input {
    margin: none;
    background-color: #FFF;
    border: #CCC 1px solid;
    height: 2rem;
}

.timePeriodCapture .inputgp .input input:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}

.timePeriodCapture .inputgp .input input:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
}

.timePeriodCapture .inputgp .input input:not(:first-child) {
    border-left: none;
}

.timePeriodCapture .inputgp .input .narrow {
    box-sizing: border-box;
    width: 4.5rem;
}

.timePeriodCapture .inputgp .input .wide {
    box-sizing: border-box;
    width: 7rem;
}

.timePeriodCapture .inputgp .input .disabled{
    background-color: #EEEEEE;
} */

/*--第1排 END--*/

/*--一般樣式style START--*/

.margin-bottom0 {
    margin-bottom: 0;
}

.layout-normal {
    padding: 1.25rem
}

.layout-normal>.title {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 0.25rem;
}

.layout-normal .remark-txt {
    font-size: 0.875rem;
    color: #AAA;
    font-weight: 400;
    margin-left: .25rem;
}

.layout-normal>.content {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #000;
}

/*--一般樣式style END--*/

.execution-overview {
    display: block;
}

.execution-overview>div {
    box-sizing: border-box;
    margin: 1.25rem 0.875rem;
}

.execution-overview .title {
    margin-bottom: 1.25rem;
}

.execution-overview .title .titile-main {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}

.execution-overview .title .title-sub-blue {
    color: #3D71F8;
    font-size: 0.875rem;
}

.execution-overview .title .title-sub-black {
    font-size: 0.75rem;
    color: #000;
}

.execution-overview .title .title-sub2::before {
    font-size: 0.75rem;
    content: "｜ ";
    color: #CCC;
}

.tag-icontext {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    width: fit-content;
    font-size: 0.75rem;
    color: #000;
    font-weight: 400;
    padding: 0.1875rem 0.625rem 0.1875rem 0.375rem;
    margin-left: 0.5rem;
    border-radius: 0.8125rem;
    vertical-align:top;/*這個vertical-align:top是用來修正當裡面的圖示<div>中沒有代碼時，整個<span>往下掉的問題*/
}

.tag-icontext>div:first-child {
    margin-right: 0.25rem;
}

table .tag-icontext {
    margin-left: 0;
}


.part-overview-timePeriodCapture {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
}

.part-overview-timePeriodCapture>.part {
    width: 40%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-Start;
    padding: 1.25rem 0 1.25rem 0.875rem;
}

.part-overview-timePeriodCapture>.box {
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.25rem 0.875rem 1.25rem 0.875rem;
}

.part-overview-timePeriodCapture>.part>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: flex-Start;
    align-items: center;
    margin-right: 1rem;
    border-radius: 0.5rem;
}

.part-overview-timePeriodCapture>.part>div:nth-child(1)>img {
    height: 4.667rem;
    width: 6rem;
    object-fit: contain;
    border-radius: 0.5rem;
}

.part-overview-timePeriodCapture>.part .imgreplace {
    background-color: #EBEEFB;
    height: 4.667rem;
    width: 6rem;
    border-radius: 0.5rem;
}

.part-overview-timePeriodCapture>.part>div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-break: normal;
    white-space: normal;
}

.part-overview-timePeriodCapture .part .title .titile-main {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}

.part-overview-timePeriodCapture .part .title .title-sub-blue {
    color: #3D71F8;
    font-size: 0.875rem;
}

.part-overview-timePeriodCapture .part .title .title-sub-black {
    font-size: 0.75rem;
    color: #000;
}

.part-overview-timePeriodCapture .part .title .title-sub2::before {
    font-size: 0.75rem;
    content: "｜ ";
    color: #CCC;
}


@media (width <=900px) {
    .part-overview-timePeriodCapture>.part {
        width: 100%;
    }

    .part-overview-timePeriodCapture>.box {
        width: 100%;
    }
}

@media (width <=650px) {
    .part-overview-timePeriodCapture>.box {
        padding-top: 0;
    }
    
    .part-overview-timePeriodCapture>.part>div:nth-child(2) {
        margin-right: 1rem;
    }
}

@media (width <=500px) {
    .part-overview-timePeriodCapture>.part {
        flex-wrap: wrap;
        padding-right: 0.875rem;
        flex-direction: column;
    }

    .part-overview-timePeriodCapture>.part>div:nth-child(1) {
        width: 100%;
        justify-content: center;
        margin-right: 0;
    }

    .part-overview-timePeriodCapture>.part>div:nth-child(2) {
        text-align: center;
        padding-top: 1rem;
        margin-right: 0;
    }
}

.card-value-purpleblue .card,
.card-value-white .card {
    padding: 0.625rem;
    border-radius: 0.3125rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 0;
    margin-left: 0.625rem;
}

.card-value-purpleblue .card {
    background-color: #F3F6FB;
}

.card-value-white .card {
    background-color: #FFF;
}

.card-value-purpleblue .card>.title,
.card-value-white .card>.title {
    color: #3D71F8;
    font-size: 0.875rem;
}

.card-value-purpleblue .card>.title {
    color: #3D71F8;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
}

.card-value-purpleblue .card>.content,
.card-value-white .card>.content {
    color: #000;
    font-size: 1rem;
}

@media (width<=768px) {
    .card-value-purpleblue .card>.title {
        color: #3D71F8;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }

    .card-value-purpleblue .card>.content {
        color: #000;
        font-size: 0.875rem;
    }
}

@media (width <=615px) {
    .card-value-white .card {
        flex: 30% ;
        margin-top: 0.5rem;
    }
    .card-value-white .card:nth-child(odd) {
        margin-left: 0 !important ;
    }
}

/*圖表區塊 START*/
#power_chart_timeline .table-scroll-justify>div {
    width: 100%;
}

#power_chart_timeline .table-scroll-justify>div>div {
    width: 100%;
    min-width: 600px;
}

@media (width <=696px) {

    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_chart_timeline .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }
}

.apache_power_now {
    height: 300px;
}

/*圖表區塊 END*/