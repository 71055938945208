.addPart{
    background-color: #EBEEFB;
    color: #3D71F8;
    font-size: larger;
}

.createPartCard{
    background-color: #EBEEFB;
    padding: 1rem;
    margin-bottom: 1rem;
}

.createPartTitle{
    font-size: 16px;
    font-weight: 600;
}

.button-previous {
    background-color: #3D71F8;
    color: #fff;
    border-radius: 0.25rem;
    border: none;
    padding: 0.5rem 0.75rem;
    white-space: nowrap;
}

.durationDay{
    background-color: #EBEEFB;
    padding: 8px 10px;
    color: #3259CA;
    width: auto;
}