.pwd-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 使容器充滿整個視窗高度 */
}

.message-board{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1.3rem #A2B1D880;
    width: 26.75rem;
    padding: 3rem;
    border-radius: 0.6rem;
}

.message-board-title{
    text-align: center;
    font-size: 1.875rem;
}

.message-board-content{
    font-size: 1rem;
    margin: 1rem 0.45rem;
}

/* .message-board-button{
    width: 100%;
    border-radius: 0.6rem;
    background-color: #000;
    height: 2.938rem;
    padding: 0.9rem;
    color: #fff;
    margin-top: 0.5rem;
} */

.pwd-board{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #A2B1D880;
    width: 23.75rem;
    padding: 3rem;
    border-radius: 1rem;
}

.user-account-title{
    color: #AAA;
    font-size: 0.5rem;
    margin-bottom: 0;
}

.user-account{
    color: #000;
    font-size: 0.9rem;
}

.input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #F8F9FC;
    border-radius: 0.75rem;
    border: none;
}

.input-error{
    border: 1px solid #F66F6F !important;
}

/* input {
    border: none;
    outline: none;
    box-sizing: border-box;
    background-color: #F8F9FC;
    padding: 10px;
    border-radius: 0.75rem;
} */

/* .text-blue{
    color: #3D71F8;
} */

/* .login-btn{
    background-color: #000000;
    border-radius: 0.5rem;
    color: #fff;
    width: 100%;
    padding: 10px 20px;
} */

.pwd-remark{
    font-size: 0.9rem;
}

.text-err{
    color: #F66F6F;
    font-size: 0.8rem;
}