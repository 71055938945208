.group-card-4pcs {
    display: grid;
    gap: 1rem;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
}

@media (width <=1360px) {
    .group-card-4pcs {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (width <=1000px) {
    .group-card-4pcs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (width <=650px) {
    .group-card-4pcs {
        grid-template-columns: repeat(1, 1fr);
    }
}

.group-card-4pcs .nodata {
    display:flex;    
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    height:40rem;    
    padding:0;
    text-align: center;
    color:#3D71F8;
}

.group-card-4pcs .nodata .notice {
    box-sizing: border-box;
    width:fit-content;
    color:#3D71F8;
    font-size: 0.875rem;
    background-color:#D4E0FF ;
    padding:0.25rem 0.875rem;
    border-radius: calc(0.25rem + 0.875rem);
    display:flex;
}

.group-card-4pcs .nodata .remark {
    margin: 4rem 2rem 0 2rem;
    display:flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.group-card-4pcs .nodata .remark > div,
.group-card-4pcs .nodata .remark > button {
    margin-bottom: 1rem;
}

.card-style1 {
    background-color: #fff;
    border: 1px solid #dce5ff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
    border-radius: 0.625rem;
    padding: 1.25rem;
}

.card-style1>div:nth-child(1) {
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
}

.card-style1>div:nth-child(2) {
    width: 100%;
    text-align: center;
}


.card-style1>div:nth-child(2)>div:nth-child(1) {
    color: #3D71F8;
    font-size: 0.75rem;
}

.card-style1>div:nth-child(2)>div:nth-child(2) {
    color: #000;
    font-size: 1rem;
    font-weight: 700;
}

.card-style1>div:nth-child(1)>div {
    position: relative;
    height: 8rem;
    width: 10.3rem;
    border-radius: 0.5rem;
}

.card-style1>div:nth-child(1) img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8rem;
    width: 10.3rem;
    object-fit: contain;
    border-radius: 0.5rem;
}

.card-style1>div:nth-child(1)>div .imgreplace {
    background-color: #EBEEFB;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
}

.card-style1-end {
    background-color: #EBEEFB;
    border: 1px solid #dce5ff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
    border-radius: 0.625rem;
    padding: 1.25rem;
    color: #3D71F8;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-style1-end>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card-style1-end>div>div {
    width: 100%;
    text-align: center;
    margin-bottom: 0.625rem;
    margin-top: 1rem;
}

.box-style1 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.5rem;
}

.box-style1>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.5rem;
    padding: 0.5rem;
    flex: 1 1 0;
    border-radius: 0.3125rem;
}

.ml-0 {
    margin-left: 0 !important;
}

.icon16 {
    font-size: inherit;
    font-size: 1rem !important;
}

.icon12 {
    font-size: inherit;
    font-size: 0.75rem !important;
}

.box-style1>div {
    font-size: 0.875rem;
}

.box-style2 {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
}

.box-style2>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.875rem;
}

.box-style2>div>div:nth-child(1) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.box-style2>div>div:nth-child(1)>div:nth-child(1) {
    border-radius: 50%;
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}

/*--4/25 新增---*/

.part-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.part-overview>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: flex-Start;
    margin: 1.25rem 0 1.25rem 0.875rem;
    box-sizing: border-box;
}

.part-overview>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: flex-Start;
    align-items: center;
    margin-right: 1rem;
    height: 7rem;
    width: 9rem;
    border-radius: 0.5rem;
}

.part-overview>div:nth-child(1)>div:nth-child(1)>img {
    height: 7rem;
    width: 9rem;
    object-fit: contain;
    border-radius: 0.5rem;
}

.part-overview>div:nth-child(1)>div:nth-child(1)>div {
    height: 7rem;
    width: 9rem;
    object-fit: contain;
    border-radius: 0.5rem;
    background-color: #EBEEFB;
}

.part-overview>div:nth-child(1) .imgreplace {
    background-color: #EBEEFB;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
}

.part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(1) {
    color: #AAA;
    font-size: 0.875rem;
    height: 1rem;
}

.part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(2) {
    height: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-break: normal;
    white-space: normal;
    line-height: 110%;
}

.part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(2)>div>span:nth-child(1) {
    color: #3D71F8;
    font-size: 0.875rem;
}

.part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(2)>div>span:nth-child(2) {
    font-size: 0.75rem;
    color: #000;
}

.part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(2)>div>span:nth-child(2)::before {
    font-size: 0.75rem;
    content: "｜ ";
    color: #CCC;
}

.part-overview>div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (width <=900px) {
    .part-overview>div:nth-child(1) {
        width: 100%;
    }

    .part-overview>div:nth-child(2) {
        width: 100%;
    }
}

@media (width <=500px) {
    .part-overview>div:nth-child(1) {
        flex-wrap: wrap;
        margin-right: 0.875rem;
        margin-bottom: 0.875rem;
        flex-direction: column-reverse;
    }

    .part-overview>div:nth-child(1)>div:nth-child(1) {
        width: 100%;
        justify-content: center;
    }

    .part-overview>div:nth-child(1)>div:nth-child(2)>div:nth-child(2) {
        height: 4rem;
    }
}

.part-overview .title .titile-main {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}

.part-overview .title .title-sub-blue {
    color: #3D71F8;
    font-size: 0.875rem;
}

.part-overview .title .title-sub-black {
    font-size: 0.75rem;
    color: #000;
}

.part-overview .title .title-sub2::before {
    font-size: 0.75rem;
    content: "｜ ";
    color: #CCC;
}

.box-score-scoreup {
    box-sizing: border-box;
    background-color: #F3F6FB;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 12rem;
    height: 7rem;
    border-radius: 0.25rem;
    padding: 1rem;
    margin: 1.25rem 0.875rem 1.25rem 0;
}

.box-score-scoreup>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.box-score-scoreup>div:nth-child(1)>div:nth-child(1) {
    box-sizing: border-box;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
    color: #9FBAFF;
    margin-right: 0.5rem;
}

.box-score-scoreup>div:nth-child(1)>div:nth-child(2) {
    color: #000;
    font-size: 0.875rem;
    line-height: 150%;
}

@media (width <=1200px) {
    .box-score-scoreup {
        width: 11rem;
    }
}

@media (width <=1000px) {
    .box-score-scoreup {
        width: 10.5rem;
    }
}

@media (width <=900px) {
    .part-overview>div:nth-child(2) {
        margin-left: 0.875rem;
        justify-content: space-between;
    }

    .box-score-scoreup {
        margin: 0 0.875rem 1.25rem 0;
    }

    .box-score-scoreup {
        width: auto;
        flex: 1 1 0;
    }
}

@media (width <=600px) {
    .part-overview>div:nth-child(2) {
        flex-wrap: wrap;
        margin: 0 0 0.75rem 0.875rem;
        align-items: center;
    }

    .box-score-scoreup {
        width: 100%;
        flex: 1 1 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 3.5rem;
        margin: 0 0.875rem 0.5rem 0;
    }

    .box-score-scoreup>div:nth-child(2) {
        margin-left: 1rem;
    }
}

/*--第1排 END--*/

/*--第2排 START--*/
.flex-flexible {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.flex-flexible>div {
    box-sizing: border-box;
    flex: 1 1 0;
    margin: 0 0.625rem;
}


@media (width <=750px) {
    .flex-flexible {
        display: block;
        width: 100%;
    }

    .flex-flexible>div {
        width: calc(100% - 2.5rem);
        margin: 0.625rem 0;
    }
}

.box-score-and-barchart {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 2.5rem 1fr;
    padding: 0.625rem;
}

.box-score-and-barchart>div:nth-child(1) {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-score-and-barchart>div:nth-child(1)>div {
    font-size: 1.25rem;
    margin: 0.625rem;
}

.box-score-and-barchart>div:nth-child(2) {
    font-size: 1rem;
    font-weight: 500;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
}

.box-score-and-barchart>div:nth-child(4) {
    width: 100%;
}

.box-score-and-barchart>div:nth-child(4)>div:nth-child(1) {
    padding-left: 0.625rem;
    padding-top: 0.3125rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.box-score-and-barchart>div:nth-child(4)>div:nth-child(1)>div {
    flex: 1 1 0;
    padding-right: 1rem;
}

.box-score-and-barchart>div:nth-child(4)>div:nth-child(2) {
    margin-left: 0.625rem;
    margin-top: 1rem;
    height: 4.5rem;
    width: calc(100% - 1.25rem);
}

@media (width <=1024px) {
    .box-score-and-barchart {
        grid-template-columns: 2rem 1fr;
        grid-template-rows: 2rem 1fr;
    }

    .box-score-and-barchart>div:nth-child(1)>div {
        font-size: 1rem;
    }

    .box-score-and-barchart>div:nth-child(2) {
        font-size: 0.875rem;
        height: 2rem;
    }

    .box-score-and-barchart .text-black-20-bold {
        font-size: 1rem;
    }
}

@media (width <=750px) {
    .box-score-and-barchart {
        grid-template-columns: 2.5rem 1fr;
        grid-template-rows: 2.5rem 1fr;
    }

    .box-score-and-barchart>div:nth-child(1)>div {
        font-size: 1.25rem;
    }

    .box-score-and-barchart>div:nth-child(2) {
        font-size: 1rem;
        height: 2.5rem;
    }

    .box-score-and-barchart .text-black-20-bold {
        font-size: 1.25rem;
    }
}

@media (width <=280px) {
    .box-score-and-barchart .text-black-20-bold {
        font-size: 1rem;
    }
}

.chart-bar-radius {
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.chart-bar-radius>div {
    max-width: 3rem;
    background-color: #9FBAFF;
    border-radius: 1.5rem 1.5rem 0 0;
    margin-right: 0.625rem;
    flex: 1 1 0;
}

.chart-bar-radius>div:hover {
    background-color: #3D71F8;
}

@media (width <=950px) {
    .chart-bar-radius>div:nth-child(11),
    .chart-bar-radius>div:nth-child(12),
    .chart-bar-radius>div:nth-child(13),
    .chart-bar-radius>div:nth-child(14),
    .chart-bar-radius>div:nth-child(15) {
        display: none;
    }
}

.mr-0 {
    margin-right: 0 !important;
}

/*--第2排 END--*/

/*--第3排 START--*/

.flex-table .dmtable {
    width: 100%;
}

.flex-table .dmtable>.thead>.tr {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-table .dmtable>.tbody>.tr {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-table .dmtable>.thead>.tr>div,
.flex-table .dmtable>.tbody>.tr>div {
    padding: 0 1rem;
}

.flex-table .dmtable>.tbody>.tr>div {
    vertical-align: middle;
}

.flex-table .dmtable>.tbody>.tr>div>.tag {
    margin-right: 0.2rem;
    white-space: nowrap;
}

.flex-table .dmtable .tablecell-tag {
    display: flex;
    flex-wrap: wrap;
}

.box-list-whiterow {
    padding: 1.25rem;
    border-radius: 0.625rem;
    background-color: #EBEEFB;
    box-shadow: 0px 0px 0px 0.125rem rgba(159, 186, 255, 0.25);
}

.box-list-whiterow>.title {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 1rem;
}

.box-list-whiterow .dmtable>.thead>.tr {
    color: #3259CA;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    line-height: 110%;
}

.box-list-whiterow .dmtable>.tbody>.tr {
    color: #000;
    box-sizing: border-box;
    font-size: 0.875rem;
    background-color: #fff;
    border: 1px solid #dce5ff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
    border-radius: 0.625rem;
    padding: 1.25rem 0;
    margin-bottom: 0.25rem;
}

@media (width <=1040px) {

    .box-list-whiterow .dmtable>.thead>.tr,
    .box-list-whiterow .dmtable>.tbody>.tr {
        width: fit-content;
    }
}

@media (width <=750px) {
    .box-list-whiterow .dmtable>.tbody>.tr {
        font-size: 1rem;
    }
}

.box-list-whiterow .dmtable>.thead>.tr>.sticky-left {
    background-color: #EBEEFB;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.box-list-whiterow .dmtable>.sticky-top {
    background-color: #EBEEFB;
}

.box-list-whiterow .dmtable>.tbody>.tr>.sticky-left {
    background-color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

@media (width <=350px) {
    .box-list-whiterow .dmtable>.tbody>.tr>.sticky-left {
        min-width: 5rem !important;
    }
}

/*.box-list-whiterow 中的 .tr 的 hover 和 active效果*/
.box-list-whiterow .dmtable>.tbody>.tr:hover {
    background-color: #f5f6fc;
}

.box-list-whiterow .dmtable>.tbody>.tr>div>span:hover {
    color: #3D71F8;
    font-weight: 500;
    cursor: pointer;
}

.box-list-whiterow .dmtable>.tbody>.tr>div>span:active {
    color: #3259CA;
}

.box-list-whiterow .dmtable>.tbody>.tr:hover>.sticky-left {
    background-color: #f5f6fc;
}

/*.box-list-whiterow 中的 .tr 的 hover 效果 (END)*/

.box-list-whiterow .dmtable .tag {
    width: fit-content;
    padding: 0.125rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    margin-bottom: 0.2rem;
}

.box-list-whiterow  .nodata {
    display:flex;    
    justify-content: center;
    align-items: center;
    width:100%;
    height:10rem;    
    padding:0;
}

.box-list-whiterow  .nodata .notice {
    box-sizing: border-box;
    width:fit-content;
    color:#3D71F8;
    font-size: 0.875rem;
    background-color:#D4E0FF ;
    padding:0.25rem 0.875rem;
    border-radius: calc(0.25rem + 0.875rem);
    display:flex;
}

.min-width11 {
    min-width: 11rem;
}

.min-width9 {
    min-width: 9rem;
}

.min-width7 {
    min-width: 7rem;
}

.min-width5 {
    min-width: 5rem;
}

.dmtable .tflex1 {
    flex: 1 1 0;
}

.dmtable .tflex2 {
    flex: 2 1 0;
}

.sticky-left {
    position: sticky;
    left: 0;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-scroll-justify {
    box-sizing: border-box;
}

@media (width <=1104px) {
    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_programlist .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }
}

/*--4/25 新增 END---*/

/*--4/29 新增 START--*/

.execution-overview {
    display: block;
}

.execution-overview>div{
    box-sizing: border-box;
    margin: 1.25rem 0.875rem;
}

/* .popout-fullscreen {
    background-color: #FFF;
    border-radius: 0.625rem;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    height: fit-content;
    margin: 1rem;
    padding: 1.5rem;
    z-index: 1;
} */

.execution-overview .title {
    margin-bottom: 1.25rem;
}

.execution-overview .title .titile-main {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}

.execution-overview .title .title-sub-blue {
    color: #3D71F8;
    font-size: 0.875rem;
}

.execution-overview .title .title-sub-black {
    font-size: 0.75rem;
    color: #000;
}

.execution-overview .title .title-sub2::before {
    font-size: 0.75rem;
    content: "｜ ";
    color: #CCC;
}

.card-value-purpleblue {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 1.75rem);
}

.card-value-purpleblue>div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    width: calc(50% - 0.3125rem);
    margin-left: 0.625rem;
}

.card-value-purpleblue>div:nth-child(1) {
    margin-left: 0;
}

.card-value-purpleblue>div>div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
    width: calc(50% - 0.3125rem);
    margin-left: 0.625rem;
}

.card-value-purpleblue>div>div:nth-child(1) {
    margin-left: 0;
}

@media (width <=1289px) {
    .card-value-purpleblue>div {
        width: 100%;
        margin-left: 0;
    }

    .card-value-purpleblue>div:not(:nth-child(1)) {
        margin-top: 0.625rem;
    }
}

@media (width <=615px) {
    .card-value-purpleblue>div>div {
        width: 100%;
        margin-left: 0;
    }

    .card-value-purpleblue>div>div:not(:nth-child(1)) {
        margin-top: 0.625rem;
    }
}

.card-value-purpleblue .card {
    padding: 0.625rem;
    background-color: #F3F6FB;
    border-radius: 0.3125rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 0;
    margin-left: 0.625rem;
    border: none;
}

.card-value-purpleblue .card>.title {
    color: #3D71F8;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
}

.card-value-purpleblue .card>.content {
    color: #000;
    font-size: 1rem;
}

@media (width<=768px) {
    .card-value-purpleblue .card>.title {
        color: #3D71F8;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }

    .card-value-purpleblue .card>.content {
        color: #000;
        font-size: 0.875rem;
    }
}

#power_chart_timeline .table-scroll-justify>div {
    width: 100%;
}

#power_chart_timeline .table-scroll-justify>div>div {
    width: 100%;
    min-width: 600px;
}

@media (width <=696px) {

    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_chart_timeline .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }
}

.apache_power_now {
    height: 300px;
}

.chart_timeline_group {
    box-sizing: border-box;
    padding-left: 20px;
}

.chart_timeline_machinestatus {
    box-sizing: border-box;
    width: 100%;
    height: 0.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 0.25rem;
}

.chart_timeline_machinestatus>div {
    box-sizing: border-box;
}

.chart_timeline_machinestatus .running {
    background-color: #7CC629;
}

.chart_timeline_machinestatus .idle {
    background-color: #FFAA00;
}

.chart_timeline_machinestatus .error {
    background-color: #F66F6F;
}

.chart_timeline_machinestatus .offine {
    background-color: #AAAAAA;
}

.chart_timeline_subprogram {
    box-sizing: border-box;
    width: 100%;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 0.25rem;
}

.chart_timeline_subprogram>div {
    background-color: #9FBAFF;
    border-left: #FFF solid 1px;
}

.chart_timeline_subprogram>div:nth-child(1) {
    border-left: #FFF solid 0px;
}

.chart_timeline_subprogram>div:nth-child(even) {
    background-color: #D4E0FF;
}

.chart_timeline_error {    
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 1.5rem;
    background-color: #F8F9FC;
}

.chart_timeline_error>div {
    position: relative;
    height: 0%;
}

/* .chart_timeline_error>.error:after {
    content: url('../../../../public/images/icon_error_red.svg');
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: -0.75rem;
    top: 0;
}

.chart_timeline_error>.warning:after {
    content: url('../../../../public/images/icon_warning_yellow.svg');
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: -0.75rem;
    top: 0;
} */

.chart_timeline_error > .error > div,
.chart_timeline_error > .warning > div {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: -0.75rem;
    top: 0;
}

.chart_timeline_error > .error > div {
    content: url('../../../../../public/images/icon_error_red.svg');
}

.chart_timeline_error > .warning > div {
    content: url('../../../../../public/images/icon_warning_yellow.svg');
}

.floating-animation:hover {
    animation: floatAnimation 1s infinite alternate;
}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-5px);
    }
}

.box-list-whiterowgrayline {
    border-radius: 0.625rem;
    padding:1.25rem;
    background-color: #fff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
}

.box-list-whiterowgrayline>.title {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 0.25rem;
}

.box-list-whiterowgrayline .dmtable>.thead>.tr {
    color: #3259CA;
    font-size: 0.75rem;
    padding-bottom: 0.25rem;
    line-height: 110%;
    border-bottom: 1px solid #D4E0FF;
}

.box-list-whiterowgrayline .dmtable>.tbody>.tr {
    color: #000;
    box-sizing: border-box;
    font-size: 0.875rem;
    background-color: #fff;
    padding: 0.25rem 0;
    border-left: 1px solid #D4E0FF;
    border-right: 1px solid #D4E0FF;
    border-bottom: 1px solid #D4E0FF;
}


.box-list-whiterowgrayline .dmtable>.thead>.tr>.sticky-left {
    background-color: #FFF;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.box-list-whiterowgrayline .dmtable>.sticky-top {
    background-color: #FFF;
}

.box-list-whiterowgrayline .dmtable>.tbody>.tr>.sticky-left {
    background-color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/*.box-list-whiterowgrayline 中的 .tr 的 hover 和 active效果*/
.box-list-whiterowgrayline .dmtable>.tbody>.tr:hover {
    background-color: #f5f6fc;
}

.box-list-whiterowgrayline .dmtable>.tbody>.tr:hover>.sticky-left {
    background-color: #f5f6fc;
}

@media (width <=990px) {
    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_table_subprogram .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }

    #power_table_subprogram .dmtable>.thead>.tr,
    #power_table_subprogram .dmtable>.tbody>.tr {
        width: fit-content;
    }
}

@media (width <=889px) {

    /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
    #power_table_error .table-scroll-justify>div {
        overflow-x: auto;
        overflow-y: hidden;
    }

    #power_table_error .dmtable>.thead>.tr,
    #power_table_error .dmtable>.tbody>.tr {
        width: fit-content;
    }
}
/*.box-list-whiterowgrayline 中的 .tr 的 hover 效果 (END)*/

.work-break-all {
    word-break: break-all;
}


/*--4/25 新增---*/


.text-grey-12 {
    font-size: 0.75rem;
    color: #AAA;
    font-weight: 400;
}

.text-black-20-bold {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
}


.apache_power_now .loading {
    opacity: 0.5;
    border-radius: 0.5rem;
    background: linear-gradient(90deg, #FFF 43%, #EBEEFB 50%, #FFF 66%) #FFF;
    background-size: 300% 100%;
    animation: l1 3s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
}

.power_now_partsProgram .chartbox {
    position: absolute;
    height: calc(100% - 8rem);
    width: calc(100vw - 9.5rem);
    top: 2.5rem;
    z-index: 1;
}

.power_now_timePeriod .chartbox {
    position: absolute;
    height: calc(100% - 5rem);
    width: calc(100vw - 11rem);
    top: 2.5rem;
    z-index: 1;
}