:root {
    --shift1: #7796CB;
    --shift2: #AB8FE3;
    --shift3: #99B3F2;
    --shift4: #576490;
    --shift1-bg: rgba(119, 150, 203, 0.2);
    --shift2-bg: rgba(171, 143, 227, 0.2);
    --shift3-bg: rgba(153, 179, 242, 0.2);
    --shift4-bg: rgba(87, 100, 144, 0.2);
    --shift1-bg-dark: rgba(119, 150, 203, 0.5);
    --shift2-bg-dark: rgba(171, 143, 227, 0.5);
    --shift3-bg-dark: rgba(153, 179, 242, 0.5);
    --shift4-bg-dark: rgba(87, 100, 144, 0.5);
    --machine-running: #7CC629;
    --machine-idle: #FFAA00;
    --machine-error: #F66F6F;
    --machine-offline: #CCC;
    --machine-nodata: #EBEEFB;
    --table-hover: rgba(159, 186, 255, 0.2);
}

.paddingSide20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}



/*圖表樣式：滾動卷軸區塊高度 START ↓ ↓ ↓*/
.chart_dailyList .scroll-body {
    min-height: 8.1rem;
    max-height: 14.55rem;
    overflow-y: auto;
}

@media (width<=1200px) {
    .chart_dailyList .scroll-body {
        min-height: 9.1rem;
        max-height: 19.4rem;
    }
}

/* .chart_machinestatus .scroll-body {
   min-height: 6rem;
   max-height: 14.5rem;
   overflow-y: auto;
}

@media (width<=1200px) {  
   .chart_machinestatus table {
       max-height: 32rem;
   }
   .chart_machinestatus .scroll-body {
       min-height: 12rem;
       max-height: 32rem;
       overflow-y: auto;
   }
} */
/*圖表樣式：滾動卷軸區塊高度 END ↑ ↑ ↑*/

/*------------------------------------------------------------*/
/*排版*/
/*---.flex-box-11：2塊1比1的寬區塊，在螢幕縮小時變成上下堆疊---*/
.flex-box-11 {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    align-items: stretch;
}

@media (width<=1120px) {
    .flex-box-11 {
        flex-direction: column;
    }
}

.flex-box-11>section {
    flex: 1 1 0;
}

/*排版*/
/*---.flex-box-111：橫向3格→1380:2格→950:1格---*/
.flex-box-111 {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.25rem;
    align-items: stretch;
}

.flex-box-111>section {
    flex: 1 1 0;
    min-width: 280px;
}

@media (max-width: 1000px) {
    .flex-box-111>section {
        min-width: 500px;
    }
}

@media (max-width: 670px) {
    .flex-box-111>section {
        min-width: 300px;
    }
}


/*排版*/
/*---.flex-box-1111：給小的2格或4格區塊｜橫向4格→1380:2格→950:1格／橫向2格950:1格---*/
.flex-box-1111 {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1.25rem;
    align-items: stretch;
}

.flex-box-1111>section {
    flex: 1 1 0;
    min-width: 300px;
}

@media (max-width: 1380px) {
    .flex-box-1111>section {
        min-width: 500px;
    }
}

@media (max-width: 1140px) {
    .flex-box-1111>section {
        min-width: 400px;
    }
}

@media (max-width: 950px) {
    .flex-box-1111>section {
        min-width: 315px;
    }
}

/*排版*/
/*---.flex-box-1111：給小的2格或4格區塊｜橫向4格→1380:2格→950:1格／橫向2格950:1格---*/
.flex-box-111111 {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.875rem;
    align-items: stretch;
}

.flex-box-111111>div {
    flex: 1 1 0;
    min-width: 200px;
}

@media (max-width: 1390px) {
    .flex-box-111111>div {
        min-width: 350px;
    }
}

@media (max-width: 1200px) {
    .flex-box-111111>div {
        min-width: 300px;
    }
}

@media (max-width: 1040px) {
    .flex-box-111111>div {
        min-width: 250px;
    }
}

@media (max-width: 900px) {
    .flex-box-111111>div {
        min-width: 230px;
    }
}

@media (max-width: 760px) {
    .flex-box-111111>div {
        min-width: 200px;
    }
}

/*-------其他版面樣式------*/
/* h2.block-title {
    font-size: .875rem;
    font-weight: 400;
    margin: 1.25rem 1rem 0 1.25rem;
}

h2.block-title:not(:first-child) {
    border-top: #9FBAFF 0.0625rem solid;
    padding-top: 1rem;
} */


/* .chartbox-notable {
    cursor: default;
} */


/*圖表區塊1：小資訊 START ↓ ↓ ↓-----------------*/

/*小框-藍底樣式*/
.boxstyle-blue {
    box-sizing: border-box;
    border-radius: .625rem;
    align-self: stretch;
    cursor: default;
}

.Light .boxstyle-blue {
    background: #D4E0FF;
    border: 1px solid rgba(0, 0, 0, 0);
}

.Dark .boxstyle-blue {
    background: #D4E0FF22;
    border: 1px solid rgba(0, 0, 0, 0);
}

.boxstyle-blue:hover {
    border: 1px solid #90adf8;
    box-shadow: 0px 0px 5px .25rem rgba(159, 186, 255, 0.2);
}

.littlebox>div {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100% - 1.75rem);
    margin: 0.875rem;
    gap: .5rem;
}

.littlebox>div>div {
    width: 100%;
    font-size: .875rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
}

.boxstyle-blue .title {
    display: inline;
    font-size: .75rem;
    font-weight: 500;
    line-height: .875rem;
}

.Light .boxstyle-blue .title {
    color: #444;
}

.Dark .boxstyle-blue .title {
    color: #AAA;
}

.boxstyle-blue .note {
    font-size: .875rem;
    line-height: .875rem;
}
.Light .boxstyle-blue .note {
    color: #444;
}
.Dark .boxstyle-blue .note {
    color: #AAA;
}

.boxstyle-blue .unit {
    font-size: 80%;
    font-size: 90%;
    margin-left: .25rem;
    padding: 0 .25rem;
    border-radius: 0.25rem;
}
.Light .boxstyle-blue .unit {
    color: #0346F2;
}
.Dark .boxstyle-blue .unit {
    color: #90adf8;
}

.boxstyle-blue .score {
    font-size: 1.125rem;
    font-weight: 700;
}

.Light .boxstyle-blue .score {
    color: #222;
}

.Dark .boxstyle-blue .score {
    color: #CCC;
}

.boxstyle-blue:hover .title {
    font-size: .875rem;
    line-height: .875rem;
}

.Light .boxstyle-blue:hover .title {
    color: #000;
}

.Dark .boxstyle-blue:hover .title {
    color: #FFF;
}

@media (width<=1200px) {
    .boxstyle-blue .title {
        font-size: .875rem;
        line-height: 1rem;
    }

    .boxstyle-blue:hover .title {
        font-size: 1rem;
        line-height: 1rem;
    }
}

@media (width<=680px) {
    .boxstyle-blue .title {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: #666;
        margin-bottom: .5rem;
    }

    .boxstyle-blue:hover .title {
        line-height: 1.25rem;
    }

    .boxstyle-blue .score {
        font-size: 1.25rem;
    }
}

.btn-littlebox {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: .25rem;
    padding: .2rem .5rem .2rem .35rem;
    cursor: pointer;
}
.Light .btn-littlebox {
    color: #3D71F8;
    background: #F8F9FC88;
    border: #D4E0FF 1px solid;
}
.Dark .btn-littlebox {
    color: #D4E0FF88;
    background: #F8F9FC22;
    border: #000000AA 1px solid;
}

.Light .btn-littlebox:hover {
    background: #F8F9FC;
    border: #9FBAFFAA 1px solid;
}
.Dark .btn-littlebox:hover {
    background: #F8F9FC22;
    border: #9FBAFFAA 1px solid;
    color: #D4E0FFCC;
}

.btn-littlebox:active {
    background: #EBEEFB;
    border: #9FBAFFDD 1px solid;
}
.btn-littlebox:active {
    background: #F8F9FC11;
    border: #9FBAFFDD 1px solid;
}

.btn-littlebox>div {
    display: flex;
    align-items: center;
}

.btn-littlebox .text {
    font-size: 0.75rem;
    margin-left: 0.125rem;
}

.btn-littlebox .icon {
    width: 1rem;
    height: 1rem;
}
.Light .btn-littlebox .icon {
    background: #3D71F8;
}
.Dark .btn-littlebox .icon {
    background: #D4E0FF88;
}
.Dark .btn-littlebox:hover .icon {
    background: #D4E0FFCC;
}

@media (width<=680px) {
    .btn-littlebox .text {
        font-size: 1rem;
    }

    .btn-littlebox {
        padding: .5rem;
    }
}

/* 定義上下飄動的動畫 */
@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5px);
    }
}

/* @media (width <=600px) {
    .btn-onlyicon .icon {
        font-size: 2rem;
    }
} */



/*------------------------------------------------------------*/

/*圖表區塊2：各班別小結論 START ↓ ↓ ↓*/

.area-shift-statistic {
    position: relative;
    border-radius: 0.125rem 0.125rem 0.625rem 0.625rem;
    cursor: default;
}

.area-shift-statistic>div {
    display: flex;
    flex-direction: column;
}

/*---2-1 班別名稱 START ↓ ↓ ↓---*/
.area-shift-statistic .deco-shiftname .shiftname {
    position: absolute;
    z-index: 3;
    top: -.625rem;
    width: fit-content;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    word-break: keep-all;
}
.Light .area-shift-statistic .deco-shiftname .shiftname {
    color: #FFF;
}
.Dark .area-shift-statistic .deco-shiftname .shiftname {
    color: #000;
}

.area-shift-statistic .deco-shiftname .bar {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: .25rem;
    border-radius: .125rem;
    opacity: 50%;
    margin-top: 0 !important;
}

.area-shift-statistic .deco-shiftname .bg {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.875rem;
    opacity: 10%;
}

@media (width <=768px) {
    .area-shift-statistic .deco-shiftname .shiftname {
        font-size: 1rem;
        line-height: 1.25rem;
    }
}

/*---2-1 班別名稱 END ↑ ↑ ↑---*/

/*---2-2 圓餅圖 START ↓ ↓ ↓---*/
.area-shift-statistic .chart-and-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.area-shift-statistic .chart-and-table>div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 2.75rem;
    margin-bottom: 1.875rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 25rem;
}

.area-shift-statistic .chart-and-table .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 7.5rem);
    height: 8rem;
    max-height: 9rem;
}

.area-shift-statistic .chart-and-table .chart>div {
    height: 100%;
    max-height: 9rem;
    width: 9.5rem;
}

/*-----2-2-2 th有圓點的表格 ↓ ↓ ↓*/
/* .chart-table>table {
    line-height: 100%;
    border-collapse: collapse;
    width: 100%;
}

.chart-table>table>thead {
    color: #AAA;
    font-size: .625rem;
    line-height: .625rem;
    border-bottom: #CCC .03125rem solid;
}

.chart-table>table>tbody {
    font-size: 1rem;
}

@media (width<=680px) {
   .chart-table>table>thead {
       font-size: .75rem;
       line-height: .75rem;
   }

   .chart-table>table>tbody {
       font-size: 120%;
   }
}

.chart-table>table th,
.chart-table>table td {
    font-weight: normal;
}

.chart-table>table th {
    padding: 0.25rem;
    text-align: left;
    border-radius: .25rem 0 0 .25rem;
    margin-right: .5rem;
}

.chart-table>table td:last-child {
    padding: 0 .25rem 0 .75rem;
    text-align: right;
    border-radius: 0 .25rem .25rem 0;
}

.chart-table>table tbody td:nth-last-child(2) {
    padding: 0 .25rem 0 0;
    text-align: right;
}

.chart-table>table>tbody td {
    line-height: 150%;
}

.chart-table>table .dot {
    box-sizing: border-box;
    width: .875rem;
    height: .875rem;
    border-radius: 50%;
    margin-right: .5rem;
    border: 1px solid #FFF;
}

.chart-table .unit {
    font-size: 80%;
    margin-left: .125rem;
    color: #888;
}

.chart-table>table>tbody tr:hover {
    background: var(--table-hover);
}

.chart-table>table>tbody tr:hover .dot {
    width: .875rem;
    height: .875rem;
    border: #FFF;
} */


/*-----2-2-2 th有圓點的表格 ↑ ↑ ↑*/

/*---2-3 評等 START ↓ ↓ ↓---*/

.area-shift-statistic .statistic {
    display: flex;
    flex-direction: row;
    gap: .75rem;
}

.area-shift-statistic .statistic>div {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .3125rem;
    padding: .625rem;
    padding-bottom: .875rem;
}

.Light .area-shift-statistic .statistic>div:not(:first-child):hover {
    filter: brightness(0.75) contrast(130%);
}
.Dark .area-shift-statistic .statistic>div:not(:first-child):hover {
    filter: brightness(1.2);
}

.area-shift-statistic .statistic .title {
    font-size: .625rem;
    line-height: .75rem;
    margin-bottom: .625rem;
}
.Light .area-shift-statistic .statistic .title {
    color: #444;
}
.Dark .area-shift-statistic .statistic .title {
    color: #CCC;
}

.area-shift-statistic .statistic:hover .title {
    font-size: .75rem;
    line-height: .875rem;
}

@media (width<=680px) {
    .area-shift-statistic .statistic .title {
        font-size: .875rem;
        line-height: 1rem;
        margin-bottom: 1rem;
    }

    .area-shift-statistic .statistic:hover .title {
        font-size: 1rem;
        line-height: 1.1rem;
    }
}

.area-shift-statistic .statistic .score {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.Light .area-shift-statistic .statistic .criteria_lv1 .score,
.Light .area-shift-statistic .statistic .criteria_lv1 .title,
.Light .area-shift-statistic .statistic .criteria_lv2 .score,
.Light .area-shift-statistic .statistic .criteria_lv2 .title {
    color: #FFF;
}
.Light .area-shift-statistic .statistic .criteria_lv0 .score,
.Light .area-shift-statistic .statistic .criteria_lv0 .title,
.Light .area-shift-statistic .statistic .criteria_lv3 .score,
.Light .area-shift-statistic .statistic .criteria_lv3 .title,
.Light .area-shift-statistic .statistic .criteria_lv4 .score,
.Light .area-shift-statistic .statistic .criteria_lv4 .title {
    color: #000;
}
.Dark .area-shift-statistic .statistic .criteria_lv1 .score,
.Dark .area-shift-statistic .statistic .criteria_lv1 .title,
.Dark .area-shift-statistic .statistic .criteria_lv2 .score,
.Dark .area-shift-statistic .statistic .criteria_lv2 .title,
.Dark .area-shift-statistic .statistic .criteria_lv0 .score,
.Dark .area-shift-statistic .statistic .criteria_lv0 .title,
.Dark .area-shift-statistic .statistic .criteria_lv3 .score,
.Dark .area-shift-statistic .statistic .criteria_lv3 .title,
.Dark .area-shift-statistic .statistic .criteria_lv4 .score,
.Dark .area-shift-statistic .statistic .criteria_lv4 .title {
    color: #FFF;
}

.area-shift-statistic .statistic .unit {
    font-size: 80%;
}

.area-shift-statistic .shift1 .shiftname,
.area-shift-statistic .shift1 .bar,
.area-shift-statistic .shift1 .bg {
    background: var(--shift1);
}

.Light .area-shift-statistic .shift1 .statistic>div:not(:first-child) {
    background: var(--shift1-bg);
}
.Dark .area-shift-statistic .shift1 .statistic>div:not(:first-child) {
    background: var(--shift1-bg-dark);
    border: 1px solid  var(--shift1);
}

.area-shift-statistic .shift2 .shiftname,
.area-shift-statistic .shift2 .bar,
.area-shift-statistic .shift2 .bg {
    background: var(--shift2);
}

.Light .area-shift-statistic .shift2 .statistic>div:not(:first-child) {
    background: var(--shift2-bg);
}
.Dark .area-shift-statistic .shift2 .statistic>div:not(:first-child) {
    background: var(--shift2-bg-dark);
    border: 1px solid  var(--shift2);
}

.area-shift-statistic .shift3 .shiftname,
.area-shift-statistic .shift3 .bar,
.area-shift-statistic .shift3 .bg {
    background: var(--shift3);
}

.Light .area-shift-statistic .shift3 .statistic>div:not(:first-child) {
    background: var(--shift3-bg);
}
.Dark .area-shift-statistic .shift3 .statistic>div:not(:first-child) {
    background: var(--shift3-bg-dark);    
    border: 1px solid  var(--shift3);
}

.area-shift-statistic .shift4 .shiftname,
.area-shift-statistic .shift4 .bar,
.area-shift-statistic .shift4 .bg {
    background: var(--shift4);
}

.Light .area-shift-statistic .shift4 .statistic>div:not(:first-child) {
    background: var(--shift4-bg);
}
.Dark .area-shift-statistic .shift4 .statistic>div:not(:first-child) {
    background: var(--shift4-bg-dark);    
    border: 1px solid  var(--shift4);
}

/*---2-3 評等 END ↑ ↑ ↑---*/
/*圖表區塊：各班別小結論 END ↑ ↑ ↑*/


/*圖表區塊：每日評等 START ↓ ↓ ↓*/
.chart_dailyList {
    position: relative;
    padding-top: 1rem;
}

.chart_dailyList .deco_shiftname {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 1rem;
    display: flex;
    flex-direction: row;
    z-index: 0;
}

.chart_dailyList .deco_shiftname .space {
    width: 6rem;
}

@media (width <=768px) {
    .chart_dailyList .deco_shiftname .space {
        width: 2rem;
    }
}

.chart_dailyList .deco_shiftname .chart {
    box-sizing: border-box;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.chart_dailyList .deco_shiftname .shift {
    box-sizing: border-box;
    position: relative;
}

.chart_dailyList .deco_shiftname .shift>div {
    width: 100%;
}

.chart_dailyList .deco_shiftname .shiftname {
    position: absolute;
    z-index: 6;
    top: -.375rem;
    width: 100%;
    display: grid;
    justify-content: center;
}

.chart_dailyList .deco_shiftname .shiftname>div {
    width: fit-content;
    font-size: .625rem;
    line-height: .75rem;
    padding: .25rem .75rem;
    word-break: keep-all;
}

.chart_dailyList .deco_shiftname .shift .bar {
    position: absolute;
    z-index: 2;
    top: .75rem;
    left: .125rem;
    width: calc(100% - .25rem);
    height: 0.25rem;
    border-radius: .125rem;
    opacity: 50%;
    margin-top: 0;
}

@media (width <=1120px) {
    .chart_dailyList .deco_shiftname .shiftname {
        top: -.5rem;
    }

    .chart_dailyList .deco_shiftname .shiftname>div {
        font-size: .875rem;
        line-height: .875rem;
    }
}

@media (width <=768px) {

    .chart_dailyList .deco_shiftname .shiftname,
    .chart_dailyList .deco_shiftname .shiftname>div {
        display: none;
    }

    .chart_dailyList .deco_shiftname .shift .bar {
        top: .5rem;
    }

    .chart_dailyList .deco_shiftname .bg {
        top: 0;
        height: 1rem;
    }
}

.chart_dailyList .timeline tr {
    line-height: 1.75rem;
}

@media (width<=1200px) {
    .chart_dailyList .timeline tr {
        line-height: 2.5rem;
    }
}

.chart_dailyList .timeline td {
    border-top: 0;
    border-bottom: 0;
}

.Light .chart_dailyList .timeline td {
    border-left: 2px solid #FFF;
    border-right: 2px solid #FFF;
}

.Dark .chart_dailyList .timeline td {
    border-left: 2px solid #000;
    border-right: 2px solid #000;
}

.chart_dailyList .timeline th,
.chart_dailyList .timeline td {
    padding: 0.1rem 0;
}

.chart_dailyList .timeline tr:hover,
.chart_dailyList .timeline td:hover,
.highlight {
    background: var(--table-hover);
}

.chart_dailyList .timeline .date {
    line-height: 1;
}
.chart_dailyList .timeline tr:hover .date {
    font-weight: 600;
}
.Light .chart_dailyList .timeline tr:hover .date {
    color: #000;
}
.Dark .chart_dailyList .timeline tr:hover .date {
    color: #FFF;
}

.chart_dailyList .score {
    font-size: .75rem;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    height: 1.75rem;
    line-height: 1.5rem;
    margin: 0 1rem;
    border-radius: .3125rem;
    text-align: center;
    overflow: hidden;
    cursor: default;
}

.chart_dailyList .score:hover {
    font-size: 1rem;
    line-height: 1.5rem;
}

/*用滑鼠點擊這個<div>時，達到下面這段:focus的效果*/
.chart_dailyList .score:focus {
    font-size: 1.25rem;
    overflow: visible;
    font-weight: 500;
    transition: font-size 0.3s ease;
    white-space: nowrap;
}

.chart_dailyList .score .unit {
    font-size: 80%;
}

@media (width <=1200px) {
    .chart_dailyList .score {
        width: calc(100% - 1rem);
        margin: 0 .5rem;
        height: 2.5rem;
        font-size: 1rem;
        line-height: 2.25rem;
    }

    .chart_dailyList .score:hover {
        line-height: 2.25rem;
    }
}

@media (width <=380px) {
    .chart_dailyList .score {
        width: calc(100% - 0.5rem);
        font-size: .75rem;
        margin: 0 0.25rem;
    }
}

/* .criteria_lv0 {
    background: rgba(255, 255, 255, .5);
    border: 2px solid #FFF;
    color: #CCC;
}

.criteria_lv0:hover {
    background: rgba(255, 255, 255, .7);
    border: 2px solid #DDD;
    color: #888;
} */

.Light .criteria_lv1 {
    background: #003396;
    border: 2px solid #003396;
    color: #FFF;
}
.Dark .criteria_lv1 {
    background: #003396;
    border: 2px solid #0349d6;
    color: #FFF;
}
.Light .criteria_lv1:hover {
    background: #00246b;
    border: 2px solid #003396;
}
.Dark .criteria_lv1:hover {
    background: #0349d6;
    border: 2px solid #003396;
}

.Light .criteria_lv2 {
    background: #3273C3;
    border: 2px solid #3273C3;
    color: #FFF;
}
.Dark .criteria_lv2 {
    background: #3273C3CC;
    border: 2px solid #3273C3CC;
    color: #FFF;
}

.Light .criteria_lv2:hover {
    background: #1c5fb1;
    border: 2px solid #3273C3;
}
.Dark .criteria_lv2:hover {
    background: #1c5fb1CC;
    border: 2px solid #3273C3CC;
}

.Light .criteria_lv3 {
    background: #73B9ED;
    border: 2px solid #73B9ED;
    color: #000;
}
.Dark .criteria_lv3 {
    background: #73B9ED66;
    border: 2px solid #73B9ED44;
    color: #FFF;
}

.Light .criteria_lv3:hover {
    background: #549ed6;
    border: 2px solid #73B9ED;
}
.Dark .criteria_lv3:hover {
    background: #549ed6AA;
    border: 2px solid #73B9EDAA;
}

.Light .criteria_lv4 {
    background: #c2e4ff;
    border: 2px solid #c2e4ff;
    color: #000;
}
.Dark .criteria_lv4 {
    background: #c2e4ff22;
    border: 2px solid #c2e4ff44;
    color: #c2e4ffAA;
}

.Light .criteria_lv4:hover {
    background: #a9dcfa;
    border: 2px solid #c2e4ff;
}
.Dark .criteria_lv4:hover {
    background: #a9dcfa44;
    color: #c2e4ff;    
}

/* .error_lv0 {
    background: rgba(255, 255, 255, .5);
    border: 2px solid #FFF;
    color: #CCC;
}

.error_lv0:hover {
    background: rgba(255, 255, 255, .7);
    border: 2px solid #DDD;
    color: #888;
} */

.Light .error_lv1,
.Light .color-danger-div-great {
    background: #FEF0F0;
    border: 2px solid rgba(255, 255, 255, .7);
    color: #F66F6F;
}

.Dark .error_lv1,
.Dark .color-danger-div-great {
    background: #FEF0F022;
    border: 2px solid rgba(255, 255, 255, .1);
    color: #F66F6FAA;
}

.error_lv1:hover,
.color-danger-div-great:hover {
    border: 2px solid #FFD4D4;
    color: #F66F6F;
}

.Light .error_lv2,
.Light .color-danger-div-good {
    background: #FFD4D4;
    border: 2px solid rgba(255, 255, 255, .7);
    color: #F66F6F;
}

.Dark .error_lv2,
.Dark .color-danger-div-good {
    background: #FFD4D4AA;
    border: 2px solid rgba(255, 255, 255, .2);
    color: #FFF;
}

.error_lv2:hover,
.color-danger-div-good:hover {
    border: 2px solid #F9A8A8;
}

.Light .error_lv3,
.Light .color-danger-div-fair {
    background: #F9A8A8;
    border: 2px solid rgba(255, 255, 255, .7);
    color: #B53838;
}

.Dark .error_lv3,
.Dark .color-danger-div-fair {
    background: #F9A8A8AA;
    border: 2px solid rgba(255, 255, 255, .3);
    color: #FFF;
}

.error_lv3:hover,
.color-danger-div-fair:hover {
    border: 2px solid #F66F6F;
}

.error_lv4,
.color-danger-div-poor {
    background: #F66F6F;
    border: 2px solid rgba(255, 255, 255, .7);
    color: #FFF;
}

.error_lv4:hover,
.color-danger-div-poor:hover {
    border: 2px solid #B53838;
}

/*圖表區塊：每日評等 END ↑ ↑ ↑*/


/*-----------------------------------------------
圖表區塊-時間軸基底 ↓ ↓ ↓*/
.timeline>table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin: 0;
}

.timeline>table th {
    font-weight: normal;
    padding: .25rem 0;
    text-align: left;
    width: 6rem;
}

.timeline>table td {
    font-weight: normal;
    padding: .25rem 0;
}

.timeline>table>thead>tr>td {
    color: #AAA;
    font-size: .75rem;
    line-height: 0.825rem;
    text-align: center;
    height: 1.75rem
}

.timeline .sticky {
    position: sticky;
    z-index: 11;
}

.Light .timeline .sticky {
    background: rgba(255, 255, 255, 0.9);
}

.Dark .timeline .sticky {
    background: #000;
}

.timeline .top-32 {
    top: -2px;
}

.timeline .top16 {
    top: 1rem;
}

.timeline .smallScreen {
    display: none;
}

.timeline .smallScreen>td {
    height: 1rem;
}

@media (width <=1120px) {
    .timeline .smallScreen {
        display: table-row;
    }

    .timeline>table>thead>tr>td>span {
        display: none;
    }

    .timeline .time>td {
        height: 1rem;
    }
}

.timeline .time td:not(:last-child).crossline {
    border-right: #CCC solid 0.0625rem;
}

.timeline tbody {
    color: #888;
    font-size: .75rem;
    text-align: left;
}

.timeline  .date {
    line-height: 1;
}

@media (width <=768px) {
    .timeline>table th {
        width: 2rem;
    }

    .timeline .date span {
        display: none;
    }

    .timeline .date {
        font-size: 1rem;
        font-weight: 500;
        color: #444;
    }
}

.timeline tbody tr:hover .date {
    font-weight: 600;
}

.Light .timeline tbody tr:hover .date {
    color: #000;
}

.Dark .timeline tbody tr:hover .date {
    color: #FFF;
}

/*圖表區塊-時間軸基底 END ↑ ↑ ↑
-----------------------------------------------*/


/*圖表群組：時間軸｜設備狀態 START ↓ ↓ ↓ */
.chart_timeline_machinestatus {
    box-sizing: border-box;
    width: 100%;
    height: 1rem !important;
    margin-top: .25rem;
    margin-bottom: 0 !important;
}

.chart_timeline_machinestatus>div {
    box-sizing: border-box;
    width: 100%;
    height: inherit;
    background: #EBEEFB;
    position: relative;
}

.chart_timeline_machinestatus>div>div {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 0;
    z-index: 1;
}

.chart_timeline_machinestatus>div>div>div:nth-child(1) {
    height: 0;
}

.chart_timeline_machinestatus>div>div>div:nth-child(2) {
    height: 1rem;
}

.chart_timeline_machinestatus .running .status,
.chart-table .running {
    background: var(--machine-running);
}

.chart_timeline_machinestatus .idle .status,
.chart-table .idle {
    background: var(--machine-idle);
}

.chart_timeline_machinestatus .error .status,
.chart-table .error {
    background: var(--machine-error);
}

.Light .chart_timeline_machinestatus .offline .status,
.chart-table .offline {
    background: var(--machine-offline);
}

.Dark .chart_timeline_machinestatus .offline .status {
    background: #666;
}

.chart_timeline_machinestatus .nodata .status,
.chart-table .nodata {
    background: var(--machine-nodata);
}

.chart_timeline_machinestatus .running .status:hover,
.chart_timeline_machinestatus .idle .status:hover,
.chart_timeline_machinestatus .error .status:hover,
.chart_timeline_machinestatus .offline .status:hover {
    filter: brightness(0.85) contrast(130%);
    box-shadow: 0px 0px 5px 0px rgba(159, 186, 255, .5);
}

.chart_timeline_machinestatus .nodata .status:hover {
    filter: brightness(0.85) contrast(130%);
}

@media (width <=1200px) {

    .chart_timeline_machinestatus,
    .chart_timeline_machinestatus>div,
    .chart_timeline_machinestatus>div>div>div:nth-child(2) {
        height: 2rem !important;
        margin-bottom: 0 !important;
    }

    .chart_timeline_machinestatus {
        margin-top: .75rem;
    }
}

/*圖表群組：時間軸｜設備狀態 END ↑ ↑ ↑ */

/*圖表群組：時間軸｜程式執行區段 START ↓ ↓ ↓*/
.chart_timeline_programrun {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.25rem;
}

.chart_timeline_programrun>div {
    box-sizing: border-box;
    width: 100%;
    height: inherit;
    position: relative;
}

.chart_timeline_programrun>div>div {
    position: absolute;
    top: -1px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 0;
}

.chart_timeline_programrun .run {
    border-bottom: 1px dashed #888;
    position: relative;
    border-left: 0;
}

.chart_timeline_programrun .run-start::before {
    content: "";
    display: block;
    width: .5rem;
    height: .5rem;
    background: rgba(255, 255, 255, .9);
    border: 1px solid #AAA;
    border-radius: 50%;
    position: absolute;
    left: -.3rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.chart_timeline_programrun .run-finished::after {
    content: '';
    display: inline-block;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="24" viewBox="0 0 8 24" fill="none" stroke="%23888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 3;
}

.chart_timeline_programrun .run .runshadow {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: .5rem;
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    z-index: 2;
}

.chart_timeline_programrun .run .runshadow::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(-1.5rem + 2px);
    left: -1px;
    box-sizing: border-box;
    width: calc(100% + 2px);
    height: .5rem;
    z-index: 2;
}

@media (width <=990px) {
    .chart_timeline_programrun {
        margin-bottom: .75rem;
    }

    .chart_timeline_programrun .run .runshadow {
        height: 1rem;
    }

    .chart_timeline_programrun .run .runshadow::before {
        top: calc(-3rem + 2px);
        height: 1rem;
    }
}

.chart_timeline_programrun .run:hover .runshadow,
.chart_timeline_programrun .run:hover .runshadow::before,
.chart_timeline_programrun .run .runshadow:hover,
.chart_timeline_programrun .run .runshadow:hover::before {
    background: rgba(159, 186, 255, 0.4);
    border-left: 1px dashed #AAA;
    border-right: 1px dashed #AAA;
}

/*圖表群組：時間軸｜程式執行區段 END ↑ ↑ ↑*/

/*圖表樣式：時間軸｜班別時間區塊  START ↓ ↓ ↓*/
/*(deco_timeline_shiftname是最上方的「班別名稱」區塊，deco_timeline_shift是班別的背景色)*/
.deco_timeline_shiftname {
    box-sizing: border-box;
    width: 100%;
    height: .75rem;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: .5rem;
    z-index: 15;
}

.Light .deco_timeline_shiftname {
    background: #FFF;
}

.Dark .deco_timeline_shiftname {
    background: #000;
}

.deco_timeline_shift {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.deco_timeline_shiftname .space,
.deco_timeline_shift .space {
    width: 6rem;
}

@media (width <=768px) {

    .deco_timeline_shiftname .space,
    .deco_timeline_shift .space {
        width: 2rem;
    }
}

.deco_timeline_shiftname .chart,
.deco_timeline_shift .chart {
    box-sizing: border-box;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
}

.deco_timeline_shiftname .chart>div,
.deco_timeline_shift .chart>div {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
}

.deco_timeline_shiftname .chart>div>div,
.deco_timeline_shift .chart>div>div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.deco_timeline_shiftname .shift,
.deco_timeline_shiftname .shift-whitebg>div,
.deco_timeline_shift .shift {
    box-sizing: border-box;
    position: relative;
}

.deco_timeline_shiftname .shift>div {
    width: 100%;
}

.deco_timeline_shiftname .shift-whitebg>div>div {
    position: absolute;
    z-index: 1;
    top: -.75rem;
    width: 100%;
    height: 1.5rem;
}

.Light .deco_timeline_shiftname .shift-whitebg>div>div {
    background: rgba(255, 255, 255, 0.9);
}

.Dark .deco_timeline_shiftname .shift-whitebg>div>div {
    background: rgba(0, 0, 0, 0.9);
}

.deco_timeline_shiftname .shiftname {
    position: absolute;
    z-index: 6;
    top: -.75rem;
    width: 100%;
    display: grid;
    justify-content: center;
}

.Light .deco_timeline_shiftname .shiftname {
    background: #FFF;
}

.Dark .deco_timeline_shiftname .shiftname {
    background: #000;
}

.deco_timeline_shiftname .shiftname>div {
    width: fit-content;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem 0;
    word-break: keep-all;
}

.deco_timeline_shiftname .shift .bar {
    position: absolute;
    z-index: 15;
    top: .5rem;
    left: 1px;
    width: calc(100% - 2px);
    height: 0.25rem;
    border-radius: 0.125rem;
    opacity: 50%;
    margin-top: 0;
}

@media (width <=768px) {

    .deco_timeline_shiftname .shiftname,
    .deco_timeline_shiftname .shiftname>div {
        display: none;
    }
}

.deco_timeline_shift .bg {
    opacity: 10%;
    top: .75rem;
    z-index: 0;
    height: calc(100% - .5rem);
}

.Light .deco_timeline_shift .bg {
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
}

.Dark .deco_timeline_shift .bg {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.deco_shiftname .shift1 .shiftname>div,
.deco_timeline_shiftname .shift1.shiftname>div {
    color: var(--shift1);
}

.deco_shiftname .shift2 .shiftname>div,
.deco_timeline_shiftname .shift2.shiftname>div {
    color: var(--shift2);
}

.deco_shiftname .shift3 .shiftname>div,
.deco_timeline_shiftname .shift3.shiftname>div {
    color: var(--shift3);
}

.deco_shiftname .shift4 .shiftname>div,
.deco_timeline_shiftname .shift4.shiftname>div {
    color: var(--shift4);
}

.deco_shiftname .shift1 .bar,
.deco_timeline_shiftname .shift1.bar,
.deco_timeline_shift .shift1.bg {
    background: var(--shift1);
}

.deco_shiftname .shift2 .bar,
.deco_timeline_shiftname .shift2.bar,
.deco_timeline_shift .shift2.bg {
    background: var(--shift2);
}

.deco_shiftname .shift3 .bar,
.deco_timeline_shiftname .shift3.bar,
.deco_timeline_shift .shift3.bg {
    background: var(--shift3);
}

.deco_shiftname .shift4 .bar,
.deco_timeline_shiftname .shift4.bar,
.deco_timeline_shift .shift4.bg {
    background: var(--shift4);
}

.deco_timeline_shift .noshift {
    border-left: 0;
    opacity: 65%;
    z-index: 10;
    margin-top: 3rem;
    height: calc(100% - 3rem);
}

.Light .deco_timeline_shift .noshift {
    background: #FFF;
}

.Dark .deco_timeline_shift .noshift {
    background: #000;
}

@media (width <=990px) {
    .deco_timeline_shift .noshift {
        margin-top: 3.75rem;
        height: calc(100% - 3.75rem);
    }
}

/*圖表樣式：時間軸｜班別時間區塊 END ↑ ↑ ↑*/

/*圖表樣式：時間軸效果｜圖表垂直線 START ↓ ↓ ↓*/
.deco_timeline_mouseLine {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.deco_timeline_mouseLine .space {
    width: 6rem;
}

@media (width <=768px) {
    .deco_timeline_mouseLine .space {
        width: 2rem;
    }
}

.deco_timeline_mouseLine .chart {
    box-sizing: border-box;
    flex: 1 1 0;
    width: 100%;
    height: 100%;
}

.deco_timeline_mouseLine .chart>div {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
}

.deco_timeline_mouseLine .chart>div>div {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#chartMouseMove {
    position: relative;
}

#vertical-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    border-left: 1px dashed #AAA;
    display: none;
    /* Initially hidden */
    z-index: 10;
    /* Make sure the line is above other elements */
    pointer-events: none;
    /* Allow mouse events to pass through */
}

/*圖表樣式：時間軸效果｜圖表垂直線 END ↑ ↑ ↑*/





/**Tooltip*/

.tooltipButton {
    position: relative;
}

.tooltipButton .tooltip {
    position: absolute;
    width: max-content;
    background: rgba(34, 34, 34, 85%);
    color: white;
    border-radius: 0.5rem;
    font-size: 90%;
    z-index: 15;
    /* Fade in tooltip */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    bottom: 125%;
    left: -50%;
}

.tooltipButton .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(34, 34, 34, 85%) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipButton:hover .tooltip {
    visibility: visible;
    opacity: 1;
}



.tp_machinestatus {
    margin: .5rem;
    display: flex;
    flex-direction: row;
}

.tp_machinestatus .dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    margin: .05rem 0.5rem 0 0;
}

.tp_machinestatus .status {
    font-weight: bold;
}

.tp_machinestatus>div>div {
    text-align: left;
}

.tp_machinestatus>div>div:not(:first-child) {
    margin-top: 0.25rem;
}

.tp_machinestatus .duration {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tp_machinestatus .title {
    font-size: .625rem;
    line-height: .625rem;
    padding: .2rem .4rem;
    background: #222;
    border: 1px solid #888;
    margin: 0 .25rem .1rem 0;
    opacity: 0.6;
    border-radius: 0.25rem;
    width: max-content;
}

.tp_machinestatus .program .title {
    margin-bottom: 0.25rem;
}

.tp_machinestatus .program .content {
    display: flex;
    flex-direction: column;
}

.tp_machinestatus .program img {
    max-width: 3rem;
    max-height: 3rem;
}

.tp_machinestatus hr {
    margin-top: .5rem;
}

.tp_machinestatus .program .content .part {
    font-weight: 700;
    margin-right: 0.75rem;
    font-size: 120%;
}

.tp_machinestatus .program .content .programname {
    font-weight: lighter;
    color: #AAA;
    font-size: 95%;
    word-break: break-all;
}

.tp_machinestatus .datetime {
    font-size: .875rem;
    line-height: 1rem;
    margin-bottom: .5rem;
    word-break: keep-all;
}

.tp_machinestatus .nodata .datetime {
    max-width: 7rem;
}

.running .status {
    color: var(--machine-running);
}

.running .dot {
    background: var(--machine-running);
}

.idle .status {
    color: var(--machine-idle);
}

.idle .dot {
    background: var(--machine-idle);
}

.error .status {
    color: var(--machine-error);
}

.error .dot {
    background: var(--machine-error);
}

.offline .status {
    color: var(--machine-offline);
}

.offline .dot {
    background: var(--machine-offline);
}