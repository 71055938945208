
.remarkDiv {
  margin: .5rem 0;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.remarkTxt>div{
  background-color: #D4E0FF;
  color: #3D71F8;
  padding: .25rem .5rem;
  border-radius: .25rem;
  font-size: .625rem;
}

.remarkContent{
  color: #3D71F8;
  font-size: .75rem;
  line-height: 1;  
  padding: .25rem .5rem;
}

.inputText{
  /* background-color: #EBEEFB; */
  background-color: #fff;
  border: 1px solid #D4E0FF;
  border-radius: 5px;
}

/* .table-scrollRawData{
  overflow-x: auto;
  min-height: 74vh;
}

.table-scrollRemarkRawData{
  overflow-x: auto;
  min-height: 70vh;
} */

