.gp {
    box-sizing: border-box;
    width:100%;
}

.gp .title{
    font-size: .75rem;
    color:#000;
    margin-top: 1rem;
}

.gp .gp-blackline{
    box-sizing: border-box;
    border: #000 1px solid;
    border-radius: .25rem;
    padding:1.25rem;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: .5rem;
}

.gp .gp-blackline:hover {
    outline:#000 1px solid;
}

.gp .gp-blackline .cancel{
    position: absolute;
    top:0;
    right:0;
    width:2rem;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 .25rem 0 .25rem;
    background: transparent;
}

.gp .gp-blackline .cancel:hover{
    background: #000;
}

.gp .gp-blackline .cancel:active{
    background: #444;
}

.gp .gp-blackline .cancel>div{
    height:1.25rem;
    width:1.25rem;
    background:#CCC;
}

.gp .gp-blackline .cancel:hover>div{
    background:#FFF;
}

.gp .gp-blackline .gp-list {
    box-sizing: border-box;
    padding:.625rem;
    padding-right: 0;
    width:100%;
}

.gp .gp-blackline .gp-list>div {
    box-sizing: border-box;
    width:100%;
    border-left:#D4E0FF 2px solid ;
    display: flex;
    flex-direction: column;
}

.gp .gp-blackline .gp-list .item {
    box-sizing: border-box;
    width:100%;
    height:2rem;
    padding:.5rem .5rem .5rem 1rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem;
    border-radius: 0 .25rem .25rem 0;
}

.gp .gp-blackline .gp-list .item:hover {
    background: #F5F5F5;
}

.gp .gp-blackline .gp-list .item .icon{
    width:1.75rem;
    height:1.75rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.gp .gp-blackline .gp-list .item .icon:hover{
    background: #EEE;
}

.gp .gp-blackline .gp-list .item .icon:active{
    background: #EBEEFB;
}

.gp .gp-blackline .gp-list .item .icon>div{
    width:1.25rem;
    height:1.25rem;
}

.icon .lightblue{
    background: #9FBAFF;
}

.icon:hover .lightblue{
    background: #83a2f0;
}
.icon .lightblue:active{
    background: #6889dd;
}

.mR20 {
    margin-right: 1.25rem;
}

.mT0 {
    margin-top:0 !important;
}

/*---跳出視窗的Footer-按鈕群組 START ↓ ↓ ↓ */

.btn-gp {
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
}

.btn-gp>div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: stretch;
}

.btn-gp button.fat {
    padding: 0 1rem;
}

.modal-footer .btn-gp {
    width:100%;
    height: 2.375rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
}

.modal-form .btn-gp {
    height: 2rem;
}

.modal-form .btn-gp button{
    padding: 0;
}

.modal-footer .btn-gp>div>button {
    margin-right: 0.5rem;
}

/*---跳出視窗的Footer-按鈕群組 END ↑ ↑ ↑ */

.modal-createdProcesses{
    background-color: #F8F9FC;
    margin: .5rem 0;
    border-radius: .25rem;
    width: 100vw;
    padding: .5rem;
}

.modal-createdProcesses > .title, .modal-trialCalculation > .title{
    color: #9FBAFF;
    font-size: 0.625rem;
}

.modal-createdProcesses > .item{
    color: #444;
    font-size: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    padding: .25rem
}

.modal-createdProcesses > .item > .processNO{
    flex: 2;
    display: flex;
    justify-content: center;
}

.modal-createdProcesses > .item > .processName{
    flex: 8
}

.modal-createdProcesses > .item > .workOrderID{
    flex: 4;
    display: flex;
    justify-content: center;
}

.modal-createdProcesses > .item > .partProcesses{
    flex: 6
}

.modal-createdProcesses > .item > .partProcesses > .no{
    font-weight: bolder;
    margin-right: .25rem;
}

.modal-trialCalculation{
    background-color: #EBEEFB;
    margin: .5rem 0;
    border-radius: .25rem;
    width: inherit;
    padding: 1rem;
}

.modal-trialCalculation > .subTitle{
    color: #444;
    font-size: 0.75rem;
    padding: .75rem 0 .25rem;
}

.modal-trialCalculation > .value, .modal-applicableEquipment > .content{
    font-weight: bolder;
    font-size: 0.9rem;
}

.modal-applicableEquipment{
    background-color: #F5F5F5;
    border: 1px solid #EEEEEE;
    margin: .5rem 0;
    border-radius: .25rem;
    width: inherit;
    padding: 1rem;
}

.modal-applicableEquipment .title{
    color: #AAAAAA;
    font-size: 0.8rem;
}

.modal-applicableEquipment .continueTitle{
    color: #AAAAAA;
    font-size: 0.6rem;
}

.modal-applicableEquipment .content{
    font-size: 0.8rem;
}

.modal-applicableEquipment > .workOrderID{
    font-size: 0.9rem;
    font-weight: 900;
    margin-bottom: 0.75rem;
}

.border-error{
    border: 1px solid #F66F6F !important;
}

.programDetail {
    background-color: #F5F5F5;
    width: 100%;
    padding: 0.25rem;
}

.programDetail >.rowDetail {
    padding: 0.25rem 0.75rem;
}

.programDetail >.rowDetail > .mainTitle{
    color: #AAAAAA;
    font-size: 0.625rem;
}

.programDetail >.rowDetail > .content, .programDetail >.rowDetail > .contentDescription{
    font-size: 0.875rem;
}

.programDetail >.rowDetail > .content{
    margin-left: 0.5rem;
}

.input-wrapper {
    position: relative;
    display: inline-block;
}

.prefix {
    position: absolute;
    left: 10px; /* 調整 "WO" 距離輸入框的距離 */
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px; /* 設置字型大小 */
    color: #333; /* 設置顏色 */
    pointer-events: none; /* 禁止點擊 "WO" */
}

.orderNoInput{
    padding-left: 2.5rem !important; /* 增加左邊距，以便 "WO" 不會遮擋住輸入框 */
    font-size: 14px;
}

/*---跳出視窗的暫停工單 START ↓ ↓ ↓ */

.pausedWorkOrderDiv{
    margin: 1rem 0;
    padding: .75rem;
    background-color: #FFF3B6;
    border-radius: .5rem;
}

.pausedWorkOrderDiv > .titleRow{
    display: flex;
}

.pausedWorkOrderDiv > .titleRow > .title{
    font-size: 0.9rem;
    font-weight: 900;
    width: 90vw;
}

.pausedWorkOrderDiv > .titleRow > .info{
    width: 10vw;
    justify-content: end;
    align-items: center;
    /* background-color: #FFAA00 !important; */
}

.pausedWorkOrderDiv > .content{
    font-size: 0.8rem;
    margin-top: .25rem;
}

.pausedWorkOrderDiv > .contentRow{
    display: flex;
    justify-content: flex-end;
}

.pausedWorkOrderDiv > .contentRow > .link{
    font-size: 0.8rem;
    color: #3D71F8;
    cursor: pointer;
}

.yellow {
    background: #FFAA00 !important;
}
/*---跳出視窗的暫停工單 END*/

/*---無法進行排程 START*/
.unableScheduleContent{
    color: #444444;
    font-size: 0.9rem;
}

.unableScheduleLink{
    color: #3D71F8;
    font-size: 0.9rem;
    margin: 1rem 0;
    display: block;
}

.unableSchedule-programList{
    background-color: #F3F6FB;
    border-radius: .25rem;
    color: #444444;
    padding: .75rem;
    font-size: 0.9rem;
}

.unableSchedule-programList > .copyRow{
    display: flex;
    justify-content: flex-end;
}

.unableSchedule-programList > .copyRow > .copyTxt{
    color: #9FBAFF;
    font-size: 0.8rem;
}
/*---無法進行排程 END*/