.input-dropdown {
  cursor: pointer;
  position: relative;
  width: 200px;
  height: 100%;
}

.input-dropdown>div:first-child {
  position: relative;
  height: 100%;
}

/* .input-dropdown .icon {
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(0, -45%);
  border-left: #CCC 1px solid;
  border-radius: .25rem;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
}

.input-dropdown .icon:hover {
  background: #F5F5F5;
}

.input-dropdown .icon>div {
  position: relative;
  box-sizing: border-box;
  height: 1.5rem;
  width: 1.5rem;
  background: #888;
  display: inline-flex;
  align-items: center;
  margin-left: .25rem;
} */

.input-dropdown .text {
  color: #888;
  box-sizing: border-box;
  font-size: 85%;
  width: calc(100% - 2rem);
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-dropdown .text.selected {
  color: #000 !important;
}

.dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-options label {
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  font-size: .875rem;
}

.option:hover {
  background: #f5f5f5;
}

.option input {
  margin-right: 8px;
}

.multi-select-dropdown {
  position: relative;
  z-index: 1000;
}

#searchInput {
  width: 9rem;
}

.btn-dropdown {
  position: relative;
  /* display: flex; */
  height: 100%;
}

.btn-dropdown>div {
  padding-right: 2.5rem;
}

.btn-dropdown>i {
  position: absolute;
  right: .25rem;
  top: .25rem;
  height: 2rem;
  width: 2rem;
  border: 0;
  border-radius: .25rem;
}

.search-input {
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.clear-btn {
  cursor: pointer;
  font-size: 16px;
}

/* .dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  display: none;
}

.dropdown-options input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 8px;
} */

.dropdown-option {
  font-size: .75rem;
  display: block;
  padding: 8px;
  cursor: pointer;

}

.dropdown-option:hover {
  background-color: #F5F5F5;
}

.dropdown-option input {
  display: none;
}

.dropdown-option .icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #eee;
  margin-right: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dropdown-option .text {
  padding: .25rem .5rem;
  border-radius: .5rem;
}

.dropdown-option input:checked+.icon {
  background-color: #0346F2;
}

.dropdown-option input:checked~.text {
  color: #0346F2;
  font-weight: 500;
  background: rgba(61, 113, 248, .1);
}

.selected-option {
  font-size: .75rem;
  display: inline-flex;
  align-items: center;
  margin: .25rem;
  padding: .25rem .4rem .25rem .75rem;
  background: rgba(61, 113, 248, .1);
  color: #0346F2;
  border-radius: .75rem;
  cursor: default;
}

.selected-option:hover {
  outline: 1px solid rgba(61, 113, 248, .3);
}

.selected-option .delete-option {
  width: .875rem;
  height: .875rem;
  text-align: center;
  line-height: 1;
  margin-left: .5rem;
  color: #0346F2;
  cursor: pointer;
  padding: .125rem;
  border-radius: 50%;
}

.selected-option .delete-option:hover {
  background: rgba(61, 113, 248, .15);
}

.clear-btn {
  display: none;
  cursor: pointer;
  font-size: 16px;
}