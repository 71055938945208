/*跳出「選擇」視窗 */

.h40 {
    height:40vh;
}

.h60 {
    height:60vh;
}

/*----抄Bootstrap的Modal CSS START ↓ ↓ ↓ */

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    outline: 0;
}

.modal-dialog {
    box-sizing: border-box;
    position: relative;
    width: auto;
    margin: .5rem;
    transform: translate(0, 0);
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 1.5rem auto;
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 0.625rem;
    padding: 1rem;
}

.Modal-body {
    padding: 0rem 1rem .5rem 1rem;
    max-height: calc(95vh - 16.5rem);
    /* overflow-y: auto; */
}

.Modal-body .text{
    font-size: .875rem;
}

.modal-footer {
    display: block;
    padding: 0.5rem 1rem;
    border-top: none !important;
}

/*----抄Bootstrap的Modal CSS END ↑ ↑ ↑ */

/*----Modal 重要樣式 START ↓ ↓ ↓ */

.modal-content .scroll {
    max-height: 90vh;
    overflow-y: auto;
}

/* .modal-body.scroll {
    max-height: calc(95vh - 16rem);
    overflow-y: auto;
} */


.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.modal-title-note {
    font-size: 0.75rem;
    color: #AAA;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: none;
}

.close {
    float: right;
}

.close div {
    opacity: .5;
    height:2rem;
    width:2rem;
}
.Light .close div {
    background: #000;
}
.Dark .close div {
    background: #FFF;
}

.close:hover div {
    opacity: 1;
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
}

/*---跳出視窗的Body-輸入欄 START ↓ ↓ ↓ */
.modal-form {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    cursor: default;
}

.modal-form hr {
    width:100%;
    border: #EEE 1px solid;
    margin-top:1rem;
}

.modal-form legend {
    display: none;
}

.modal-form .w25 {
    box-sizing: border-box;
    width:calc(25% - 1rem);
}

.modal-form .w30 {
    box-sizing: border-box;
    width:calc(30% - .625rem);
}

.modal-form .w50 {
    box-sizing: border-box;
    width:calc(50% - .625rem);
}

.modal-form .w70 {
    box-sizing: border-box;
    width:calc(70% - .625rem);
}

.modal-form .w100 {
    box-sizing: border-box;
    width:100%;
}

.modal-inputText {
    display: flex;
    flex-direction: column;
}

@media (width<=520px) {
    .modal-form .w50,
    .modal-form .w70 {
        width:100%;
    }

    .modal-form .w25 {
        width:calc(50% - .625rem);
    }

    .modal-form .mR20:nth-child(even) {
        margin-right: 0;
    }
    
}

.modal-inputText input,
.modal-inputText textarea,
.modal-inputText select,
.modal-inputText .select,
.modal-inputText .input-wrapper {
    /* width:100%; */
	order: 2;
}

.modal-inputText input:disabled {
    background-color: #F5F5F5;
    border: 1px solid #CCCCCC;
    color: #AAAAAA;
}

/* .modal-inputText input {
    height:2.5rem;
} */

.modal-inputText textarea {
    height:5rem;
}

.modal-inputText label {
    /* width: 100%; */
    min-height: 1rem;
	order: 1;
    margin-top: 1rem;
    font-size: .75rem;
    line-height: 100%;
    color:#666;
}

.modal-inputText span {
    width: 100%;
	order: 3;
}

.modal-inputText label .charactor {
    color:#AAA;
    margin-left: .5rem;
}

.modal-inputText input[required] + label:after,
.modal-inputText textarea[required] + label:after,
.modal-inputText select[required] + label:after,
.modal-inputText .select.required + label:after{
    content:" *";
    font-size: .75rem;
    color:#F66F6F;
}