.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  z-index: 1000;
}

.fixed-header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 13rem;
  background-color: #F8F9FC;
  opacity: 0.8;
  z-index: 9;
}

.workPieceContent {
  margin-top: 10.625rem;
  padding: 20px;
}

/*border樣式*/
.table-header-border {
  border-bottom: 1px solid #000;
}

@media (min-width: 768px) {
  .choiceWorkpieceModalBody {
    padding: 2rem !important;
  }
}

@media (max-width: 767px) {
  .choiceWorkpieceModalBody {
    padding: 1.5rem !important;
  }
}

/* 計算機表頭 */
.header-calculator-container {
  width: 100%;
  height: 6rem;
  background: #fff;
}

.header-calculator-container-phone {
  width: 100%;
  height: 8rem;
  background: #fff;
}


/*Card樣式*/
.card-total {
  border-radius: 0.5rem !important;
  box-shadow: 0px 0px 10px #D4E0FF;
  background: #fff;
}

.card-workpiece {
  border-radius: 0.75rem !important;
  background: #fff;
  border: none !important;
}

.card-workpiece-formula {
  border-radius: 1.5rem;
  border: 1px solid #CCCCCC;
}

.card-workpiece-formula-items {
  border-radius: 0.5rem;
  background: #fff;
}

.card-workpiece-formula-items-selected {
  border-radius: 0.5rem;
  background: #EBEEFB;
}

.card-search {
  background: #EBEEFB;
  color: #3D71F8;
  border-radius: 2rem;
  font-size: 1rem;
}

.card-search-circle {
  background: #9FBAFF;
  border-radius: 50rem;
  width: 10rem !important;
  height: 10rem !important;
}

.card-workpiece-modal-items {
  border-radius: 0.75rem !important;
  background: #fff;
  border: 1px solid #CCCCCC;
}

.card-workpiece-modal-items-selected {
  border-radius: 0.75rem !important;
  background: #EBEEFB;
  border: 1px solid #3D71F8;
}

.card-workpiece-modal-items-exists {
  border-radius: 0.75rem !important;
  background: #f4f4f4;
  border: 1px solid #CCCCCC;
}

.card-adjustingCost {
  background: #EBEEFB;
  border: 1px solid #9FBAFF;
  border-radius: 0.5rem;
}

.card-upload-div {
  width: 20rem;
  height: 25rem;
  /* border: 1px solid black; */
  position: relative;
}

.card-upload {
  width: 20rem;
  height: 20rem;
  border: 1px solid #3D71F8;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D4E0FF;
  border-radius: 1rem;
}

.card-upload-in-div {
  width: 13rem;
  height: 15rem;
  /* border: 1px solid purple; */
  position: relative;
}

.card-upload-in-topCard {
  width: 6rem;
  height: 6rem;
  background: #fff;
  position: absolute;
  top: 20%;
  left: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D71F8;
  border-radius: 1rem;
}

/* .card-upload-in-button {
    width: fit-content;
    white-space: nowrap;
    padding: 0.5rem 1rem;
    background: #000000;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
  } */

/* .card-upload-in-button:hover {
   width: 8rem;
    padding: 0.5rem 0.25rem;
    background: #000000;
    position: absolute;
    bottom: 10%;
    left: 20%;
    color: #fff;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 4px #3D71F8;
  } */

.card-upload-in-topCard-border {
  width: 5rem;
  height: 5rem;
  background: #fff;
  border: 1px solid #9FBAFF;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.card-upload-in-bpttomCard-button {
    width: fit-content;
    white-space: nowrap;
    padding: 0.5rem 1rem;
    background: #FFF;
    border: 1px solid #CCCCCC;
    border-radius: 0.5rem;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    cursor: pointer;
  }
  
  .card-upload-in-bpttomCard-button:hover {
    width: fit-content;
    padding: 0.5rem 1rem;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 0.5rem;
    position: absolute;
    bottom: 0%;
    left: 50%;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 3px #9FBAFF;
  }*/

.card-message-error {
  padding: 1rem;
  background: #FFD4D4;
  border: 1px solid #F66F6F;
  border-radius: 0.5rem;
}

.card-message-success {
  padding: 1rem;
  background: #DCF2C4;
  border: 1px solid #7CC629;
  border-radius: 0.5rem;
}

.card-message-warning {
  padding: 1rem;
  background: #FFF3B6;
  border: 1px solid #FFAA00;
  border-radius: 0.5rem;
}

.card-uploadImage-illustrate {
  border: 1px solid #3D71F8;
  background: #D4E0FF;
  border-radius: 1rem;
}

.card-noImage {
  border-radius: 0.75rem !important;
  background: #F0F3FF;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  min-width: 14rem;
}


.text-unselected-div {
  height: calc(70vh);
}

.text-unselected {
  background: #D6DEFF;
  color: #3D71F8;
  border-radius: 2rem;
}

/*dropdown下拉選單*/
.custom-dropdown {
  width: fit-content;
  padding: 1rem;
  background: #F8F9FC;
  color: #000;
  border: none;
  border-radius: 0.35rem;
  font-size: large;
}

.custom-dropdown option {
  display: block;
  background: #fff;
  color: #333;
}

.custom-dropdown-phone {
  padding: 1rem;
  background: #F8F9FC;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: large;
}

.custom-dropdown-phone option {
  background: #fff;
  color: #333;
  margin: 2rem;
  padding: 5rem !important;
}

.custom-dropdown-adjustingCost {
  padding: 1rem;
  background: #F8F9FC;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: large;
}

.custom-dropdown-adjustingCost option {
  background: #fff;
  color: #333;
  margin: 2rem;
  padding: 5rem !important;
}

.input-big-text {
  color: #000000;
  background: #F8F9FC;
  border: none;
  border-radius: 0.5rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}

.input-big-text:disabled {
  color: #AAA;
  background: #F8F9FC;
  border: none;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}

.input-big-text-alert {
  color: #F66F6F;
  background: #F8F9FC;
  border: 1px solid #F66F6F;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}

.input-big-text-alert:disabled {
  color: #AAA;
  background: #F8F9FC;
  border: 1px solid #F66F6F;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}

.input-big-text-warning {
  color: #FFAA00;
  background: #F8F9FC;
  border: 1px solid #FFAA00;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}

.input-big-text-warning:disabled {
  color: #AAA;
  background: #F8F9FC;
  border: 1px solid #FFAA00;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 1.5rem;
}