.fixsize38 {
    width:2.375rem;
}

@media (width <=600px) {
    .fixsize38 {
        width:3rem;
    }
}

.filterRowDiv2,.filterRowDiv2+.error-text  {
    width: calc(22rem + 0.25rem);
}

.filterRowDiv2>div {
    width: 22rem;
}

.filterRowDiv2>.input .text {
    width: 18.5rem;
    text-align: left;
}

.input .text {
    /* color: #888; */
    margin: auto;
    box-sizing: border-box;
}

.input-dropdown .icon {
    color: #888;
    margin: 0.5rem 0.5rem;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
}

.input-dropdown .icon>span {
    border-left: 1px solid #CCC;
    width: 0.25rem;
    height: 1rem;
}

.input-dropdown svg {
    fill: #AAA;
    line-height: 1;
    stroke-width: 0;
}

.box-errortxt {
    display: flex;
    flex-direction: column;
}

.error .input {
    border-color: #F66F6F;
}

.error>.error-text {
    color: #F66F6F;
    font-size: .75rem;
    line-height: .75rem;
    word-break: keep-all;
    height: fit-content;
    margin-left: .25rem;
    margin-bottom: .25rem;
}

@media (width <=600px) {

    .filterRow .filterRowDiv2 {
        height: calc(3rem + 0.25rem);
    }

    .filterRowDiv2,
    .filterRowDiv2+.error-text,
    .box-errortxt {
        width: 100%;
    }

    .filterRowDiv2>div {
        width: calc(100% - 0.25rem);
    }


    .filterRowDiv2>.input .text {
        width: calc(100% - 1.75rem);
    }

    .error>.error-text {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: .75rem;
    }
}

.custom-machine-dropdown .btn.dropdown-toggle::after {
    display: none; /* 仅隐藏带有 custom-dropdown 类的下拉菜单的小图标 */
}

.custom-machine-dropdown .btn.dropdown-toggle{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0.5rem;
    display: flex;
    justify-content: left;
    align-items: center;
    /* color: #888; */
}

.custom-machine-dropdown .btn.dropdown-toggle.show{
    border: none !important;
}

.custom-machine-dropdown .btn.dropdown-toggle .icon{
    position: absolute;
    top: 0;
    right: 0.5rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.custom-machine-dropdown .btn.dropdown-toggle .icon>span{
    border-left: 1px solid #CCC;
    width: 0.25rem;
    height: 1rem;
}

.custom-machine-dropdown .btn.dropdown-toggle .icon>div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-machine-dropdown .btn.dropdown-toggle .icon svg{
    fill: #AAA;
    line-height: 1;
    stroke-width: 0;
}

/*第1區塊：篩選 END ↑ ↑ ↑ */

/*------------------------------------------------------------*/
/*第2區塊：「顯示」選取的資料列 START ↓ ↓ ↓ */
.area-show-items {
    display: flex;
    flex-wrap: wrap;
    /* width:100%; */
    align-items: center;
}

.area-show-items .title {
    font-size: 0.875rem;
    color: #3259CA;
    line-height: 0.875rem;
    margin: 0 0.5rem 0.25rem 0;
    width: fit-content;
}

@media (width <=990px) {
    .area-show-items .title {
        margin: 0 0.5rem 0.5rem 0;
    }
}

.area-show-items .item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.area-show-items .item>div {
    box-sizing: border-box;
    padding: 0.3125rem 0.625rem;
    border-radius: 1.5rem;
    width: fit-content;
    margin: 0 0.5rem 0.25rem 0;
    font-size: 0.875rem;
    line-height: 0.875rem;
    cursor: pointer;
}

@media (width <=990px) {
    .area-show-items .item>div {
        font-size: 1rem;
        line-height: 1rem;
        padding: 0.5rem 0.875rem;
        margin: 0 0.75rem 0.5rem 0;
    }
}

.area-show-items .item .txt-btn {
    box-sizing: border-box;
    padding: 0.3125rem 0.625rem;
    width: fit-content;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    color: #3D71F8;
    border: 0;
    border-radius: 0.4rem;
}

.area-show-items .item .txt-btn:hover {
    background-color: #D4E0FF;
}

.area-show-items .item .show {
    background-color: #3D71F8;
    border: 1px solid #9FBAFF;
    color: #FFF;
}

.area-show-items .item .show:hover {
    background-color: #0346F2;
    border: 1px solid #3D71F8;
}

.area-show-items .item .hide {
    background-color: #EBEEFB;
    border: 1px solid #9FBAFF;
    color: #3D71F8;
}

.area-show-items .item .hide:hover {
    background-color: #D4E0FF;
}

.area-show-items .item .nodata {
    background-color: #EEE;
    border: 1px solid #CCC;
    color: #AAA;
    cursor: default;
}

.area-show-items .item .loading {
    background-color: #3D71F8;
    border: 1px solid #9FBAFF;
    opacity: 50%;
    color: #FFF;
    position: relative;
    overflow: hidden;
    padding-left: 1.5rem;
}

.area-show-items .item .loading:hover {
    opacity: 80%;
}

.area-show-items .item .loading::before {
    position: absolute;
    top: 25%;
    left: 0.4rem;
    content: " ";
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    box-sizing: border-box;
    transform: translateZ(0) scale(0.5);
    transform-origin: 50% 50%;
    transform-box: fill-box;
    border-radius: 50%;
    border: 1px solid currentColor;
    border-left-color: transparent;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.area-show-items .item .loading::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    opacity: 50%;
    transition: scale(1);
    border-radius: 30%;
    animation: wave 10s ease-in-out infinite;
}

@keyframes wave {
    0% {
        transform: translateY(-20%) translateX(-40%) rotate(-30deg) scale(1.2);
    }

    50% {
        transform: translateY(-60%) translateX(40%) rotate(30deg) scale(1.2);
    }

    100% {
        transform: translateY(-20%) translateX(-40%) rotate(-30deg) scale(1.2);
    }
}

/*第2區塊：「顯示」選取的資料列 END*/

/*------------------------------------------------------------*/
/*第3區塊：圖表 START  ↓ ↓ ↓ */
/*-----8/28增加 START ↓ ↓ ↓ */
.min-height160 {
    min-height: 10rem;
}

.status-nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 10rem;   
}

.status-nodata>div {
    background-color: #EBEEFB;
    color:#3D71F8;
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
    border-radius: 1rem;
}

/*-----8/28增加 END ↑ ↑ ↑ */
.box-style-white-shadow .decoline {
    position: absolute;
    border-left: 2px solid #9FBAFF;
    margin-left: 8rem;
    height: 100%;
    width: 0px;
}

@media (width <=990px) {
    .box-style-white-shadow .decoline {
        display: none;
    }
}

.title-and-chart {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    margin: 1rem 0;
    position: relative;
}

@media (width <=990px) {
    .title-and-chart {
        flex-direction: column;
        align-items: start;
    }
}

.title-and-chart .title {
    box-sizing: border-box;
    width: 7rem;
    margin-right: 1.5rem;
}

@media (width <=990px) {
    .title-and-chart .title {
        width: fit-content;
        margin-right: 0;
        display: flex;
        flex-direction: row;
    }
}

.title-and-chart .align-center {
    align-self: center;
}

.title-and-chart .align-top {
    padding-top: 1.25rem;
    align-self: start;
}

.title-and-chart .chart {
    box-sizing: border-box;
    width: calc(100% - 8.5rem);
}

@media (width <=990px) {
    .title-and-chart .chart {
        width: 100%;
    }
}

.title-and-chart .title .maintitle {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 0.875rem;
}

/*2024/09/30新增，用於讓點點圖的title文字可以在第2行之後就不顯示，超出的顯示「...」*/
.title-and-chart .chart-scatter.title .maintitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (width <=990px) {
    .title-and-chart .title .maintitle {
        margin-bottom: 0.25rem;
    }
}

.title-and-chart .title .subtitle {
    max-height: 13.5rem;
    overflow-y: auto;    
}

.title-and-chart .title .subtitle>div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0.25rem 0;
}

@media (width <=990px) {
    .title-and-chart .title .subtitle>div {
        align-items: start;
        margin: 0 0;
    }
}

.title-and-chart .title .subtitle .text {
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.title-and-chart .title .subtitle .dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.15rem;
}

.title-and-chart .shortwidth {
    margin-left: 49px;
    width: calc(100% - 49px);
}

/*圖表區塊元件 END ↑ ↑ ↑ */

/*圖表群組1：設備狀態 START ↓ ↓ ↓ */
.chart_timeline_machinestatus {
    width: 100%;
    height: 1.5rem;
}

.chart_timeline_machinestatus>div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.chart_timeline_machinestatus>div>div {
    box-sizing: border-box;
}

.chart_timeline_machinestatus .running {
    background-color: #7CC629;
}

.chart_timeline_machinestatus .idle {
    background-color: #FFAA00;
}

.chart_timeline_machinestatus .error {
    background-color: #F66F6F;
}

.chart_timeline_machinestatus .offine {
    background-color: #AAAAAA;
}

@media (width <=990px) {
    .chart_timeline_machinestatus {
        height: 2rem;
    }
}

/*圖表群組1：設備狀態 END ↑ ↑ ↑ */

/*圖表群組2：副程式 START ↓ ↓ ↓ */
.chart_timeline_subprogram {
    box-sizing: border-box;
    width: 100%;
    height: 1.5rem;
}


.chart_timeline_subprogram>div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #CCC;
    position: relative;
}

.chart_timeline_subprogram>div>div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 0;
}

.chart_timeline_subprogram>div>div>div:nth-child(1) {
    height: 0;
}

.chart_timeline_subprogram>div>div:nth-child(1)>div:nth-child(2) {
    border-left: #FFF solid 0px;
}

.chart_timeline_subprogram>div>div>div:nth-child(2) {
    background-color: #9FBAFF;
    border-left: #FFF solid 1px;
    position: relative;
    height: 1rem;
}

.chart_timeline_subprogram>div>div:nth-child(even)>div:nth-child(2) {
    background-color: #D4E0FF;
}

.chart_timeline_subprogram .text {
    display: inline;
    color: #444;
    font-size: 0.625rem;
    line-height: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (width <=990px) {

    .chart_timeline_subprogram, .chart_timeline_subprogram>div,
    .chart_timeline_subprogram>div>div>div:nth-child(2) {
        height: 2rem;
    }

    .chart_timeline_subprogram .text {
        line-height: 2rem;
        font-size: 1rem;
    }
}

/*圖表群組2：副程式 END ↑ ↑ ↑ */


/*圖表群組3：主程式 START ↓ ↓ ↓ */

.chart_timeline_mainprogram {
    width: 100%;
    height: 1rem;
}

.chart_timeline_mainprogram>div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #CCC;
    position: relative;
}

.chart_timeline_mainprogram>div>div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 0;
}

.chart_timeline_mainprogram>div>div>div:nth-child(1) {
    height: 0;
}

.chart_timeline_mainprogram>div>div:nth-child(1)>div:nth-child(2) {
    border-left: #FFF solid 0px;
}

.chart_timeline_mainprogram>div>div>div:nth-child(2) {
    background-color: #3D71F8;
    border-left: #FFF solid 1px;
    position: relative;
    height: 0.5rem;
}

.chart_timeline_mainprogram .text {
    display: inline;
    color: #FFF;
    font-size: 0.625rem;
    line-height: 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.5rem;
}

@media (width <=990px) {

    .chart_timeline_mainprogram,
    .chart_timeline_mainprogram>div>div>div:nth-child(2) {
        height: 2rem;
    }

    .chart_timeline_mainprogram>div .text {
        line-height: 2rem;
        font-size: 1rem;
    }
}

/*圖表群組3：主程式 END ↑ ↑ ↑ */


/*圖表群組4：刀具 START ↓ ↓ ↓ */
.chart_timeline_tool {
    width: 100%;
    height: 1rem;
}

.chart_timeline_tool>div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #CCC;
    position: relative;
}

.chart_timeline_tool>div>div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 0;
}

.chart_timeline_tool>div>div>div:nth-child(1) {
    height: 0;
}

.chart_timeline_tool>div>div:nth-child(1)>div:nth-child(2) {
    border-left: #FFF solid 0px;
}

.chart_timeline_tool>div>div>div:nth-child(2) {
    background-color: #9FBAFF;
    border-left: #FFF solid 1px;
    position: relative;
    height: 0.5rem;
}

.chart_timeline_tool div>div:nth-child(even)>div:nth-child(2) {
    background-color: #D4E0FF;
}

.chart_timeline_tool .text {
    display: inline;
    color: #444;
    font-size: 0.625rem;
    line-height: 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (width <=990px) {

    .chart_timeline_tool,
    .chart_timeline_tool>div>div>div:nth-child(2) {
        height: 2rem;
    }

    .chart_timeline_tool .text {
        line-height: 2rem;
        font-size: 1rem;
    }
}

/*圖表群組4：刀具 END ↑ ↑ ↑ */

/*圖表群組5：折線圖 START ↓ ↓ ↓ */

.title-and-chart .area-linechart {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

@media (width <=990px) {
    .title-and-chart .area-linechart {
        position: relative;
        display: block;
    }
}

.title-and-chart .area-linechart>div:nth-child(1) {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

/*2024/09/27調整選擇器名稱，將折線圖和點點圖的高度分開定義。這裡是定義折線圖的title高度*/
.area-linechart .chart-line.title {
    height: 290px;
    position: relative;
}

.area-linechart .chart-line.title:last-child {
    height: 250px;
}

/*2024/09/27新增，定義點點圖的title的高度*/
.area-linechart .chart-scatter.title {
    height: 60px;
    position: relative;
}

.area-linechart .chart-scatter.title:last-child {
    height: 60px;
}

/*2024/09/30調整，定義loading波紋顯示的範圍*/
.area-linechart .title .chartbox {
    position: absolute;
    width: calc(100vw - 15.5rem);
    top: 0.5rem;
    left: 8.5rem;
    z-index: 1;
}

/*2024/09/30新增，分別定義折線圖和點點圖loading波紋顯示高度*/
.area-linechart .chart-line.title .chartbox {
    height: calc(100% - 3rem);
}
.area-linechart .chart-scatter.title .chartbox {
    height: calc(100% - 1.5rem);
}

.area-linechart .title:last-child .chartbox {
    height: calc(100% - 0.5rem);
}

.area-linechart .title .loading {
    opacity: 0.5;
    border-radius: 0.5rem;

    background:
        linear-gradient(90deg, #FFF 43%, #EBEEFB 50%, #FFF 66%) #FFF;
    background-size: 300% 100%;
    animation: l1 3s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes l1 {
    0% {
        background-position: right
    }
}


.spinner {
    width: 120px;
    height: 10px;
    border-radius: 40px;
    color: #9FBAFF;
    border: 2px solid;
    position: relative;
    overflow: hidden;
}

.spinner::before {
    content: "";
    position: absolute;
    margin: 2px;
    width: 14px;
    top: 0;
    bottom: 0;
    left: -20px;
    border-radius: inherit;
    background: currentColor;
    box-shadow: -10px 0 12px 3px currentColor;
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%, -30px 50%);
    animation: l14 3s infinite linear;
}

@keyframes l14 {
    100% {
        left: calc(100% + 20px)
    }
}


@media (width <=990px) {
    .area-linechart .title:last-child {
        height: 300px;
    }

    .area-linechart .title .subtitle {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .area-linechart .title .subtitle>div {
        margin-left: 1rem;
    }

    .area-linechart .chart {
        position: absolute;
        top: 30px;
        left: 0;
    }

    .area-linechart .title .chartbox,
    .area-linechart .title:last-child .chartbox {
        height: calc(100% - 3rem);
        width: calc(100vw - 6.5rem);
        top: 2.5rem;
        left: 0;
    }
}

/*圖表群組5：折線圖 END ↑ ↑ ↑ */

/*圖表群組6：作動軸 START ↓ ↓ ↓ */
.chart_timeline_action {
    width: 100%;
}

.chart_timeline_action .line {
    box-sizing: border-box;
    width: 100%;
    height: 0.3125rem;
    background-color: #EEE;
    border-radius: 0.15625rem;
    margin-top: 0.21875rem;
}

.chart_timeline_action .action {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 0.21875rem;
}

.chart_timeline_action .action>div {
    position: relative;
    height: 0%;
}

.chart_timeline_action .action>.dot>div {
    background-color: #7CC629;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    position: absolute;
    right: -0.375rem;
    top: -0.484375rem;
}

/*圖表群組6：作動軸 END ↑ ↑ ↑ */

/*功能：滑鼠游標對齊遮罩 START ↓ ↓ ↓ */
.func_pointarea {
    box-sizing: border-box;
    width: calc(100% - 8.5rem);
    height: 100%;
}

.func_pointarea .shortwidth {
    margin-left: 49px;
    width: calc(100% - 185px);
}

@media (width <=990px) {
    .func_pointarea {
        width: 100%;
    }

    .func_pointarea .shortwidth {
        margin-left: 49px;
        width: calc(100% - 50px);
    }
}

.func_pointarea>div {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 8.5rem;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(60, 1fr);
}

@media (width <=990px) {
    .func_pointarea>div {
        left: 0rem;
    }
}

.func_pointarea>div>div {
    background-color: none;
    height: 100%;
    opacity: 0%;
}

.func_pointarea>div>div:hover {
    background-color: #9FBAFF;
    opacity: 10%;
}

.hidden {
    visibility: hidden;
}

/*功能：滑鼠游標對齊遮罩 END ↑ ↑ ↑ */

/*-----8/28增加 START ↓ ↓ ↓ */
/*功能：遮罩+「回復」按鈕 START ↓ ↓ ↓ */
.func-revert {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #d4e0ff;
    border-radius: 0.625rem;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-align: center;
}

.func-revert>div {
    position: sticky;
    bottom: 30vh;
}

.func-revert>div>div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin: 3rem;
}

.func-revert .btn {
    background-color: rgba(212, 224, 255, 0.3);
    border-radius: 0.625rem;
    height: 7rem;
    width: 7rem;
    color: #3259CA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
}

.func-revert .btn>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.func-revert .btn>div>div:first-child {
    background-color: rgba(212, 224, 255, 0.8);
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
}

.func-revert .btn .icon {
    font-size: 1.5rem;
}

.func-revert .btn .text {
    font-size: 1rem;
    font-weight: 500;
}

.func-revert .btn:hover {
    background-color: rgba(212, 224, 255, 0.5);
}

.func-revert .btn:hover>div>div:first-child {
    background-color: rgba(159, 186, 255, 0.5);
}

.func-revert .btn:active {
    background-color: rgba(212, 224, 255, 0.7);
}

.func-revert .btn:active>div>div:first-child {
    background-color: rgba(159, 186, 255, 0.7);
}

.func-revert .title {
    font-size: .875rem;
    color: #3D71F8;
    font-weight: 500;
}

.func-revert .note {
    font-size: .75rem;
    /* line-height: .75rem; */
    color: #3D71F8;
}

/*功能：遮罩+「回復」按鈕 END ↑ ↑ ↑ */
/*-----8/28增加 END ↑ ↑ ↑ */


/*-----8/29增加 END START ↓ ↓ ↓ */
/*狀態：選擇的區間無資料 START ↓ ↓ ↓ */
.title-and-chart .nodata-mask {
    background-color: rgba(243, 246, 251,0.7);
    display: flex;
    flex-wrap: wrap;   
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
}

.title-and-chart .nodata-mask>div {
    display: flex;
    flex-wrap: wrap;   
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    user-select:none;
    
}

.title-and-chart .nodata-mask .text {
    background-color:rgba(0,0,0,0);
    width: fit-content  !important;   
    color:#3D71F8; 
    font-size: 0.625rem;
    line-height: 1rem;
    height:fit-content;
    margin-left: 0 !important;
}

.title-and-chart .nodata-mask .icon {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2223.999%22 height=%2224%22 viewBox=%220%200%2023.999%2024%22><path id=%22Subtraction_2%22 data-name=%22Subtraction%202%22 fill=%22%233d71f8%22 d=%22M-638%2C3518a11.921%2C11.921%2C0%2C0%2C1-8.485-3.515A11.926%2C11.926%2C0%2C0%2C1-650%2C3506a11.925%2C11.925%2C0%2C0%2C1%2C3.515-8.485A11.921%2C11.921%2C0%2C0%2C1-638%2C3494a11.922%2C11.922%2C0%2C0%2C1%2C8.485%2C3.514A11.924%2C11.924%2C0%2C0%2C1-626%2C3506a11.925%2C11.925%2C0%2C0%2C1-3.514%2C8.486A11.924%2C11.924%2C0%2C0%2C1-638%2C3518Zm0-15a2%2C2%2C0%2C0%2C0-2%2C2v7a2%2C2%2C0%2C0%2C0%2C2%2C2%2C2%2C2%2C0%2C0%2C0%2C2-2v-7A2%2C2%2C0%2C0%2C0-638%2C3503Zm0-5a2%2C2%2C0%2C0%2C0-2%2C2%2C2%2C2%2C0%2C0%2C0%2C2%2C2%2C2%2C2%2C0%2C0%2C0%2C2-2A2%2C2%2C0%2C0%2C0-638%2C3498Z%22 transform=%22translate(650%20-3494)%22/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    height:0.5rem;
    width:0.5rem;
    margin-right: 0.25rem;
}

.title-and-chart .title .nodata {
    color:#AAA;
    text-decoration: line-through;
}


/*狀態：選擇的區間無資料 END ↑ ↑ ↑*/
/*-----8/29增加 END ↑ ↑ ↑ */

.box-note {
    box-sizing: border-box;
    background-color: #9FBAFF33;
    border-radius: .75rem;
    width:100%;
    padding: 1rem;   
    display: flex;
    flex-direction: row;    
    cursor: default;
    align-items:center ;
}

.box-note .title {    
    font-size: .75rem;
    width: fit-content;
    padding: 2px 5px;
    background-color: #FFFFFF66;
    color:#3D71F8;
    margin-right: .5rem;
}

.box-note .content { 
    font-size: .75rem;
    color:#3259CA;
}