.loginPreMain-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 使容器充滿整個視窗高度 */
}

.spinner-border{
    --bs-spinner-width: 5rem;
    --bs-spinner-height: 5rem;
    color: #3D71F8;
}

/* .text-blue{
    color: #3D71F8;
} */
  