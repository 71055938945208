:root {
    --dynamic-week-main-width: 100%;
    /* 週區間時的「main」寬度*/
}

.GanttChart-wrapper {
    width: 100%;
    box-sizing: border-box;
    /* max-height: 40rem; */
    cursor: default;
}

.ganttChart {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}
.ganttChart::after {
    content: "";
    position: absolute;
    height:calc(100% - 6px);
    width:1px;
    bottom:4px;
    right:0;
    border-right: 1px solid #CCC;
}

.ganttChart .table-left {
    width: 6.5rem;
    /* 若變數不可用，則回退到5rem */
    border-right: 1px solid #CCC;
    height: fit-content;
}

.ganttChart .table-right {
    width: calc(100% - 6.5rem);
    overflow-y: hidden;
    overflow-x: auto;
    /* border-right: 1px solid #CCC; */
    position: relative;
}
/* .ganttChart .table-right::after {
    content: "";
    position: absolute;
    height:calc(100% - 3px);
    width:1px;
    bottom:1px;
    right:0;
    border-right: 1px solid red;
} */


@media (width<873px) {
    .ganttChart .table-left {
        width: 0;
    }

    .ganttChart .table-right {
        width: 100%;
    }
}

.ganttChart .thead,
.ganttChart .tbody {
    width: 100%;
    box-sizing: border-box;
    flex-flow: column nowrap;
}

.ganttChart .tr,
.ganttChart .table-right.weekly .thead .tr.main>div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border: 0;
    padding: 0;
    margin: 0;
    align-items: center;
    box-sizing: border-box;
}

.ganttChart .table-right .tr {
    border-right: 1px solid #CCC;
}

.ganttChart .thead .tr.main {
    /* --gantt-trMain-Height: 2rem; */
    height: 2rem;
    overflow: visible;
}

.ganttChart .thead .tr.secondary,
.ganttChart .tbody .tr {
    height: 2.5rem;
}

.ganttChart .table-right .split {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    /* border-right: 1px solid #888; */
    margin-top: -1px;
    position: relative;
}
.ganttChart .table-right .split::before {
    position: absolute;
    content: "";
    height:100%;
    width:1px;
    left:100%;
    bottom:0;
    border-right: 1px solid #888;
}

/* .ganttChart .table-right .split:not(:first-child) {
    border-left: 1px solid #888;
} */
.ganttChart .table-right .split:not(:first-child)::after {    
    position: absolute;
    content: "";
    height:100%;
    width:1px;
    left:0;
    bottom:0;
    border-left: 1px solid #888;
}
/* .ganttChart .table-right .split:last-child::after {
    position: absolute;
    content: "";
    height:100%;
    width:1px;
    right:0;
    bottom:0;
    border-right: 2px solid red;
} */


.ganttChart .table-right.daily .tr.main .td,
.ganttChart .table-right.daily .split,
.ganttChart .table-right.weekly .split {
    width: 50%;
    min-width: 50%;
}

.ganttChart .table-right.monthly .td.wDay28,
.ganttChart .table-right.monthly .split.wDay28 {
    width: 45.161290%;
    min-width: 45.161290%;
}

.ganttChart .table-right.monthly .td.wDay29,
.ganttChart .table-right.monthly .split.wDay29 {
    width: 46.77419%;
    min-width: 46.77419%;
}

.ganttChart .table-right.monthly .td.wDay30,
.ganttChart .table-right.monthly .split.wDay30 {
    width: 48.387096%;
    min-width: 48.387096%;
}

.ganttChart .table-right.monthly .td.wDay31,
.ganttChart .table-right.monthly .split.wDay31 {
    width: 50%;
    min-width: 50%;
}

.ganttChart .table-right.weekly .dynamic-trMainWidth {
    width: var(--dynamic-week-main-width);
    /* 週區間時的「main」寬度*/
    min-width: var(--dynamic-week-main-width);
    /* 週區間時的「main」寬度 */
}


@media (width<1470px) {
    .ganttChart .table-right.weekly .dynamic-trMainWidth {
        width: calc(var(--dynamic-week-main-width) * 2);
        min-width: calc(var(--dynamic-week-main-width) * 2);
    }

    .ganttChart .table-right.daily .tr.main .td,
    .ganttChart .table-right.daily .split,
    .ganttChart .table-right.weekly .split {
        width: 100%;
        min-width: 100%;
    }

    .ganttChart .table-right.monthly .td.wDay28,
    .ganttChart .table-right.monthly .split.wDay28 {
        width: 90.32258%;
        min-width: 90.32258%;
    }

    .ganttChart .table-right.monthly .td.wDay29,
    .ganttChart .table-right.monthly .split.wDay29 {
        width: 93.54838%;
        min-width: 93.54838%;
    }

    .ganttChart .table-right.monthly .td.wDay30,
    .ganttChart .table-right.monthly .split.wDay30 {
        width: 96.77419%;
        min-width: 96.77419%;
    }

    .ganttChart .table-right.monthly .td.wDay31,
    .ganttChart .table-right.monthly .split.wDay31 {
        width: 100%;
        min-width: 100%;
    }
}

@media (width<700px) {

    .ganttChart .table-right.monthly .td.wDay28,
    .ganttChart .table-right.monthly .split.wDay28 {
        width: 135.4838709%;
        min-width: 135.4838709%;
    }

    .ganttChart .table-right.monthly .td.wDay29,
    .ganttChart .table-right.monthly .split.wDay29 {
        width: 140.3225806%;
        min-width: 140.3225806%;
    }

    .ganttChart .table-right.monthly .td.wDay30,
    .ganttChart .table-right.monthly .split.wDay30 {
        width: 145.1612903%;
        min-width: 145.1612903%;
    }

    .ganttChart .table-right.monthly .td.wDay31,
    .ganttChart .table-right.monthly .split.wDay31 {
        width: 150%;
        min-width: 150%;
    }
}

@media (width<500px) {

    .ganttChart .table-right.monthly .td.wDay28,
    .ganttChart .table-right.monthly .split.wDay28 {
        width: 180.64516%;
        min-width: 180.64516%;
    }

    .ganttChart .table-right.monthly .td.wDay29,
    .ganttChart .table-right.monthly .split.wDay29 {
        width: 187.096774%;
        min-width: 187.096774%;
    }

    .ganttChart .table-right.monthly .td.wDay30,
    .ganttChart .table-right.monthly .split.wDay30 {
        width: 193.54838%;
        min-width: 193.54838%;
    }

    .ganttChart .table-right.monthly .td.wDay31,
    .ganttChart .table-right.monthly .split.wDay31 {
        width: 200%;
        min-width: 200%;
    }
}

.ganttChart .table-left .tbody .th {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid #CCC;
    border-right: transparent;
    font-size: .875rem;
}

.ganttChart .table-left .tbody .th>div {
    text-align: left;
    width: 100%;
    z-index: 4;
    background: #FFFFFF88;
    padding: .25rem .5rem;
    border-radius: .5rem;
}


@media (width<873px) {
    .ganttChart .table-left .tbody .th>div {
        width: fit-content;
    }
}

.ganttChart .table-left .tbody .tr:not(:first-child) .th {
    border-top: transparent;
}

.ganttChart .table-right .td {
    height: 100%;
    box-sizing: border-box;
    padding: .25rem .5rem;
    text-align: center;
    /* border: 1px solid #CCC; */
    border-bottom: 1px solid #DDD;
    font-size: .875rem;
    position: relative;
    display: grid;
    place-content: center;
    background: #FFF;
    /* outline: 1px solid #CCC; */
}
.ganttChart .table-right .tr:not(.main) .td:not(:last-child)::after
/* .ganttChart .table-right.daily .tr .td::after,
.ganttChart .table-right.monthly .tr .td::after */
 {
    content: "";
    height:100%;
    width: 1px;
    position: absolute;
    right:0;
    bottom:0;
    border-right: 1px solid #DDD;
}

.ganttChart .table-right .tr.main .td:hover,
.ganttChart .table-right:not(.daily) .tr.secondary .td:hover {
    cursor: pointer;
    filter: brightness(0.9) contrast(1.2);
}

.ganttChart .table-right .tr.main .td:active,
.ganttChart .table-right:not(.daily) .tr.secondary .td:active {
    filter: brightness(0.85) contrast(1.3);
}

.ganttChart .table-right .tr.secondary .td:hover *:not(.weekday),
.ganttChart .table-right:not(.daily) .tr.secondary .td:hover {
    font-weight: bold;
}

.ganttChart .table-right.monthly .td {
    padding: .25rem;
}

.ganttChart .table-right.monthly .tr.secondary .td {
    font-size: .625rem;
}

.ganttChart .table-right .tr.main .td>div {
    position: sticky;
    left: .5rem;
}

.ganttChart .table-right .tr.secondary .td,
.ganttChart .table-right .tbody .td {
    flex: 1;
}

.ganttChart .table-right .tr.secondary .td,
.ganttChart .table-right .thead .tr.secondary .td>div,
.ganttChart .table-right .thead .tr.secondary .td>div>div {
    clip-path: inset(-2rem 0 0 0);
}

.ganttChart .table-right .thead .tr.secondary .td>div,
.ganttChart .table-right .thead .tr.secondary .td>div>div {
    white-space: nowrap;
    /* 強制單行顯示 */
    overflow: hidden;
    /* 隱藏超出範圍的內容 */
    text-overflow: ellipsis;
    /* 超出部分顯示「...」 */
}


/* .ganttChart .table-right .thead .tr.secondary .td,
.ganttChart .table-right .tbody .td {
    border-top: transparent;
} */

/* .ganttChart .table-right.monthly .tr.main .td:not(:last-child),
.ganttChart .table-right.daily .tr.main .td:not(:last-child) {
    border-right: 1px solid #888;
} */

/* .ganttChart .table-right.monthly .tr.main .td:not(:first-child),
.ganttChart .table-right.daily .tr.main .td:not(:first-child) {
    border-left: 1px solid #888;
} */

/* .ganttChart .table-right.weekly .tr.main .td {
    border-right: transparent;
}

.ganttChart .table-right .split .td:last-child {
    border-right: transparent;
} */

/* .ganttChart .table-right .thead .td,
.ganttChart .table-right .tbody .td {
    border-left: transparent;
} */

.ganttChart .table-right .tr.main .td {
    border-top: 1px solid #CCC;
}

.ganttChart .table-right.daily .tr.secondary .td {
    font-size: .625rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    text-align: center;
    padding: .25rem 3.2%;
}

.ganttChart .table-right.daily .tr.secondary .td>div {
    width:.75rem;
    color:#00000088;
    display: grid;
    place-content: center;
}

.ganttChart .table-right.daily .tr.secondary .td>div::after {
    width:fit-content;
    text-align: center;
    padding:.25rem 0;
    background: #FFFFFFAA;
    color:#00000088;
}

.ganttChart .table-right.daily .tr.secondary .td.restday>div::after {
    background: #F4F6FDAA;
    color:#9FBAFF;
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(1)::after {
    content: "1";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(2)::after {
    content: "2";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(3)::after {
    content: "3";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(4)::after {
    content: "4";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(5)::after {
    content: "5";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(6)::after {
    content: "6";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(7)::after {
    content: "7";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(8)::after {
    content: "8";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(9)::after {
    content: "9";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(10)::after {
    content: "10";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(11)::after {
    content: "11";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(12)::after {
    content: "12";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(13)::after {
    content: "13";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(14)::after {
    content: "14";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(15)::after {
    content: "15";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(16)::after {
    content: "16";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(17)::after {
    content: "17";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(18)::after {
    content: "18";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(19)::after {
    content: "19";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(20)::after {
    content: "20";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(21)::after {
    content: "21";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(22)::after {
    content: "22";
}

.ganttChart .table-right.daily .tr.secondary .td div:nth-child(23)::after {
    content: "23";
}

.ganttChart .td.restday {
    background: #EBEEFB88 !important;
}

.ganttChart .table-right.daily .tr.secondary .td,
.ganttChart .table-right.daily .tbody .td {
    background: repeating-linear-gradient(to right,
            /*垂直線條的方向*/
            /*下面這2行是垂直線的間隔顏色為透明、寬度為均分24格(23條線)(扣掉1px線的空間) */
            transparent 0px,
            transparent calc(100% / 24 - 1px),
            /*下面這2行是垂直線的顏色為#DDD和寬度為1px */
            #EEE calc(100% / 24 - 1px),
            #EEE calc(100% / 24));
}

.ganttChart .table-right.daily .tr.secondary .td.restday,
.ganttChart .table-right.daily .tbody .td.restday {
    background: repeating-linear-gradient(to right,
            /*垂直線條的方向*/
            /*下面這2行是垂直線的間隔顏色為#EBEEFB88、寬度為均分24格(23條線)(扣掉1px線的空間) */
            #EBEEFB88 0px,
            #EBEEFB88 calc(100% / 24 - 1px),
            /*下面這2行是垂直線的顏色為#DDD和寬度為1px */
            #D4E0FF calc(100% / 24 - 1px),
            #D4E0FF calc(100% / 24)) !important;
}

.ganttChart .weekday {
    color: #AAA;
    line-height: 1;
}

.ganttChart .main .weekday {
    font-size: .75rem;
    margin-left: .5rem;
}

.ganttChart .secondary .weekday {
    font-size: .625rem;
    margin: 0;
}

.ganttChart .table-right.weekly .tr.secondary .td.end {
    position: relative;
}

.ganttChart .table-right.weekly .tr.secondary .td.end:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    height: calc(2rem - 1px);
    border-right: 1px solid #CCC;
    width: 1px;
    z-index: 10;
}

/*定位排程條圖*/
.ganttChart .tbody .standard {
    position: relative;
}

.ganttChart .tbody .bar {
    box-sizing: content-box;
    position: absolute;
    height: 1.5rem;
    top: .43rem;
    z-index: 5;
    border-radius: .25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* clip-path: inset(0 0 0 0); */
    /* 當容器縮小時，裁減裡面的內容，不要超出區塊的範圍 */
    border: 1px solid #FFF;
}

.ganttChart .tbody .bar>div:not(.delayPin) {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    clip-path: inset(0 0 0 0);
    /* 當容器縮小時，裁減裡面的內容，不要超出區塊的範圍 */
}

.ganttChart .tbody .bar span {
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1.2;
    text-align: left;
    white-space: nowrap;
    /* 強制單行顯示 */
    overflow: hidden;
    /* 隱藏超出範圍的內容 */
    text-overflow: ellipsis;
    /* 超出部分顯示「...」 */
    position: sticky;
    left: .5rem;
}

/*未顯示到結束時間點的工單：當圖表上的區間無法完整顯示排程條圖，就把右邊的圓角拿掉變成平的*/
.ganttChart .tbody .bar.noEnd {
    border-radius: .25rem 0 0 .25rem;
    border-right: transparent;
}

.ganttChart .tbody .bar.noStart {
    border-radius: 0 .25rem .25rem 0;
    border-left: transparent;
}

/*交期延遲的工單，顯示「紅外框」+「hover出現漂浮pin」*/
.ganttChart .tbody .bar.delay {
    box-shadow: 0 0 0 2px #F66F6F;
}

.ganttChart .tbody .bar.delay~.delayPin {
    position: absolute;
    left: -.75rem;
    top: calc(-100% - .5rem);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.ganttChart .tbody .bar.delay:hover+.delayPin {
    animation: bounce 0.7s ease-in-out infinite alternate;
    opacity: 1;
    visibility: visible;
}

.ganttChart .tbody .delayPin>.icon {
    background: #FFFFFF88;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
}

.ganttChart .tbody .delayPin>.icon>div {
    background: #F66F6F;
    width: 100%;
    height: 100%;
}

.ganttChart .tbody .delayPin>.text {
    background: #F66F6F;
    color: #FFF;
    width: 3.25rem;
    padding: .25rem .5rem;
    border-radius: .5rem;
    white-space: nowrap;
    font-size: .625rem;
    border: 2px solid #FFFFFFAA;
    text-align: center;
}

/*生產中的工單，顯示「綠外框」+出現綠點點*/
.ganttChart .tbody .bar.started {
    box-shadow: 0 0 0 2px #7CC629;
}


/*bar顏色*/
.ganttChart .bar:hover {
    filter: brightness(0.85) contrast(1.5);
}
.ganttChart .bar.disabled {
    /* filter: saturate(80%) contrast(80%); */
    /* filter: brightness(0.85) contrast(0.8) saturate(80%) ; */
    /* filter: grayscale(20%) brightness(150%) contrast(0.9) ; */
    filter: grayscale(70%) brightness(150%) ;
}
.ganttChart .bar.disabled span{
    color:#666;
}

.ganttChart .bar.bg000000 {
    background: #000000;
    color: #fff;
}

.ganttChart .bar.bg1039AF {
    background: #1039AF;
    color: #fff;
}

.ganttChart .bar.bg6892FF {
    background: #6892FF;
    color: #000;
}

.ganttChart .bar.bg006699 {
    background: #006699;
    color: #fff;
}

.ganttChart .bar.bg4EAEDE {
    background: #4EAEDE;
    color: #000;
}

.ganttChart .bar.bg491A74 {
    background: #491A74;
    color: #fff;
}

.ganttChart .bar.bg8F41CF {
    background: #8F41CF;
    color: #fff;
}

.ganttChart .bar.bgCA9FF3 {
    background: #CA9FF3;
    color: #000;
}

.ganttChart .bar.bg28ABAE {
    background: #28ABAE;
    color: #fff;
}

.ganttChart .bar.bg8ECECF {
    background: #8ECECF;
    color: #000;
}

.ganttChart .bar.bg990066 {
    background: #990066;
    color: #fff;
}

.ganttChart .bar.bgCC3399 {
    background: #CC3399;
    color: #fff;
}

.ganttChart .bar.bgFF6600 {
    background: #FF6600;
    color: #fff;
}

.ganttChart .bar.bgFF9900 {
    background: #FF9900;
    color: #000;
}

.ganttChart .bar.bgAF5500 {
    background: #AF5500;
    color: #fff;
}

.ganttChart .bar.bgF76F8E {
    background: #F76F8E;
    color: #000;
}

.ganttChart .bar.bgF2BAC9 {
    background: #F2BAC9;
    color: #000;
}