.header{
    width: 100%;
    height: 50px;
    padding: .625rem 1.25rem .625rem .625rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
}

.logoHeaderImg{
    width: 77px;
    height: 20px;
}


/*screen color mode*/
.Light .header{
    /* background: #FFF;
    border-bottom: 1px solid #ddd; */
    /* background: #f2f5ffDD; */
    background: var(--bg-light-blur);
    backdrop-filter: blur(5px);
}
.Dark .header{
    /* background: #000;
    border-bottom: 1px solid #444; */
    /* background: #222222DD; */
    background: var(--bg-dark-blur);
    backdrop-filter: blur(5px);
}

.Light .logoHeaderImg{
    background:#000;
}
.Dark .logoHeaderImg{
    background: #FFF;

}