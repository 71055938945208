nav,
ol,
li {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none;
}


nav.sidebar {
    width: 3.5rem;
    height: 100vh;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 1000;
}

nav.sidebar ol,
nav.sidebar li {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
}

nav.sidebar ol {
    padding-left: 0;
}

nav.sidebar li {
    width: 3rem;
    margin: 0 .25rem;
    border-radius: .25rem;
    cursor: pointer;
    margin-top:.25rem;
}

nav.sidebar li:first-child {
    margin-top: .5rem;
}

nav.sidebar li:last-child {
    margin-bottom: .5rem;
}

nav.sidebar li .icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: .5rem;
    margin-bottom: .25rem;
}

nav.sidebar li .text {
    font-size: .625rem;
    margin-bottom: .5rem;
}


@media (max-width: 1024px) {
    nav.sidebar {
        position: fixed;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 4rem;
        border-right: none;
    }

    nav.sidebar>ol {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: left;
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0;
        padding: 0;
        width: 100vw;
    }

    nav.sidebar>ol::-webkit-scrollbar {
        height: 0;
    }

    nav.sidebar>ol::-webkit-scrollbar-button {
        background: transparent;
        border-radius: 50%;
    }

    nav.sidebar>ol::-webkit-scrollbar-track-piece,
    nav.sidebar>ol::-webkit-scrollbar-track {
        background: transparent;
    }

    nav.sidebar>ol::-webkit-scrollbar-thumb {
        border-radius: .125rem;
    }

    nav.sidebar li {
        flex: 1 1 0;
        min-width: 4.5rem;
        margin: .25rem 0;
    }

    nav.sidebar li:first-child {
        margin-top: .25rem;
        margin-left: .25rem;
    }

    nav.sidebar li:last-child {
        margin-bottom: 0;
        margin-right: .25rem;
    }

    nav.sidebar li .text {
        margin-bottom: .25rem;
    }
}

/*screen mode color*/
.Light nav.sidebar {
    /* background: #FFF;
    border-right: #dce5ff 1px solid; */    
    /* background: #f2f5ffDD; */
    background: var(--bg-light-blur);
    backdrop-filter: blur(5px);
}
.Dark nav.sidebar {
    /* background: #000;
    border-right: #444 1px solid; */
    /* background: #222222DD; */
    background: var(--bg-dark-blur);
    backdrop-filter: blur(5px);
}


@media (max-width: 1024px) {
    /* .Light nav.sidebar {
        border-top: #dce5ff 1px solid;
    }
    .Dark nav.sidebar {
        border-top: #444 1px solid;
    } */

    nav.sidebar>ol::-webkit-scrollbar-button {
        background: transparent;
    }

    nav.sidebar>ol::-webkit-scrollbar-track-piece,
    nav.sidebar>ol::-webkit-scrollbar-track {
        background: transparent;
    }

    nav.sidebar>ol::-webkit-scrollbar-thumb {
        background: rgba(159, 186, 255, 0.4);
    }
}


/*color*/
/*Default狀態*/
.Light .sidebar li {
    color: #666;
}
.Dark .sidebar li {
    color: #AAA;
}

.Light .sidebar li .icon {
    background: #666;
}
.Dark .sidebar li .icon {
    background: #AAA;
}


/*滑鼠懸停*/
.Light .sidebar li:hover {
    background: #F3F6FB;
}
.Dark .sidebar li:hover {
    background: #222;
}


/*滑鼠按下去的一瞬間*/
.Light .sidebar li:active {
    background: #3D71F866;
    color: #FFF;
}
.Dark .sidebar li:active {
    background: #3D71F866;
    color: #AAA;
}

.Light .sidebar li:active .icon {
    background: #fff;
}
.Dark .sidebar li:active .icon {
    background: #AAA;
}


/*正在被選擇的分頁*/
.Light .sidebar li.active {
    background: #3D71F8;
    color: #FFF;
}
.Dark .sidebar li.active {
    background: #9FBAFF;
    color: #000;
}

.Light .sidebar li.active .icon {
    background: #fff;
}
.Dark .sidebar li.active .icon {
    background: #000;
}