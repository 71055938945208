/*--Navbar--START ↓↓↓--*/

.nav>.list {
    position: fixed;
    top: 0;
    left: 0;
    width: 4rem;
    height: 100vh;
    z-index: 99;
    cursor: default;
    box-sizing: border-box;
    padding: 1rem 0;
    margin: 0 .25rem;
    overflow: visible auto;
    display: flex;
    flex-flow: nowrap column;
}

.nav .item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    min-height: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: .5rem .125rem;
    border-radius: .5rem;
}

.nav .item .icon {
    height: 1.875rem;
    width: 1.875rem;
    margin-bottom: .25rem;
    background-color: #888;
}

.Light .nav .item:hover .icon {
    background-color: #444;
}

.Dark .nav .item:hover .icon {
    background-color: #AAA;
}

.Light .nav .item:active .icon,
.Light .nav .item.active .icon {
    background-color: #000;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.Dark .nav .item:active .icon,
.Dark .nav .item.active .icon {
    background-color: #9FBAFF;
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, 0.2);
}

.nav .item .text {
    font-size: 0.625rem;
    line-height: 100%;
    text-align: center;
    color: #888;
}

.Light .nav .item:hover .text {
    color: #444;
}

.Dark .nav .item:hover .text {
    color: #AAA;
}

.nav .item:active .text,
.nav .item.active .text {
    font-weight: 500;
}

.Light .nav .item:active .text,
.Light .nav .item.active .text {
    color: #000;
}

.Dark .nav .item:active .text,
.Dark .nav .item.active .text {
    color: #9FBAFF;
}

@media(width<=800px) {
    .nav>.list {
        position: fixed;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: fit-content;
        flex-flow: nowrap row;
        padding: .25rem;
        margin: 0;
        border-top: #3D71F822 2px solid;
        backdrop-filter: blur(10px);
        /* 背景模糊效果 */
        -webkit-backdrop-filter: blur(10px);
        /* Safari 支援 */
    }

    .Light .nav>.list {
        background: #FFFFFFAA;
    }

    .Dark .nav>.list {
        background: #000000AA;
    }
}

/* .nav .hamburger {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: .5rem;
    right: .5rem;
    height: 1.5rem;
    width: 1.5rem;
    background: #D4E0FFCC;
    padding: .5rem;
    border-radius: .25rem;
    z-index: 100;
}

.nav .hamburger>.icon {
    position: relative;
    width: 24px;
    height: 2px;
    background-color: black;
    border-radius: 2px;
    transition: background-color .1s ease;
} */

/* .nav .hamburger>.icon::before,
.nav .hamburger>.icon::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: black;
    border-radius: 2px;
    transition: transform 0.3s ease, top .1s ease;
} */

/* Positioning for the first horizontal line */
/* .nav .hamburger>.icon::before {
    top: -8px;   
} */

/* Positioning for the second horizontal line */
/* .nav .hamburger>.icon::after {
    top: 8px;    
} */

/* Hide the middle line */
/* .nav .hamburger:active>.icon {
    background-color: transparent;    
} */

/* Move the line to the center */
/* .nav .hamburger:active>.icon::before {
    transform: rotate(45deg);
    top: 0;    
} */

/* Move the line to the center */
/* .nav .hamburger:active>.icon::after {
    transform: rotate(-45deg);
    top: 0;    
} */

/*舊版：搭配漢堡的List*/
/* @media(width<=800px) {    
    .nav>.list {
        position: fixed;
        left: 0;
        bottom: 0;
        background: #03206bCC;
        width: 100vw;
        height:fit-content;
        flex-flow: nowrap row;        
        padding: .25rem;
        margin: 0;
    }
    .nav .hamburger {
        display: flex;
    }

    .nav>.list {
        visibility: hidden;
        position: fixed;
        left: auto;
        right: 0;
        top: 0;
        padding-top: 4rem;
        background: #03206bCC;
        width: 5rem;
    }   

    .nav .hamburger:hover+.list {
        visibility: visible;
    }

    .nav .item .icon {
        background-color: #FFF;
    }

    .nav .item:hover .icon {
        background-color: #EBEEFB;
    }

    .nav .item:active .icon {
        background-color: #D4E0FF;
    }

    .nav .item .text {
        color: #FFF;
    }

    .nav .item:hover .text {
        color: #EBEEFB;
    }

    .nav .item:active .text {
        color: #D4E0FF;
    }
} */


/*--Navbar--END ↑↑↑--*/

/*--Page--START ↓↓↓--*/
.aps>main {
    overflow-x: hidden;
    overflow-y: hidden;
}

.aps>main {
    box-sizing: border-box;
    margin-left: 5rem;
    padding: 1.25rem 1.25rem 1.25rem 0;
    width: calc(100% - 5rem);
}

@media(width<=800px) {
    .aps>main {
        margin-left: 0;
        padding: 1.25rem;
        width: 100%;
    }
}

.page {
    box-sizing: border-box;
    height: fit-content;
}

.page .page-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    min-height: 50vh;
    padding: 1.25rem;
    border-radius: 0 .5rem .5rem .5rem;
}

.page .page-content.round {
    border-radius: .5rem;
}

.Light .page .page-content {
    background: #FFF;
}

.Dark .page .page-content {
    background: #000;
    border: 1px solid #444;
}

/*--Page--END ↑↑↑--*/

/*--Elements | tab--START ↓↓↓--*/
.APS-tab {
    display: flex;
    flex-wrap: nowrap;
    /* overflow-y: hidden; */
    /* overflow-y: auto; */
    justify-content: left;
    padding: 0;
    width: 100%;
    max-width: 100%;
}

.APS-tab.small-tab {
    margin-top: .25rem;
}

.APS-tab>div {
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 700;
    padding: .625rem 1.5rem .5rem 1.5rem;
    border-radius: 1rem 1rem 0 0;
    margin: 0 .125rem;
    cursor: pointer;
}

.APS-tab.small-tab>div {
    font-size: .875rem;
    font-weight: 600;
    padding: .5rem 1.75rem;
    border-radius: .625rem .625rem 0 0;
    margin: 0 .05rem;
}

.APS-tab>div:nth-child(1) {
    margin-left: 0;
}

/*color mode-Light*/
.Light .APS-tab .tab-clicked {
    background-color: #FFF;
    color: #000;
}

.Light .APS-tab .tab-unclick {
    background-color: #D4E0FF66;
    color: #888;
}

.Light .APS-tab .tab-unclick:hover {
    background-color: #D4E0FFAA;
}

.Light .APS-tab.small-tab .tab-clicked {
    background-color: #000;
    color: #FFF;
}

.Light .APS-tab.small-tab .tab-unclick {
    background-color: #EBEEFB;
    color: #3D71F8;
}

.Light .APS-tab.small-tab .tab-unclick:hover {
    background-color: #d4e0ff;
}

/*color mode-Dark*/
.Dark .APS-tab .tab-clicked {
    background-color: #000;
    color: #FFF;
    border: 1px solid #444;
    border-bottom: none;
    position: relative;
}

.Dark .APS-tab .tab-clicked::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - 2px);
    height: 2px;
}

.Dark .APS-tab .tab-clicked::after {
    position: absolute;
    top: calc(100% - .125rem);
    left: .125rem;
    width: calc(100% - .25rem);
    height: .125rem;
    background: #9FBAFF;
    content: "";
    border-radius: .25rem;
}

.Dark .APS-tab .tab-unclick {
    background-color: #D4E0FF22;
    color: #888;
}

.Dark .APS-tab .tab-unclick:hover {
    background-color: #D4E0FF44;
}

.Dark .APS-tab.small-tab .tab-clicked {
    background-color: #000;
    color: #FFF;
}

.Dark .APS-tab.small-tab .tab-unclick {
    background-color: #EBEEFB;
    color: #3D71F8;
}

.Dark .APS-tab.small-tab .tab-unclick:hover {
    background-color: #d4e0ff;
}


/*準交率、延遲天數*/
.floatitem {
    position: relative;
}

.floatitem>.item {
    position: absolute;
    left: 1.25rem;
    bottom: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
}

.floatitem>.item .bubble {
    position: relative;
    padding: .25rem .5rem;
    border-radius: .25rem;
    margin-bottom: .25rem;
    color: #FFF;
    opacity: 40%;
}

.floatitem.tab-clicked>.item .bubble,
.floatitem:hover>.item .bubble {
    opacity: 100%;
}

.floatitem>.item .bubble::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    border: .25rem solid transparent;
    border-top-color: #AAA;
    border-bottom: 0;
}

.floatitem>.item .bubble.bg-Great::after {
    border-top-color: #7CC629;
}

.floatitem>.item .bubble.bg-Good::after {
    border-top-color: #37B9CC;
}

.floatitem>.item .bubble.bg-Fair::after {
    border-top-color: #FFAA00;
}

.floatitem>.item .bubble.bg-Poor::after {
    border-top-color: #F66F6F;
}

.floatitem>.item .text {
    padding-top: .25rem;
    font-size: .75rem;
    font-weight: 400;
    color: #888;
    opacity: 50%;
}

.floatitem.tab-clicked>.item .text,
.floatitem:hover>.item .text {
    opacity: 100%;
}




/*--Elements | tab--END ↑↑↑--*/

/*--Elements | Filter Row--START ↓↓↓--*/
.filterRow-aps {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-content: stretch;
    justify-content: space-between;
}

.filterRow-aps>div {
    display: flex;
    flex-wrap: wrap;
    height: 2.5rem;
    min-height: 2.5rem;
    max-height: 10rem;
    align-content: stretch;
    margin: 0 0.5rem 0.5rem 0;
}

.filterRow-aps>div:last-child {
    margin-right: 0;
}

.filterRow-aps>div>div,
.filterRow-aps>div>form,
.filterRow-aps>div>form>input,
.filterRow-aps>div>button {
    height: 100%;
    align-content: stretch;
}

.filterRow-aps>div:nth-child(2)>div,
.filterRow-aps>div:nth-child(2)>form,
.filterRow-aps>div:nth-child(2)>button {
    margin-left: .5rem;
}

.filterRow-aps>div:first-child>div,
.filterRow-aps>div:first-child>form,
.filterRow-aps>div:first-child>button {
    margin-right: .5rem;
}

/*--Elements | Filter Row--END ↑↑↑--*/


/*--Elements | btn--START ↓↓↓--*/

.btn-item {
    border-radius: .25rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-item svg,
.btn-item .icon {
    width: 1.5rem;
    height: 1.5rem;
}

.btn-sub {
    font-size: 0.75rem;
}

.btn-sub .icon {
    width: 1rem;
    height: 1rem;
}

.btn-inputdrop {
    pointer-events: none;
}

.btn-inputdrop .icon {
    background: #AAA;
}

.btn-inputdrop:disabled .icon {
    background: #BBB;
}

.btn-txt-blue {
    background-color: #00000000;
    border: 0;
    color: #3D71F8;
}

.btn-txt-blue:hover {
    background-color: #EBEEFBAA;
}

.btn-txt-blue:active {
    background-color: #EBEEFB;
}

input.error,
textarea.error,
select.error {
    border-color: #F66F6F !important;
}

input.error:focus,
textarea.error:focus,
select.error:focus {
    outline: #F66F6F 1px solid;
}

/*增修紀錄：10/25 這個加上textarea.error~span.errortext*/
/* input.error~span.errortext, textarea.error~span.errortext {
    margin-top:.25rem;
    font-size: .75rem;
    line-height: 110%;
    color:#F66F6F;
    word-break: keep-all;
} */

/* .errortext {
    margin-top: .25rem;
    font-size: .75rem;
    line-height: 110%;
    color: #F66F6F;
    word-break: keep-all;
} */

/*--Elements | input--END ↑↑↑--*/


/*--Style | status START ↓↓↓↓--*/
/*10/26增加此項目*/
.status.status-padding {
    font-size: 0.625rem;
    padding: .1rem .25rem;
    border-radius: .25rem;
    width: fit-content;
    margin-left: .25rem;
    /* height: 1.5rem; */
}

.status.unscheduled {
    color: #AAA;
}

.status.bg.unscheduled {
    background: #F5F5F5;
    color: #AAA;
}

.status.scheduled {
    color: #444;
}

.status.bg.scheduled {
    color: #FFF;
    background: #444;
}

.status.inproduction {
    color: #5CA20D;
}

.status.bg.inproduction {
    color: #5CA20D;
    background: #DCF2C4;
}

.status.paused {
    color: #FFAA00;
}

.status.bg.paused {
    color: #FFAA00;
    background: #FFF3B6;
}

.status.bg.paused .icon {
    background: #FFAA00;
}

.status.aborted {
    color: #AAA;
}

.status.bg.aborted {
    color: #FFF;
    background: #CCC;
}

.status.completed {
    color: #000;
}

.status.bg.completed {
    color: #000;
    background: #EBEEFB;
}

table tr:hover .status.bg.completed {
    color: #000;
    background: #D4E0FF;
}

.status.bg.completed .icon {
    background: #000;
}

.status.bg.deliveryDelay {
    color: #FFFFFF;
    background: #F66F6F;
}

.status.bg.deliveryDelay .icon {
    background: #FFF;
}

.icon-Radius {
    box-sizing: border-box;
    border-radius: 50%;
    padding: .25rem;
    height: 1.25rem;
    width: 1.25rem;
    opacity: .8;
}

*>.icon-Radius:not(:first-child) {
    margin-left: -.5rem;
}

.icon-xs {
    box-sizing: border-box;
    width: .75rem;
    height: .75rem;
}

/*--Style | status END ↑↑↑↑--*/

/*找不到資料*/
.table-nodata {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    place-content: center;
    height: 100%;
    min-height: calc(50vh - 5rem);
}

.table-nodata .nodata-text {
    background: #EBEEFB;
    font-size: .75rem;
    color: #3D71F8;
    width: fit-content;
    line-height: 1;
    padding: .25rem .5rem;
    border-radius: 1rem;
}

.btn-lv2 {
    background-color: #3D71F8 !important;
    border: 0 !important;
    color: #FFF !important;
}

.btn-lv2 .icon {
    background: #FFF;
}

.btn-lv2:hover {
    background-color: #275ce2 !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.btn-lv2:active {
    background-color: #3D71F8 !important;
}

.btn-lv2:disabled {
    background-color: #CCC;
    color: #888;
}

.btn-lv2.disabled {
    background-color: #3D71F888;
    color: #FFF;
}

.btn-lv2.disabled:hover {
    background-color: #3D71F866;
}