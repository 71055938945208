/* .workpieceContent{
    position: fixed; 
    top: 3.1rem; 
    width: calc(100% - 3.5rem);
    left: 3.5rem;
} */

/*左上角切換選單*/
.btn-groupChoice{
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    height: 2.5rem;
}

.btn-groupChoice>.btn-blue:not(:last-child), .btn-groupChoice>.btn-blue-selected:not(:last-child){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-left: 1px solid #D4E0FF;
    border-top: 1px solid #D4E0FF;
    border-bottom: 1px solid #D4E0FF;
    border-right: none;
}

.btn-groupChoice>.btn-blue:not(:first-child), .btn-groupChoice>.btn-blue-selected:not(:first-child){
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-right: 1px solid #D4E0FF;
    border-top: 1px solid #D4E0FF;
    border-bottom: 1px solid #D4E0FF;
    border-left: none;
}

.btn-blue-selected{
    background-color: #3D71F8;
    color: #fff;
    padding: 0.5rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
}

.btn-blue{
    background-color: #EBEEFB;
    color: #9FBAFF;
    padding: 0.5rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
}

.search-Input{
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 0;
    height: 2.5rem;
    width: 7.313rem;
}

.btn-SearchIcon{
    font-size: 1.25rem;
}

.calWayDiv{
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    font-size: 0.75rem;
    padding: 0.25rem;
    width: auto;
    height: auto;
}

.btn-blue-selected-calWay{
    background-color: #3D71F8;
    color: #fff;
    padding: 0.5rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    border: none;
}

.btn-blue-calWay{
    background-color: #fff;
    color: #3D71F8;
    padding: 0.5rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    border: none;
}

/*麵包屑*/
.breadcrumb-div{
    position:fixed;
    z-index: 5;
    top:7rem;
    left:3.5rem;
    background-color: #3259CACC;
    width: fit-content;
    height: fit-content;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.375rem 0.375rem 1.25rem;
    font-size: .875rem;
    border-radius: 0 1.5rem 1.5rem 0;
}

@media (width<=1024px) {
    .breadcrumb-div{
        left:0rem;
    }   
}

.breadcrumb-div>*:not(:last-child){
    margin-right:.375rem;
}

.breadcrumb-div .text{
    width:fit-content;
    max-width: 12rem;
    white-space: nowrap;
    /* 強制單行顯示 */
    overflow: hidden;
    /* 隱藏超出範圍的內容 */
    text-overflow: ellipsis;
    /* 超出部分顯示「...」 */
}

@media (width<=800px) {
    .breadcrumb-div .text{
        max-width: 8rem;
    } 
}

@media (width<=600px) {
    .breadcrumb-div .text{
        max-width: 5rem;
    } 
    .breadcrumb-div .item.short{
        max-width: 10rem;
        white-space: nowrap;
        /* 強制單行顯示 */
        overflow: hidden;
        /* 隱藏超出範圍的內容 */
        text-overflow: ellipsis;
        /* 超出部分顯示「...」 */
    } 
}

@media (width<=400px) {
    .breadcrumb-div .item.short{
        max-width: 10rem;
        white-space: nowrap;
        /* 強制單行顯示 */
        overflow: hidden;
        /* 隱藏超出範圍的內容 */
        text-overflow: ellipsis;
        /* 超出部分顯示「...」 */
    } 
}

.breadcrumb-div .clickable:hover {
    text-decoration: underline dotted #FFFFFFAA;
}

.breadcrumb-div .item{
    background-color: #fff;
    color: #3259CA;
    border-radius: 1.5rem;
    padding: 0.15rem 0.5rem;
}

.breadcrumb-div .icon{
    width:1rem;
    height:1rem;
    background: #FFFFFF88;
}


/*麵包屑蓋住的filterRow留空用於換行*/
#pageWorkpiecesDetail.filling {
    width:33rem;
    height:2.375rem;
  }


@media (width<=800px) {
    #pageWorkpiecesDetail.filling {
        width:29rem;
      }
}

@media (width<=600px) {
    #pageWorkpiecesDetail.filling {
        width:26rem;
      }
}
@media (width<=430px) {
    #pageWorkpiecesDetail.filling {
        width:21rem;
        margin-top:.5rem;
      }
}

/*color*/
.box-color-green {
    background-color: #DCF2C4 !important;
}

.box-color-green .material-symbols-outlined,
.box-color-green .material-symbols-rounded,
.tablecell-tag .box-color-green {
    color: #7CC629;
}

.box-color-yellow {
    background-color: #FFF3B6;
}

.box-color-yellow .material-symbols-outlined,
.box-color-yellow .material-symbols-rounded,
.tablecell-tag .box-color-yellow {
    color: #FFAA00;
}

.box-color-lakeblue {
    background-color: #D3EDF3;
}

.box-color-lakeblue .material-symbols-outlined,
.box-color-lakeblue .material-symbols-rounded,
.tablecell-tag .box-color-lakeblue {
    color: #37B9CC;
}

.box-color-purpleblue {
    background-color: #F3F6FB;
}

.box-color-purpleblue .material-symbols-outlined,
.box-color-purpleblue .material-symbols-rounded,
.tablecell-tag .box-color-purpleblue {
    color: #9FBAFF;
}


.box-color-blue {
    background-color: #D4E0FF;
}

.box-color-blue .material-symbols-outlined,
.box-color-blue .material-symbols-rounded,
.tablecell-tag .box-color-blue {
    color: #3259CA;
}

.box-color-purple {
    background-color: #E0D9FF;
}

.box-color-purple .material-symbols-outlined,
.box-color-purple .material-symbols-rounded,
.tablecell-tag .box-color-purple {
    color: #8265FF;
}


.box-color-orange {
    background-color: #FFE5CF;
}

.box-color-orange .material-symbols-outlined,
.box-color-orange .material-symbols-rounded,
.tablecell-tag .box-color-orange {
    color: #FF8800;
}

.box-color-red {
    background-color: #FFD4D4;
}

.box-color-red .material-symbols-outlined,
.box-color-red .material-symbols-rounded {
    color: #F66F6F;
}

.box-color-grey {
    background-color: #CCCCCC;
}

.box-color-grey .material-symbols-outlined,
.box-color-grey .material-symbols-rounded {
    color: #444444;
}

.powerCarbonContent{
    overflow-y: scroll;
    height: calc(100vh - 8.5rem);
}

@media (max-width: 1400px) {
    .powerCarbonContent{
        overflow-y: scroll;
        height: calc(100vh - 9rem);
    }
}

@media (max-width: 1200px) {
    .powerCarbonContent{
        overflow-y: scroll;
        height: calc(100vh - 12rem);
    }
}

@media (max-width: 768px) {
    .powerCarbonContent{
        overflow-y: scroll;
        height: calc(100vh - 15rem);
    }
}

@media (max-width: 576px) {
    .powerCarbonContent{
        overflow-y: scroll;
        height: calc(100vh - 18rem);
    }

    .groupSearch{
        justify-content: center;
    }
}