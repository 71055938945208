.imgBlock {
  box-sizing: border-box;
  width:72px;
  height:56px;
  border-radius: .25rem;
  border: none;
  overflow: hidden; /*確保裡面的圖片<img>裁切生效*/
}

.Light .imgBlock {
  background: #F8F9FC;
  border: 1px solid #EBEEFB;
}
.Dark .imgBlock {
  background: #F8F9FC11;
  border: 1px solid #EBEEFB22;
}

.imgBlock.update {
  box-sizing: border-box;
  width:145px;
  height:105px;
  border-radius: .25rem;
  border: 1px solid #3D71F8;
  display: grid;
  place-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden; /*確保裡面的圖片<img>裁切生效*/
}

.imgBlock.update:hover {
  background: #EBEEFB;
}

.imgBlock.update>.text {
  font-size: 80%;
  color:#3D71F8;
  width:fit-content;
  line-height: 1;
}

.imgBlock.update>.icon{
  position: absolute;
  bottom: .25rem;
  right: .25rem;
  display: grid;
  place-content: center;
  height: 2rem;
  width: 2rem;
  border: 1px solid #3D71F844;
  border-radius: .25rem;
}
.imgBlock.update:hover>.icon {
  background: #FFF;
  border: 1px solid #3D71F888;
}

.imgBlock.update .icon>.icon{
  height: 1.75rem;
  width: 1.75rem;
  background: #3D71F8;
}

.imgBlock>img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 裁切圖片以填滿容器，保留比例 */
}

.remarkRequired{
  color: #AAA;
  font-size: .75rem;
  font-weight: 400;
  margin-right: .25rem;
}

.form-label-custom{
  color: #444 !important;
  font-size: 0.875rem;
}

.timeDurationDiv {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.timeDurationDiv.error {
  border: 1px solid #F66F6F;
  display: flex;
  align-items: center;
}

.timeDuration {
  display: block;
  text-align: center;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.timeDurationTitle {
  height: 2rem;
}

.inputIcon {
  position: absolute;
  right: 0.5rem;
  display: flex;
  align-items: center;
}

.moreTxt {
  height: 1.313rem;
  border-radius: 3px;
  padding: 3px;
  margin-left: .25rem;
  font-size: .625rem;
}

.Light .moreTxt {
  background-color: #EBEEFB;
  color: #3D71F8;
}
.Dark .moreTxt {
  background-color: #EBEEFB44;
  color: #CCC;
}