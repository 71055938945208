section.statistic {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    cursor: default;
    align-items: stretch;
    margin-bottom: 2rem;
}

section.statistic>div {
    padding:1.25rem;
}

section.statistic>div:not(:first-child) {
    margin-left: 1.25rem;
}

section.statistic .w2{
    flex:2;
}

section.statistic .w10{
    flex:10;
}

section.statistic h2{
    font-size: .75rem;
    line-height: 100%;
    color:#444;
    font-weight: 400;
    margin:0;
    margin-bottom:1rem;
}

section.statistic ul {    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding:0;
    margin-bottom: 0;
}

section.statistic li {    
    flex: 1 1 0;
    list-style-type: none;
}

section.statistic .score {    
    font-size: 1.75rem;
    line-height: 1;
    font-weight: 600;    
    color:#444;
}

section.statistic li:hover .score {    
    color:#000;  
}

section.statistic .sub-title {
    display: inline-flex;    
    font-size: .625rem;
    line-height: 1;
    align-items: center;
}

section.statistic .sub-title>.icon {    
    width:.875rem;
    height:.875rem;
    border-radius: 50%;
    display: grid;   
    place-content: center;
    margin-left:.25rem;
}

section.statistic .sub-title>.icon>div {    
    width:.75rem;
    height:.75rem;
}