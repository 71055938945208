.helpItem{
    width: 3%;
}

.helpContent{
    width: 97%;
}

.iconEmail{
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconEmail:hover{
    background-color: #EBEEFB;
}