.tabHeader{
    position: sticky;
    top: 50px;
    z-index: 50;
    white-space: nowrap;
    display:flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    padding: 0 1.25rem;
    /* cursor: grab; */
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.tabItem {
    height: 2.5rem;
    /* line-height: 2.5rem;
    margin-right: 1rem; */
    display: inline-block;
    vertical-align: middle !important;
    cursor: pointer;
    font-size: .875rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    border-radius: .5rem;
    padding: .5rem 1rem;
}
.tabItem {
    color: #888;
    /* border-top: 3px solid transparent; */
}

.tabItem.active {
    cursor: default;
}

.tabItem:active {
    /* border-top: 3px solid #3D71F888; */
    background:#3D71F888;
}

.tabItem .icon{
    width:1.5rem;
    height:1.5rem;
    margin-right: .125rem;
}

/*screen mode color*/
.Light .tabHeader{
    /* background: #FFF;
    border-bottom: 1px solid #DDD; */ 
    /* background: #f2f5ffDD; */
    background: var(--bg-light-blur);
    backdrop-filter: blur(5px);
    box-shadow:0 0 5px 3px var(--bg-light-blur);
}
.Dark .tabHeader{
    /* background: #000;
    border-bottom: 1px solid #444; */
    /* background: #222222DD; */
    background: var(--bg-dark-blur);
    backdrop-filter: blur(5px);
    box-shadow:0 0 5px 3px var(--bg-dark-blur);
}

.Light .tabItem:hover {
    /* border-top: 3px solid #3D71F844;
    color: #3D71F8; */
    background: #3D71F844;
    color: #444;
}
.Dark .tabItem:hover {
    /* border-top: 3px solid #9FBAFF88; */
    background:#9FBAFF88;
    color: #000;
}

.Light .tabItem.active {
    /* color: #3D71F8;
    border-top: 3px solid #3D71F8; */
    color:#FFF;
    background:#3D71F8;
}
.Dark .tabItem.active {
    /* color: #9FBAFF;
    border-top: 3px solid #3D71F8; */
    color:#000;
    background:#9FBAFF;
}

.Light .tabItem .icon{
    background: #666;
}
.Dark .tabItem .icon{
    background: #888;
}

.Light .tabItem:hover .icon{
    /* background: #3D71F888; */
    background: #444;
}
.Dark .tabItem:hover .icon{
    /* background: #9FBAFFCC; */
    background: #000;
}

.Light .tabItem.active .icon{
    /* background: #3D71F8; */
    background: #FFF;
}
.Dark .tabItem.active .icon{
    /* background: #9FBAFF; */
    background: #000;
}