.analyticsHeader {
	min-height: 3.125rem;
	padding: .625rem;
	padding-bottom: 0;
	margin: 0 1.25rem .5rem 1.25rem ;
	border-radius: .5rem;
}

.block-title-remark {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.block-title-remark .remark {
	padding: 0.125rem 1rem 0.125rem 0.0625rem;
	font-size: 0.75rem;
	border-radius: 1rem;
	width: fit-content;
	margin-left: .25rem;
}

.block-title-remark .remark span {
	font-size: 0.75rem;
	border-radius: 1rem;
	margin-right: 0.25rem;
	padding: 0.125rem 0.5rem;
}

@media (width <=370px) {
	.block-title-remark {
		flex-direction: column;
	}

	.block-title-remark .remark {
		margin: 0.25rem 1rem 0 1rem;
	}
}

/*screen mode color*/
/* .Light .analyticsHeader{
    background: #ffffffcc;
    border-bottom: 1px solid #ccc;
}
.Dark .analyticsHeader{
    background: #000;
    border-bottom: 1px solid #444;
} */

.Light .block-title-remark .remark {
	color: #3D71F8;
	background: #FFF;
	border: 1px solid #D4E0FF;
}
.Dark .block-title-remark .remark {
	/* color: #CCC;
	background: #444;
	border: 1px solid #888; */
	color: #CCC;
	background: #111;
	border: 1px solid #444;
}

.Light .block-title-remark .remark span {
	color: #3D71F8;
	background: #F8F9FC;
}
.Dark .block-title-remark .remark span {
	color: #000;
	background: #9FBAFF;
}