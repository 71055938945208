
/*第一排的結構*/
.boxgroup-outer {
    margin-top: 1rem;
    gap: 1rem;
    width:100%;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: .5rem;
    gap: 1rem;
}

.spacebetween {
    justify-content: space-between;
}

h2.block-title {
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0;
}
.Light h2.block-title {
    color:#000;
}
.Dark h2.block-title {
    color:#AAA;
}

h2.block-title:not(:first-child) {
    padding-top: 1rem;
    margin-top: 1.25rem;
}

.Light h2.block-title:not(:first-child) {
    border-top: #9FBAFF 0.0625rem solid;
}
.Dark h2.block-title:not(:first-child) {
    border-top: #9FBAFF88 0.0625rem solid;
}

/*第一排的最外層白框*/
.outer-white>div {
    display: flex;
    /* background-color: #fff;
    border: 1px solid #dce5ff;
    box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05); */
    border-radius: 0.625rem;
    align-self: stretch;
}

.grid {
    width: 100%;
    display: grid;
    gap: 0.875rem;
}

/*第一排的最外層白框內的小框設定成grid均分*/
.outer-white>div>.grid {
    width: 100%;
    display: grid;
    gap: 0.875rem;
    margin: 0.875rem;
}

.outer-white-chartbox>div {
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    align-self: stretch;
    align-items: stretch;
}

.outer-white-chartbox>div>div:nth-of-type(1) {
    color: #888;
    display: flex;
    align-items: flex-end;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    margin-bottom: 0.25rem;
    height: 2rem;
}

.outer-white-chartbox>div>div:nth-of-type(2),
.outer-white-chartbox>div>.box {
    /* box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05); */
    border-radius: 0.625rem;
    height:100%;
    min-height: 22.75rem;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}
/* .Light .outer-white-chartbox>div>div:nth-of-type(2),
.Light .outer-white-chartbox>div>.box {
    background: #fff;
    border: 1px solid #dce5ff;
}
.Dark .outer-white-chartbox>div>div:nth-of-type(2),
.Dark .outer-white-chartbox>div>.box {
    background: #000;
    border: 1px solid #dce5ff44;
} */
/* 
.Light .outer-white-chartbox h3,
.Light .outer-white-chartbox .title {
    color: #000;
}
.Dark .outer-white-chartbox h3,
.Dark .outer-white-chartbox .title {
    color: #DDD;
} */


/*第一排的最外層白框內的小框-2欄均分*/
.grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
}

/*第一排的最外層白框內的小框-3欄均分 (RWD跳成1欄)*/
.grid-col-3to1 {
    grid-template-columns: repeat(3, 1fr);
}

/*第一排的最外層白框內的小框-4欄均分 (RWD跳成2欄)*/
.grid-col-4to2 {
    grid-template-columns: repeat(4, 1fr);
}

/*第一排的最外層白框內的小框-6欄均分 (RWD跳成3欄)*/
/* 2024-12-02 啾啾修「分析」的樣式，把已經移除的樣式註解掉
.grid-col-6to3 {
    grid-template-columns: repeat(6, 1fr);
} */

.grid-col-2and1to1 {
    grid-template-columns: 2fr 1fr;
}

@media (width <=1200px) {
    .grid-col-2and1to1 {
        grid-template-columns: 1fr;
    }

    /* 2024-12-02 啾啾修「分析」的樣式，把已經移除的樣式註解掉
    .grid-col-6to3 {
        grid-template-columns: repeat(3, 1fr);
    } */
}

@media (width <=768px) {
    .grid-col-3to1 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid-col-4to2 {
        grid-template-columns: repeat(2, 1fr);
    }

    /* 2024-12-02 啾啾修「分析」的樣式，把已經移除的樣式註解掉
    .grid-col-6to3 {
        grid-template-columns: repeat(2, 1fr);
    } */
}



/*小框-基本白底樣式、欄位高度上下拉齊*/
.boxstyle-white {
    /* background-color: #fff; */
    border: 1px solid #9fbaff;
    border-radius: 0.625rem;
    align-self: stretch;
}

/*小框-淺灰藍底樣式、欄位內容上下拉齊*/
.boxstyle-lightblue {
    border-radius: 0.625rem;
    align-self: stretch;
}
.Light .boxstyle-lightblue {
    background-color: #F8F9FC;
    border: 1px solid #9fbaff;
    color:#000;
}
.Dark .boxstyle-lightblue {
    background-color: #F8F9FC22;
    border: 1px solid #9fbaffAA;
    color:#CCC;
}

/*小框-內容物上下拉齊*/
/*2024-11-29 啾啾開始修「分析」的樣式，把不需要的先註解掉
 .littlebox>div {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100% - 1.75rem);
    margin: 0.875rem;
    gap: 0.5rem;
}

.littlebox>div>div {
    width: 100%;
    font-size: 0.875rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    font-weight:600;
} */

/* 2024-11-29 啾啾開始修「分析」的樣式，把不需要的先註解掉
.btn-littlebox {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #3D71F8;
    background-color: #F8F9FC;
    border: inherit;
    border: #9FBAFF 0.0625rem solid;
    border-radius: 0.25rem;
    padding: 0.2rem 0.5rem 0.2rem 0.35rem;
} */

/* .btn-littlebox>div:nth-of-type(1) {
    font-size: 1rem;
}

.btn-littlebox>div:nth-of-type(2) {
    margin-left: 0.125rem;
    font-size: 0.75rem;
} */

/*文字樣式：分數*/
.score-XL {
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 0.5rem;
}

/*文字式：評等*/
.criteria-L {
    font-size: 1rem;
    font-weight: 700;
}

/*文字樣式：藍框分數*/
/*2024-12-02 啾啾修「分析」的樣式，把已經移除的樣式註解掉
 .score-L {
    font-size: 1.125rem;
    font-weight: 700;
} */

/*分數下的百分比bar*/
/* .bar {
    width: 100%;
    background-color: #CCC;
    height: 0.625rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
} */

.chartbox>div>div:nth-of-type(2)>div {
    width: calc(100% - 2.5rem);
    height: 100%;
    margin: 0.875rem 1.25rem 1.25rem 1.25rem;
}

.chartbox>div>div .stretch {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-content: space-between;
}

.chartbox>div>div .stretch>div {
    width: 100%;
}

.chartbox .flex {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 0;
    width: 100%;
}

.chartbox-scoreandtable>div:nth-of-type(2)>div>div:nth-of-type(2) {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}

@media (width <=768px) {
    .chartbox-scoreandtable {
        height: 35rem;
    }
}

.box-upper-score-gp {
    width: 100%;
    display: grid;
    gap: 0.875rem;
    align-self: stretch;
    margin-bottom: 1rem;
}

.box-upper-score-gp>div {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.box-upper-score-gp>div>div {
    width: 100%;
}

.box-upper-score-gp>div>.subtitle {
    color: #AAA;
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
    font-weight: 600;
}

.box-upper-score-gp>div>.score-XL {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 0.5rem;
}
.Light .box-upper-score-gp>div>.score-XL {
    color:#000;
}
.Dark .box-upper-score-gp>div>.score-XL {
    color:#FFF;
}

.chart-width-wide {
    width: calc(50% - 0.875rem);
}

.chart-width-narrow {
    width: calc(50% - 0.875rem);
}

.chart-width-evenly-wide,
.chart-width-evenly-narrow {
    width: calc(50% - 0.875rem);
}

@media (width <=1300px) {
    .chart-width-wide {
        width: calc(66% - 0.875rem);
    }

    .chart-width-narrow {
        width: calc(33% - 0.875rem);
    }

}

@media (width <=900px) {
    .chart-width-evenly-wide {
        width: calc(66% - 0.875rem);
    }

    .chart-width-evenly-narrow {
        width: calc(33% - 0.875rem);
    }
}


@media (width <=768px) {
    .chart-width-wide {
        width: 100%;
    }

    .chart-width-narrow {
        width: 100%;
    }

    .chart-width-evenly-wide,
    .chart-width-evenly-narrow {
        width: 100%;
    }
}

.width-66 {
    width: calc(66% - 0.875rem);
}

.width-50 {
    width: calc(50% - 0.875rem);
}

.width-33 {
    width: calc(33% - 0.875rem);
}

.width-33of2 {
    /*適用於總寬度50%範圍內，只有1格的區塊 (6分之1的寬度，在換行時會轉成50%)*/
    width: calc(33% - 0.875rem);
}

.width-25 {
    width: calc(25% - 0.875rem);
}

.width-16of2 {
    /*適用於總寬度50%範圍內，只有2格的區塊 (6分之1的寬度，在換行時會轉成50%)*/
    width: calc(16.6% - 0.875rem);
}

.width-16of3 {
    /*適用於總寬度50%範圍內，有3格的區塊 (6分之1的寬度，在換行時會轉成33%)*/
    width: calc(16.6% - 0.875rem);
}


.width-66>div,
.width-50>div,
.width-33>div,
.width-33of2>div,
.width-25>div,
.width-16of2>div,
.width-16of3>div {
    width: 100%;
}

@media (width <=1300px) {

    .width-66,
    .width-50,
    .width-33of2 {
        width: 100%;
    }

    .width-25,
    .width-33,
    .width-16of2 {
        width: calc(50% - 0.875rem);
    }


    .width-16of3 {
        width: calc(33% - 0.875rem);
    }
}

@media (width <=768px) {

    .width-66,
    .width-33,
    .width-25,
    .width-16of2,
    .width-16of3 {
        width: 100%;
    }
}

.chart-height-tall{
    height: 18.5rem;  
}

.chart-height-short{
    height: 16.5rem;  
}

.chart-height-talltoshort{
    height: 18.5rem;  
}
@media (width <=1539px) {
    .chart-height-talltoshort{
        height: 16.5rem;  
    }
}
@media (width <=1300px) {
    .chart-height-talltoshort{
        height: 18.5rem;  
    }
}

/*-----------------------------------------------
圖表區塊-沒有資料的替代樣式*/
/*此區塊的上層若是div，則須改成使用div以外的區塊元素 (例：section)套用此樣式，不然在display:none時會影響到畫面其他元素的排版*/
.chartbox-inner-nodata >div,
.chartbox-scoreandtable .chartbox-inner-nodata >div {
    width:100%;
    height:16.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media (width <=768px) {
    .chartbox-scoreandtable .chartbox-inner-nodata >div {
        height: 26.5rem;
    }
}

.chartbox-inner-nodata > div >div{
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 0.625rem;
    text-align: center;
    border-radius: 1rem;
}

.Light .chartbox-inner-nodata > div >div{
    color: #b7cbff;
    background-color: #F8F9FC;
}
.Dark .chartbox-inner-nodata > div >div{
    color: #000;
    background-color: #F8F9FC33;
}

/*圖表區塊-沒有資料的替代樣式 END
-----------------------------------------------*/

/*-----------------------------------------------
圖表區塊-th有圓點的表格*/
.chart-table>table {
    line-height: 100%;
    border-collapse: collapse;
    width: 100%;
    cursor: default;
}

.chart-table>table>thead {
    color: #AAA;
    font-size: .625rem;
    line-height: .625rem;
}
.Light .chart-table>table>thead {
    border-bottom: #CCC .03125rem solid;
}
.Dark .chart-table>table>thead {
    border-bottom: #666 .03125rem solid;
}

.chart-table>table>tbody {
    font-size: 1rem;
}

@media (width<=680px) {
   .chart-table>table>thead {
       font-size: .75rem;
       line-height: .75rem;
   }

   .chart-table>table>tbody {
       font-size: 120%;
   }
}

.chart-table>table th,
.chart-table>table td {
    font-weight: normal;
}

.chart-table>table th {
    padding: 0.25rem;
    text-align: left;
    border-radius: .25rem 0 0 .25rem;
    margin-right: .5rem;
}

.chart-table>table td:last-child {
    padding: 0 .25rem 0 .75rem;
    text-align: right;
    border-radius: 0 .25rem .25rem 0;
}

.chart-table>table tbody td:nth-last-child(2) {
    padding: 0 .25rem 0 0;
    text-align: right;
}

.chart-table>table>tbody td {
    line-height: 150%;
}

.Light .chart-table>table th,
.Light .chart-table>table td {
    color:#000;
}
.Dark .chart-table>table th,
.Dark .chart-table>table td {
    color:#FFF;
}

.chart-table>table .dot {
    box-sizing: border-box;
    width: .875rem;
    height: .875rem;
    border-radius: 50%;
    margin-right: .5rem;
}
.Light .chart-table>table .dot {
    border: 1px solid #FFF;
}
.Dark .chart-table>table .dot {
    border: 1px solid #000;
}

.chart-table .unit {
    font-size: 80%;
    margin-left: .125rem;
    color: #888;
}

.chart-table>table>tbody tr:hover {
    background-color: var(--table-hover);
}

.chart-table>table>tbody tr:hover .dot {
    width: .875rem;
    height: .875rem;
}

.Light .chart-table>table>tbody tr:hover .dot {
    border: #FFF;
}
.Dark .chart-table>table>tbody tr:hover .dot {
    border: #000;
}

.chart-table>table>tbody>tr>th>img {
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
}

/*圖表區塊-th有圓點的表格 END
-----------------------------------------------*/


/*-----------------------------------------------
圖表區塊-使用外部圖表的區塊*/
.chartbox-notable>div>div>div>h3 {
    font-size: 0.875rem;
    font-weight: 600;
    margin-block-start: 0;
    margin-block-end: 1rem;
}
.chartbox-notable .title-and-score {
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.chartbox-notable h3.title{
    font-size: 0.875rem;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 1rem;
    margin-bottom: .5rem;
}
@media (width<=680px) {
    .chartbox-notable h3.title {
        font-size: 1rem;
        margin-bottom: .75rem;
    }
 }
 
 .Light .chartbox-notable h3,
 .Light .chartbox-notable .title {
     color: #000;
 }
 .Dark .chartbox-notable h3,
 .Dark .chartbox-notable .title {
     color: #DDD;
 }

.chartbox-notable .title-and-score .score{
    font-size: 0.875rem;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 1rem;
}


.chartblock16 { /*區塊內的圖表高度為16.5rem，適用於標題只有「單行」時*/
    /* background-color: #F8F9FC; */
    border-radius: 0.675rem;
    height: 16.5rem;
    color: #D4E0FF;
    text-align: center;
}

.chartblock18 { /*區塊內的圖表高度為18.5rem，適用於標題會「換行至2行」時*/
    /* background-color: #F8F9FC; */
    border-radius: 0.675rem;
    height: 18.5rem;
    color: #D4E0FF;
    text-align: center;
}

/*圖表區塊-使用外部圖表的區塊 END
-----------------------------------------------*/


/*-----------------------------------------------
圖表區塊-一周的每天評等*/

.chart-week-daily {
    height:16.5rem;
}

.chart-week-daily>table {
    width: 100%;
}

.chart-week-daily>table tr {
    border: 0;
    padding: 0;
    height: 2rem;
    line-height: 1.75rem;
}
.Light .chart-week-daily>table tr:hover {
    background: rgba(159, 186, 255, 0.1);
}
.Dark .chart-week-daily>table tr:hover {
    background: rgba(159, 186, 255, 0.2);
}

.chart-week-daily>table td {
    border: 0;
    padding: 0;
    font-weight: normal;
}

.chart-week-daily>table th {
    color: #AAA;
    font-size: 0.75rem;
    font-weight: normal;
    width: 5.5rem;
    padding: 0.25rem 0;
    text-align: left;
}

.chart-week-daily>table td div {
    align-items: center;
    border-radius: 0.375rem;
    margin: 0.25rem 0 0 0;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.75rem;
}

.remark-bluebox {
    padding: 0.0625rem 0.25rem;
    font-size: 0.625rem;
    line-height: 0.625rem;
    text-align: left;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
}
.Light .remark-bluebox {
    color: #3D71F8;
    background-color: #EBEEFB;
}
.Dark .remark-bluebox {
    color: #EBEEFB;
    background-color: #3D71F8AA;
}

/*圖表區塊-一周的每天評等 END
-----------------------------------------------*/


/*-----------------------------------------------
圖表區塊-百分比條圖-文字標題*/
.chart-persentbar-text {
    height: 18.5rem;  
}

.chart-persentbar-text>div:nth-of-type(1)>div,
.chart-persentbar-img>div:nth-of-type(1)>div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 0.75rem;
}
.Light .chart-persentbar-text>div:nth-of-type(1)>div,
.Light .chart-persentbar-img>div:nth-of-type(1)>div {
    color: #AAA;
    background: white;
    border-bottom: #EEE 0.0625rem solid;
}
.Dark .chart-persentbar-text>div:nth-of-type(1)>div,
.Dark .chart-persentbar-img>div:nth-of-type(1)>div {
    color: #AAA;
    background: #000;
    border-bottom: #888 0.0625rem solid;
}

.chart-persentbar-text>div:nth-of-type(2),
.chart-persentbar-img>div:nth-of-type(2) {    
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 17.5rem;
    -ms-overflow-style: none;
    /* 隱藏 scrollbar：IE and Edge */
    scrollbar-width: none;
    /* 隱藏 scrollbar：Firefox */
}
.chart-persentbar-text>div:nth-of-type(2)::-webkit-scrollbar,
.chart-persentbar-img>div:nth-of-type(2)::-webkit-scrollbar {
    /*隱藏 scrollbar：Chrome, Safari and Opera*/
    display: none;
}

.chart-persentbar-text>div:nth-of-type(2)>div {
    margin-top:1rem;
    display: block;
    /* height: 2rem; */
}
.Light .chart-persentbar-text>div:nth-of-type(2)>div:hover,
.Light .chart-persentbar-img>div:nth-of-type(2)>div:hover {
   background: rgba(159, 186, 255, 0.1);
}
.Dark .chart-persentbar-text>div:nth-of-type(2)>div:hover,
.Dark .chart-persentbar-img>div:nth-of-type(2)>div:hover {
   background: rgba(159, 186, 255, 0.2);
}

.chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(1),
.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: bottom;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}
.Light .chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(1),
.Dark .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2) {
    color: #222;
}
.Dark .chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(1),
.Dark .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2) {
    color: #CCC;
}

.chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(2),
.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(2) {
    height: 0.135rem;
    position: relative;
}

.chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1),
.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1) {
    width: 100%;
    height: 0.135rem;
}
.Light .chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1),
.Light .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1) {
    background-color: #DDD;
}
.Dark .chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1),
.Dark .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(1) {
    background-color: #444;
}

.chart-persentbar-text>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(2),
.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(2)>div:nth-of-type(2) {
    height: 0.27rem;
    position: absolute;
    bottom: -1px;
}

.Light .chart-persentbar-img .bar.color-bg-black {
    background:#000;
}
.Dark .chart-persentbar-img .bar.color-bg-black {
    background:#D4E0FF;
}
/*圖表區塊-百分比條圖-文字標題 END
-----------------------------------------------*/


/*-----------------------------------------------
圖表區塊-百分比條圖-圖片標題*/

.chart-persentbar-img>div:nth-of-type(2)>div {
    margin-top:0.75rem;
    display: block;
}

.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(1) {
    width:2.5rem;
    height:2rem;
    border-radius: 0.33rem;
}
.Light .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(1) {
    background: #EBEEFB88;
}
.Dark .chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(1) {
    background: #222;
}

.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2)>div {
    display: flex;
    flex-wrap: wrap;
}

.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2)>div>div:nth-of-type(1) {
    margin-right:0.5rem;
}
.chart-persentbar-img>div:nth-of-type(2)>div>div:nth-of-type(1)>div:nth-of-type(2)>div>div:nth-of-type(2) {
    color: #AAA;
}

/*圖表區塊-百分比條圖-圖片標題 END
-----------------------------------------------*/


/*-----------------------------------------------
圖表區塊-時間軸*/
/* .timeline>table {
    margin: 1.25rem;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

.timeline>table th{
    font-weight: normal;
    padding: 0.25rem 0;
    text-align: left;
    width: 7rem;
}

.timeline>table td{
    font-weight: normal;
    padding: 0.25rem 0;
}

.timeline>table>thead>tr>td {
    color: #AAA;
    font-size: 0.75rem;
    text-align: center;
    height: 1.75rem
}

.timeline>table>thead>tr:first-of-type {
    display: none;
}

@media (width <=1000px) {

    .timeline>table>thead>tr:first-of-type {
        display: table-row;
    }

    .timeline>table>thead>tr>td>span {
        display: none;
    }

    .timeline>table>thead>tr>td:nth-of-type(6) {
        border-right: #CCC solid 0.0625rem;
    }
}

.timeline>table>tbody {
    color: #888;
    font-size: 0.875rem;
    text-align: left;
}

@media (width <=768px) {
    .timeline>table th {
        width: 4rem;
    }

    .timeline>table>tbody>tr>th>span {
        display: none;
    }
}

.timeline>table>tbody>tr>td> .color-bg-grey-radius {
    background-color: #CCC;
    border-radius: 0.5rem;
    height: 1rem;
} */

/*圖表區塊-時間軸 END
-----------------------------------------------*/


/*-----------------------------------------------
條件顏色*/
.Light .color-div-lv1-great,
.Light .color-div-lv3-great {
    border: inherit;
    background-color: #DCF2C4;
    border-color: #7CC629;
}
.Dark .color-div-lv1-great {
    border: 1px solid;
    border-color: #DCF2C4;
    background-color: #7CC62988;
    box-shadow: 0px 0px 5px 0.25rem #DCF2C488;
}
.Dark .color-div-lv3-great {
    border: inherit;
    border-color: #DCF2C4;
    background: #7CC629;
    color:#000;
}

.Light .color-div-lv1-good,
.Light .color-div-lv3-good {
    border: inherit;
    background-color: #D3EDF3;
    border-color: #37B9CC;
}
.Dark .color-div-lv1-good {
    border: 1px solid;
    border-color: #D3EDF3;
    background-color: #37B9CC88;
    box-shadow: 0px 0px 5px 0.25rem #D3EDF388;
}
.Dark .color-div-lv3-good {
    border: inherit;
    border-color: #D3EDF3;
    background: #37B9CC;
    color:#000;
}

.Light .color-div-lv1-fair,
.Light .color-div-lv3-fair {
    border: inherit;
    background-color: #FFF3B6;
    border-color: #FFAA00;
}
.Dark .color-div-lv1-fair {
    border: 1px solid;
    border-color: #FFF3B6;
    background-color: #FFAA0088;
    box-shadow: 0px 0px 5px 0.25rem #FFF3B688;
}
.Dark .color-div-lv3-fair {
    border: inherit;
    border-color: #FFF3B6;
    background-color: #FFAA00;
    color:#000;
}

.Light .color-div-lv1-poor,
.Light .color-div-lv3-poor {
    border: inherit;
    background-color: #FFD4D4;
    border-color: #F66F6F;
}
.Dark .color-div-lv1-poor {
    border: 1px solid;
    border-color: #FFD4D4;
    background-color: #F66F6F88;
    box-shadow: 0px 0px 5px 0.25rem #FFD4D488;
}
.Dark .color-div-lv3-poor {
    border: inherit;
    border-color: #FFD4D4;
    background-color: #F66F6F;
    color:#000;
}

.Light .color-div-lv3-restday {
    border: inherit;
    background-color: #fff;
    border-color: #CCC;
}
.Dark .color-div-lv3-restday {
    border: inherit;
    background-color: #444;
    border-color: #CCC;
    color:#000;
}

/*注意：Border-style 和 border-width 要放在 border-color之後才會生效，移動時需小心*/
.color-div-lv1-great,
.color-div-lv1-good,
.color-div-lv1-fair,
.color-div-lv1-poor {
    border-style: solid;
    border-width: 0.135rem;
}

.color-div-lv3-great,
.color-div-lv3-good,
.color-div-lv3-fair,
.color-div-lv3-poor,
.color-div-lv3-restday {
    border-style: solid;
    border-width: 0.0625rem;
}

.color-div-lv1-great .criteria-L,
.color-div-lv2-great .criteria-L,
.color-div-lv3-great {
    color: #7CC629;
}

.color-div-lv1-good .criteria-L,
.color-div-lv2-good .criteria-L,
.color-div-lv3-good {
    color: #37B9CC;
}

.color-div-lv1-fair .criteria-L,
.color-div-lv2-fair .criteria-L,
.color-div-lv3-fair {
    color: #FFAA00;
}

.color-div-lv1-poor .criteria-L,
.color-div-lv2-poor .criteria-L,
.color-div-lv3-poor {
    color: #F66F6F;
}

.color-div-lv1-restday .criteria-L,
.color-div-lv2-restday .criteria-L,
.color-div-lv3-restday {
    color: #AAA;
}
/* 
.color-danger-div-great {
    color: #F66F6F;
    background-color: #FEF0F0;
    border: #FFD4D4 0.0625rem solid;
} */

/* .color-danger-div-good {
    color: #F66F6F;
    background-color: #FCD3D3;
    border: #FFBFBF 0.0625rem solid;
} */

/* .color-danger-div-fair {
    color: #B53838;
    background-color: #F9A8A8;
    border: #F9A8A8 0.0625rem solid;
} */

/* .color-danger-div-poor {
    color: #fff;
    background-color: #F66F6F;
    border: #F66F6F 0.0625rem solid;
} */

.color-day-workday {
    color: #3D71F8;
    background-color: #EBEEFB;
}

.color-day-restday {
    color: #F66F6F;
    background-color: #FFD4D4;
}

.color-bg-danger {
    background-color: #F66F6F;
    color: #fff;
}

.color-bg-black {
    background-color: #000;
    color: #fff;
}

/*條件顏色 END
-----------------------------------------------*/

.echarts-tooltip {
    z-index: 9999;
    pointer-events: auto;
}