/*跳出「選擇」視窗 START ↓ ↓ ↓ */
.h40 {
    height:40vh;
}

.h60 {
    height:60vh;
}

/*----抄Bootstrap的Modal CSS START ↓ ↓ ↓ */

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    outline: 0;
}

.modal-dialog {
    box-sizing: border-box;
    position: relative;
    width: auto;
    /* max-width: 500px; */
    margin: .5rem;
    transform: translate(0, 0);
}

@media (min-width: 576px) {
    .modal-dialog {
        /* max-width: 500px; */
        margin: 1.75rem auto;
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.625rem;
    padding: 1rem;
}
.Light .modal-content {
    background-color: #fff;
}
.Dark .modal-content {
    background-color: #111;
    box-shadow: 0px 0px 5px 4px #FFFFFF22;
    border: 1px solid #444;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    opacity: 1;
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
}

.modal-body {
    padding: 0.5rem 1rem;
    max-height: calc(95vh - 16.5rem);
    overflow-y: auto;
}

.modal-footer {
    display: block;
    padding: 0.5rem 1rem;
}

/*----抄Bootstrap的Modal CSS END ↑ ↑ ↑ */

/*----Modal 重要樣式 START ↓ ↓ ↓ */

.modal-content .scroll {
    max-height: 90vh;
    overflow-y: auto;
}




.common {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.common .dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.15rem;
    background-color: #9FBAFF;
}

.common .text {
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.area-checkbox-category legend {
    display: none;
}

.area-checkbox-category .category .title {
    color: #3D71F8;
    font-size: 0.75rem;
    margin-top: 0.625rem;
}

.area-checkbox-category .category .item-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 1px solid #CCC;
    border-radius: 0.3125rem;
}

.area-checkbox-category .item,
.area-checkbox-category .item>div:first-child,
.area-checkbox-category .item-title,
.area-checkbox-category .item-title>div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.area-checkbox-category .item-title {
    padding: 0.25rem 0.5rem;    
    position: relative;
    justify-content: space-between;
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.area-checkbox-category .item-title>div:first-child>div:not(:first-child){
    margin-left: 0.875rem;
}

.area-checkbox-category .item-title .check{
    width: calc(1rem + .75rem);
    height: .875rem;
}

.area-checkbox-category .item-group .item {
    border-bottom: 1px solid #CCC;
    padding: 0.625rem 0.5rem;
    position: relative;
    justify-content: space-between;
}

.area-checkbox-category .item-group .item:hover {
    background-color: #EBEEFB;
}

.area-checkbox-category .item-group .item .fill {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.area-checkbox-category .item-group .item:last-child .fill {
    border-radius: 0 0.3125rem;
}

.area-checkbox-category .item-group .item:first-child .fill {
    border-radius: 0.3125rem 0;
}

.area-checkbox-category input[type="checkbox"]:checked+label .fill{
    background-color: #0346F2;
}

.area-checkbox-category input[type="checkbox"]{
    appearance: none;
    width: 1rem;
    height: 0.75rem;
    background-color: rgba(0,0,0,0);
}

.area-checkbox-category input[type="checkbox"]:checked{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%229%22 viewBox=%220%200%2012%209%22><polyline points=%221%205%204%208%2011%201%22 stroke=%22%233D71F8%22 stroke-width=%222%22 fill=%22none%22/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
}

.area-checkbox-category input[type="checkbox"]:checked+label {
    color: #0346F2;
}

.area-checkbox-category .item-group .item label {
    display: flex;
    margin-left: 0.875rem;
    box-sizing: border-box;
    line-height: 1rem;
    word-break: keep-all;
}

@media (width <=520px) {
    .area-checkbox-category .item-group .item>div:first-child {
        width: calc(100% - 6rem);
    }

    .area-checkbox-category .item-group .item label {
        width: calc(100% - 3rem);
    }
}

.area-checkbox-category .item-group .item:last-child {
    border-bottom: 0;
}

.area-checkbox-category .common input {
    position: relative;
}

.area-checkbox-category .common input:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.3rem;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    background-color: #9FBAFF;
    border-radius: 50%;
    z-index: 2;
}

.area-checkbox-category .remark {
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.area-checkbox-category .remark>div:nth-child(1) {
    width: 6rem;
    text-align: center;
}

.area-checkbox-category .remark>div:nth-child(2) {
    width: 5rem;
    text-align: center;
}

.area-checkbox-category .info {
    color: #000;
    font-size: 0.75rem;
    line-height: 0.75rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;    
    text-align: left;
}

.area-checkbox-category .w10 {
    width: 10rem;
}

.area-checkbox-category .w20 {
    width: 20rem;
}

@media (width <=520px) {
    .area-checkbox-category .remark {
        flex-direction: column;
    }

    .area-checkbox-category .remark>div:nth-child(1),
    .area-checkbox-category .remark>div:nth-child(2) {
        width: 6rem;
        text-align: right;
    }

    .area-checkbox-category .remark>div:nth-child(1) {
        color: #000;
        text-align: right;
    }
}


/*使用<table>的checkbox列表*/

/* .tableCheckboxList legend {
    display: none;
} */

/* .tableCheckboxList .category .title {
    color: #3D71F8;
    font-size: 0.75rem;
    margin-top: 0.625rem;
} */

.tableCheckboxList .category {
    width:100%;
}
.tableCheckboxList .category .item-group {
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: column; */
    border: 1px solid #CCC;
    border-radius: 0.3125rem;
}

.tableCheckboxList th {
    padding: .25rem .5rem;
}

.tableCheckboxList td {
    padding: .75rem .5rem;
}

.tableCheckboxList .item-title {
    padding: 0.25rem 0.5rem;    
    position: relative;
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
}

.inlineflex {
    display: inline-flex;
}

.tableCheckboxList .item-title .check{
    width: 1.75rem;
    height: .875rem;
}

.tableCheckboxList .item-group .item {
    border-bottom: 1px solid #CCC;
    padding: 0.625rem 0.5rem;
    position: relative;
    font-size: .875rem;
    /* justify-content: space-between; */
}

.tableCheckboxList .item-group .item:hover {
    background-color: #EBEEFB;
}

.tableCheckboxList .item-group .item .fill {
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.05;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.tableCheckboxList .item-group .item:last-child .fill {
    border-radius: 0 0.3125rem;
}

.tableCheckboxList .item-group .item:first-child .fill {
    border-radius: 0.3125rem 0;
}

.tableCheckboxList input[type="checkbox"]:checked+label .fill{
    background-color: #0346F2;
}

.tableCheckboxList input[type="checkbox"]{
    appearance: none;
    width: 1rem;
    height: 0.75rem;
    background-color: rgba(0,0,0,0);
    margin: 0 .5rem 0 .25rem;
}

.tableCheckboxList input[type="checkbox"]:checked{
    background-image: url('data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%229%22 viewBox=%220%200%2012%209%22><polyline points=%221%205%204%208%2011%201%22 stroke=%22%233D71F8%22 stroke-width=%222%22 fill=%22none%22/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
}

.tableCheckboxList tr td:first-child>input[type="checkbox"]:checked~td>label {
    color: #0346F2;
}

.tableCheckboxList .item-group .item label {
    /* display: flex; */
    /* margin-left: 0.875rem; */
    box-sizing: border-box;
    line-height: 1rem;
    word-break: keep-all;
    font-size: .875rem;
}

@media (width <=520px) {
    .tableCheckboxList .item-group .item>div:first-child {
        width: calc(100% - 6rem);
    }

    .tableCheckboxList .item-group .item label {
        width: calc(100% - 3rem);
    }
}

.tableCheckboxList .item-group .item:last-child {
    border-bottom: 0;
}

.tableCheckboxList .common input {
    position: relative;
}

.tableCheckboxList .common input:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.3rem;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    background-color: #9FBAFF;
    border-radius: 50%;
    z-index: 2;
}

.tableCheckboxList .remark {
    color: #AAA;
    font-size: 0.75rem;
    line-height: 0.75rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.tableCheckboxList .remark>div:nth-child(1) {
    width: 6rem;
    text-align: center;
}

.tableCheckboxList .remark>div:nth-child(2) {
    width: 5rem;
    text-align: center;
}

.tableCheckboxList .info {
    color: #000;
    font-size: 0.75rem;
    line-height: 0.75rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;    
    text-align: left;
}

.tableCheckboxList .w10 {
    width: 10rem;
}

.tableCheckboxList .w20 {
    width: 20rem;
}

@media (width <=520px) {
    .tableCheckboxList .remark {
        flex-direction: column;
    }

    .tableCheckboxList .remark>div:nth-child(1),
    .tableCheckboxList .remark>div:nth-child(2) {
        width: 6rem;
        text-align: right;
    }

    .tableCheckboxList .remark>div:nth-child(1) {
        color: #000;
        text-align: right;
    }
}


/*---跳出視窗的Footer-已選擇的項目 START ↓ ↓ ↓ */
.item-selected {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 1rem 0;
    max-height: 4.5rem;
    overflow-y: auto;
}

.item-selected>div {
    background-color: #EBEEFB;
    color: #3D71F8;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
    border-radius: 1.5rem;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.item-selected>div:hover {
    background-color: #D4E0FF;
}

.item-selected>div:active {
    background-color: #9FBAFF;
}

.item-selected .cancel {
    border: 0;
    width: 0.875rem;
    height: 0.875rem;
    background-color: #3D71F8;
    border-radius: 50%;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-selected .cancel>div {
    width: 100%;
    height: 100%;
    color: #FFF;
    font-size: 0.875rem;
    line-height: 0.875rem;
}

/*---跳出視窗的Footer-已選擇的項目 END ↑ ↑ ↑ */

/*---跳出視窗的Footer-按鈕群組 START ↓ ↓ ↓ */
.button-group {
    height: 2.375rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
}

.button-group>div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: stretch;
}

.button-group>div>button {
    margin-right: 0.5rem;
}

.button-group button:not(.btn-txt-black) {
    padding: 0 1rem;
}

/*---跳出視窗的Footer-按鈕群組 END ↑ ↑ ↑ */

/*跳出「選擇」視窗 END ↑ ↑ ↑ */