

.singleExecutionLog-table-container {
    max-height: 50vh;
    overflow-y: auto;
}

/* .modal-header, .modal-footer{
    border: none;
} */

.modal-header{
    border: none;
}

.modal-header{
    padding-bottom: 0;
}

/* .modal-footer{
    padding-top: 0;
} */

.border-gray{
    border: 1px solid #ccc;
    min-height: 2.5rem;
}

.border-red{
    border: 1px solid #F66F6F;
    min-height: 2.5rem;
}

.machineProgram optgroup{
    border:10px dotted #00f !important;
}


.box-list-datediv .dmtable .tr{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    border-bottom:#CCC 1px solid;
    position: relative;
}

.box-list-datediv .dmtable .thead .tr{
    color:#3D71F8;
    background-color: #FFF;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.box-list-datediv .dmtable .tgroup {
    display:flex;
    flex-direction: column;
}

.box-list-datediv .dmtable .tr>div{
    box-sizing: border-box;
    padding:0.5rem 0.2rem;  
    line-height: 1.5rem;
}

.box-list-datediv .dmtable .tr>div:not(:first-child){
    flex:1 1 0;
    min-width: 4.5rem;
}

.box-list-datediv .dmtable .tgroup .tgroup-title{
    box-sizing: border-box;
    padding:0.5rem 0.5rem 0.5rem 2rem;   
    background-color: #EEEEEE;
    font-weight: 600;
}

.box-list-datediv .dmtable .tbody .tr:not(:first-child){
    cursor: pointer;
}

.box-list-datediv .dmtable .tbody .tr:not(:first-child):hover{
    background-color:#F8F9FC ;
}

.box-list-datediv .dmtable .tbody .active{
    background-color:#EBEEFB ;
}

/* .box-list-datediv .dmtable .tbody .tr:not(:first-child):active:before{
    position: absolute;
    content:'\e5ca';
    color:#3D71F8;  
    font-family: 'Material Symbols Rounded';
    font-weight: normal;
    font-style: normal;    
    font-size: 1.5rem;
    line-height: 130%;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;      
    box-sizing: border-box;
    padding-left:0.5rem;

} */

.box-list-datediv .dmtable .checkbox{
    width:2rem;
    margin-right:2rem;
}

.box-list-datediv .table-scroll-justify{
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;
}

.btn.disabled{
    color: #AAA;
    border-color: #AAA;
}

