#WorkOrder.table, #Scheduling.table  {
    overflow: auto auto;
}

#WorkOrder .c1, #Scheduling .c1 {
    width: 3rem;
}

#WorkOrder .c2 {
    width: 8rem;
}

#WorkOrder .c3 {
    width: 6rem;
}

#WorkOrder .c4 {
    width: 15rem;
}

#WorkOrder .c5 {
    width: 8rem;
}

#WorkOrder .c6 {
    width: 5rem;
}

#WorkOrder .c7, #Scheduling .c7 {
    width: 17.5rem;
}

#WorkOrder .c8 {
    width: 5.5rem;
}

#WorkOrder .c9 {
    width: 5rem;
}

#WorkOrder .c10, #Scheduling .c3, #Scheduling .c8, #Scheduling .c10 {
    width: 9rem;
}

#WorkOrder .c11 {
    width: 9.5rem;
}

#WorkOrder .c12, #Scheduling .c2 {
    width: 10rem;
}

#WorkOrder .c13 {
    width: 12rem;
}

#Scheduling .c4{
    width: 20rem;
}