:root {
  --bg-light: #eaefff;
  --bg-dark: #222;
  --bg-light-blur: #eaefffdd;
  --bg-dark-blur: #222222DD;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  overflow-x: hidden;
  cursor: default;
}

body::-webkit-scrollbar {
  display: none;
  /* 隱藏滾動條（針對 Webkit 瀏覽器，如 Chrome 和 Safari） */
}

/*screen mode color*/
body.Light {
  background: var(--bg-light) !important;
  color: #000;
}

body.Dark {
  background: var(--bg-dark) !important;
  color: #EEE;
}

a {
  text-decoration: none !important;
}

.Dark b {
  color: #EEE;
}


/*============================
  --------loading效果---------
  　　(等待、載入的轉圈圈)
  ============================*/
.loading-circle-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.8;
  transition: all .6s ease;
  z-index: 99999;
}

.loading-circle {
  position: fixed;
  top: 45%;
  left: 45%;
  width: 5rem;
  height: 5rem;
  border-radius: 4rem;
  animation: loading 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*screen mode color*/
.Light .loading-circle {
  border: 5px solid #3D71F8;
  border-top-color: #fff;
}

.Dark .loading-circle {
  border: 5px solid #3D71F8;
  border-top-color: #888;
}

.token-circle-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: all .6s ease;
  z-index: 99999;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*============================
  -----------文字-------------
         (font, text)
  ============================*/
/*文字大小*/
.text-normal,
.text.normal,
.text16 {
  line-height: 1;
  font-size: 1rem;
}

.text-M,
.text.M,
.text14 {
  line-height: 1;
  font-size: .875rem;
}

.text-S,
.text.S,
.text12 {
  line-height: 1;
  font-size: .75rem;
}

.text-XS,
.text.XS,
.text10 {
  line-height: 1;
  font-size: .625rem;
}

*>*:not(:first-child).text12,
*>*:not(:first-child).text10 {
  margin-top: .5rem;
}

/*文字排版*/
.text-left,
.tleft,
.tLeft {
  text-align: left;
}

.text-center,
.tcenter,
.tCenter {
  text-align: center;
}

.text-right,
.tright,
.tRight {
  text-align: right;
}

.text.break-normal {
  word-break: normal !important;
  word-wrap: break-word !important;
}

.text.keep-all {
  word-break: keep-all !important;
  word-wrap: break-word !important;
}

.text.break-all {
  word-break: break-all !important;
}

/* .text.noBr br{
    display: none;
} */

.text.lineHeight150 {
  line-height: 1.5;
}

.text.lineHeight100 {
  line-height: 1;
}

/*英文樣式：首字大寫*/
.capitalize {
  text-transform: capitalize;
}

/*文字顏色*/
.text-black,
.text.black {
  color: #222;
}

.text-grey2,
.text.grey2 {
  color: #222;
}

.text-grey4,
.text.grey4 {
  color: #444;
}

.text-grey6,
.text.grey6 {
  color: #666;
}

.text-grey8,
.text.grey8 {
  color: #888;
}

.text-greyA,
.text.greyA {
  color: #AAA;
}

.text-greyC,
.text.greyC {
  color: #CCC;
}

.text-blue,
.text.blue {
  color: #3D71F8;
}

.text-blue-dark,
.text.blue-dark {
  color: #3259CA;
}

.text-lightBlue,
.text.lightBlue {
  color: #9FBAFF;
}

.text-lightGreen,
.text-good,
.text.lightGreen,
.text.good {
  color: #37B9CC;
}

.text-alert,
.text-poor,
.text-error,
.text-red,
.text.alert,
.text.poor,
.text.error,
.text.red {
  color: #F66F6F !important;
}

.text-warning,
.text-fair,
.text.warning,
.text.fair {
  color: #FFAA00;
}

.text-success-msg,
.text-great,
.text.success-msg,
.text.great {
  color: #7CC629 !important;
}


/*============================
  -----------填色-------------
      (background, fill)
  ============================*/
.bg-black,
.icon.black {
  background: #000 !important;
}

.bg-Offline,
.bg-grey8 {
  background: #888888 !important;
}

.bg-greyA {
  background: #AAA;
}

.bg-greyFE {
  background: #FEFEFE;
}

.bg-lightblue4 {
  background: #F8F9FC;
}

.bg-lightblue3 {
  background: #EBEEFB;
}

.bg-lightblue2 {
  background: #D4E0FF;
}

.bg-lightblue1 {
  background: #D4E0FF;
}

.bg-blue,
.icon.blue,
.btn.blue,
.icon.SystemNotification {
  background: #3D71F8;
}

.bg-Machining,
.bg-Success,
.bg-Great,
.bg-green,
.icon.green {
  background: #7CC629 !important;
}

.bg-Good,
.icon.lakeblue {
  background: #37B9CC !important;
}

.bg-Idle,
.bg-Warning,
.bg-Fair,
.icon.yellow,
.icon.warning {
  background: #FFAA00 !important;
}

.bg-Alarm,
.bg-Poor,
.bg-red,
.icon.red,
.icon.alarm {
  background: #F66F6F !important;
}

.bg-Alarm.fair {
  background: #F9A8A8 !important;
}

.bg-Alarm.good {
  background: #FFF0F0 !important;
}

.bg-Alarm.great {
  background: #FFF8F8 !important;
}

.background-bar-Upcoming {
  background: #F3F6FB !important;
}

/*============================
  ---------圖片尺寸-----------
  ============================*/

.size-M {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.size-S {
  height: 1rem !important;
  width: 1rem !important;
}

.size-XS {
  width: .875rem;
  height: .875rem;
}


/*分隔線樣式*/
.hr {
  border: 0.1rem solid #ccc;
}

.hr-blue {
  border: 1px solid #3D71F8 !important;
}

.divider {
  width: 2px;
  height: 25px;
  background: #ccc;
  margin: 0 10px;
}

.margin20 {
  margin: 1.25rem;
}

.margin30 {
  margin: 1.875rem;
}

.mb10 {
  margin-bottom: .625rem;
}

.mb20 {
  margin-bottom: 1.25rem;
}

.mtop10 {
  margin-top: .625rem;
}

.mtop20 {
  margin-top: 1.25rem;
}

.mleft025 {
  margin-left: .25rem;
}

.mRight8 {
  margin-right: .5rem;
}

.pBottom4 {
  padding-bottom: .25rem;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.relative {
  position: relative;
}


/*-------------以下還沒有整理好 (待整理)-*/

.dropmenu .item {
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
}

.dropmenu .item .icon {
  width: 1.25rem;
  height: 1.25rem;
  background: #444;
  margin-right: .5rem;
}

.dropmenu .item .text {
  color: #000;
  font-size: .875rem;
}

/*列表尾端功能選單的「More ...」按鈕*/
.dropdownIcon.dropdown-toggle::after {
  display: none !important;
}

.dropdownIcon {
  background: #3D71F800 !important;
  width: 2.5rem;
  height: 2.5rem;
  display: grid !important;
  place-content: center;
  border: none !important;
  padding: 0 !important;
}

.dropdownIcon .icon {
  background: #3D71F800 !important;
  width: 1.5rem;
  height: 1.5rem;
}

.dropdownIcon:hover,
.dropdownIcon:active {
  background: #3D71F822 !important;
}

tr:hover .dropdownIcon .icon {
  background: #3D71F8AA !important;
}


/*============================
  -----------表格-------------
         (table, .table)
  ============================*/

/*Table樣式*/
.table-wrapper {
  box-sizing: border-box;
  padding: 1.5rem;
  padding: 0 .5rem .5rem .5rem;
  border-radius: .625rem;
  margin: .75rem 0;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

@media (max-width: 1200px) {
  .table-wrapper table {
    width: 1200px;
  }
}

.table-wrapper table thead th {
  font-size: .75rem;
  font-weight: 400;
  padding: .5rem .75rem;
  line-height: 1.1;
}

.table-wrapper table tbody td {
  font-size: .875rem;
  padding: .75rem;
  line-height: 1.1;
  vertical-align: middle;
}

.table-wrapper table .img-wp {
  width: 3.75rem;
  height: 2.875rem;
  border-radius: 0.25rem;
}

.table-wrapper td .clickTxt:hover {
  cursor: pointer;
  font-weight: 500;
}

.table-wrapper .nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 40rem;
  padding: 0;
  text-align: center;
  font-size: .75rem;
}

.table-wrapper .nodata .notice {
  box-sizing: border-box;
  width: fit-content;
  font-size: 0.875rem;
  padding: 0.25rem 0.875rem;
  border-radius: calc(0.25rem + 0.875rem);
  display: flex;
}


@media (max-width: 1200px) {
  .table-wrapper.rwd {
    padding: .5rem .5rem .5rem .5rem;
  }

  .rwd table,
  .rwd thead,
  .rwd tbody {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .rwd thead {
    display: none;
  }

  .rwd tr,
  .rwd.col4to2 tr,
  .rwd.col2 tr {
    display: grid;
    gap: 0 .5rem;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: relative;
  }

  .rwd tr,
  .rwd.col4to2 tr {
    grid-template-columns: repeat(4, 1fr);
    /*均分4欄 */
  }

  .rwd.col2 tr {
    grid-template-columns: repeat(2, 1fr);
    /*均分2欄 */
  }

  .rwd td::before {
    content: attr(data-label);
    font-weight: 400;
    font-size: .625rem;
    color: #AAA;
    display: block;
  }

  .rwd.firstColImg tr {
    grid-template-columns: 100px repeat(3, 1fr);
    /* 左側固定 100px，右側占剩餘空間，均分3欄 */
  }

  .rwd.firstColCheckbox tr {
    grid-template-columns: 2rem repeat(3, 1fr);
    /* 左側固定 100px，右側占剩餘空間，均分3欄 */
  }

  .rwd.text-keep-all td {
    word-break: keep-all;
    word-wrap: break-word;
  }

  .rwd.text-keep-normal td {
    word-break: normal;
    word-wrap: break-word;
  }

  .rwd.firstColImg tr td:first-child,
  .rwd.firstColCheckbox tr td:first-child {
    grid-column: 1;
    /* 左側固定在第一列 */
    grid-row: 1 / 6;
  }

  .rwd.col4to2 td.span2 {
    grid-column: 1 / 3;
  }

  .rwd.col4to2 td.text.noBr br {
    display: none;
  }

  .rwd .tRight,
  .rwd .tCenter {
    text-align: left !important;
  }

  .rwd .tableFuncBtn {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .rwd tr:hover .tableFuncBtn .dropdownIcon {
    background: #3D71F822 !important;
  }

  .rwd tr .tableFuncBtn .dropdownIcon:hover,
  .rwd tr .tableFuncBtn .dropdownIcon:active {
    background: #3D71F844 !important;
  }

  .rwd .rwd-display-none {
    display: none;
  }

  #timePeriodCapture-Main-PartAverage.flex-wrap.column {
    flex-flow: row wrap;
    gap: .5rem;
  }

  #timePeriodCapture-Main-PartAverage.flex-wrap.column .mRight8 {
    margin-right: .25rem;
  }
}

@media (max-width: 600px) {
  .rwd.firstColImg tr {
    grid-template-columns: 100px repeat(2, 1fr);
    /* 左側固定 100px，右側占剩餘空間，均分2欄 */
  }

  .rwd.firstColCheckbox tr {
    grid-template-columns: 2rem repeat(2, 1fr);
    /* 左側固定 100px，右側占剩餘空間，均分2欄 */
  }

  .rwd.col4to2 tr {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 450px) {
  .rwd.firstColImg tr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 均分2欄 */
  }

  .rwd.firstColImg tr td:first-child {
    grid-column: 1 / 3;
    grid-row: 1;
  }
}

.noDataDiv {
  display: flex;
  justify-content: center;
}

.noData {
  border-radius: 0.9rem;
  padding: 3px 12px;
  margin: 3rem 0;
  font-size: .75rem;
}

.table-minheight0 {
  overflow-x: auto;
  min-height: 78vh;
}

.table-minheight1 {
  overflow-x: auto;
  min-height: 70vh;
}

.table-minheight2 {
  overflow-x: auto;
  min-height: 67vh;
}

.table-minheight3 {
  overflow-x: auto;
  min-height: 62vh;
}

@media (width<=1024px) {
  .table-minheight1 {
    min-height: calc(70vh - 5.5rem);
  }

  .table-minheight2 {
    min-height: calc(67vh - 5.5rem);
  }

  .table-minheight3 {
    min-height: calc(62vh - 5.5rem);
  }
}

@media (width<=570px) {

  .table-minheight1,
  .table-minheight2,
  .table-minheight3 {
    min-height: 0;
  }
}

.minWidth160 {
  min-width: 10rem;
}


@media (max-width: 1200px) {
  .rwd thead.showTHead {
    display: block;
  }

  .rwd thead.showTHead tr {
    display: inline-flex;
    flex-flow: row wrap;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  .rwd thead.showTHead tr th {
    border-bottom: none;
    min-width: 0;
    width: fit-content;
    padding: .5rem 1rem;
    border-radius: .25rem;
  }

  .rwd thead.showTHead tr th.noShowWhenRWD {
    display: none;
  }
}

.table-wrapper thead th .sort-icons,
.table-wrapper thead th .icon,
.table-wrapper thead th .icon-clicked {
  display: inline-flex;
  flex-wrap: nowrap;
}

.table-wrapper thead th .icon,
.table-wrapper thead th .icon-clicked {
  height: .7rem;
  width: .7rem;
}

.table-wrapper thead th.hoverShowIcon .icon {
  display: none;
}

.table-wrapper thead th.hoverShowIcon:hover .icon {
  display: inline-flex;
  background: #AAAAAA;
}


@media (max-width: 1200px) {
  .rwd thead.showTHead th.hoverShowIcon {
    cursor: pointer;
  }

  .rwd thead.showTHead th.hoverShowIcon:hover .icon {
    display: none;
  }

}

.table-wrapper thead th .icon-clicked {
  background: #000;
}

/*screen mode color*/
.Light .table-wrapper {
  background: #FFF;
  color: #000;
}

.Dark .table-wrapper {
  background: #000;
  color: #EEE;
}

.Light .table-wrapper.border {
  border: 1px solid #dce5ff;
  box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
}

.Dark .table-wrapper.border {
  border: 1px solid #dce5ff88;
  box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
}

.Light .table-wrapper thead th {
  color: #3d71f8;
  background: #FFF;
  border-bottom: 1px solid #DDD;
}

.Dark .table-wrapper thead th {
  color: #AAA;
  background: #000;
  border-bottom: 1px solid #888;
}

@media (max-width: 1200px) {
  .Light .rwd thead.showTHead th.hoverShowIcon.unclicked {
    color: #3d71f8;
    background: #0346F211;
    border-bottom: 0;
    border: 2px solid #FFF;
  }

  .Light .rwd thead.showTHead th.hoverShowIcon.unclicked:hover {
    color: #3d71f8;
    border-bottom: 0;
    border: 2px solid #0346F222;
  }

  .Light .rwd thead.showTHead th.hoverShowIcon.clicked {
    color: #3d71f8;
    background: #0346F222;
    border-bottom: 0;
    border: 2px solid #0346F266;
  }
}

/* .Light .table-wrapper thead th .icon.grey8{
  background: #888;
}

.Dark .table-wrapper thead th .icon.grey8{
  background: #CCC;
} */

.Light .table-wrapper table tbody tr {
  border-bottom: 1px solid #CCC;
}

.Dark .table-wrapper table tbody tr {
  border-bottom: 1px solid #444;
}

.Light .table-wrapper table tbody tr:nth-child(even) {
  background: #F8F8F8;
}

.Dark .table-wrapper table tbody tr:nth-child(even) {
  background: #111;
}

.Light .table-wrapper tbody tr:hover {
  background: #EBEEFB !important;
}

.Dark .table-wrapper tbody tr:hover {
  background: #3D71F866 !important;
}

.Light .table-wrapper td .clickTxt:hover {
  color: #3259ca;
}

.Dark .table-wrapper td .clickTxt:hover {
  color: #FFF;
  text-decoration: underline dotted #AAAAAA;
}

.Light .table-wrapper td .clickTxt:active {
  color: #3259ca;
}

.Dark .table-wrapper td .clickTxt:active {
  color: #FFF;
  text-decoration: underline dashed;
}

.Light .table-wrapper .nodata .notice {
  color: #3D71F8;
  background: #D4E0FF;
}

.Dark .table-wrapper .nodata .notice {
  color: #FFF;
  background: #444;
}

.Light .noData {
  color: #3D71F8;
  background: #EBEEFB;
}

.Dark .noData {
  color: #CCC;
  background: #444;
}



/* 当屏幕宽度小于1200px时，添加水平滚动条 */
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-wrap.column {
  flex-direction: column;
}

.flex-nowrap.align-center,
.flex-wrap.align-center,
.align-center {
  align-items: center;
}

.gap1 {
  gap: 1rem;
}

.gap025 {
  gap: .25rem;
}


/*按鈕樣式*/
.button-icon-trash {
  color: #AAAAAA;
  font-size: 1.5rem !important;
}


.h40px {
  height: 2.5rem;
}

.w40px {
  width: 2.5rem;
}

.button-blue {
  background: #1D44B2;
  color: #fff;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1rem;
}

.button-blue:hover {
  background: #1D44B2;
  color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 3px #9FBAFF;
  padding: 0.5rem 1rem;
}

.button-not-border {
  background: #fff;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}


/*圓角樣式*/
.border-solid-light-blue {
  border: 2px solid #9FBAFF;
}

.border-bottom {
  border-bottom: 1px solid #AAAAAA;
}

/*工件圖片樣式*/
.img-workpiece {
  max-height: 7rem;
  min-height: 7rem;
  margin-bottom: 1rem;
}



.flat-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flat-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}


/*============================
  --------頁面內容空間---------
   (讓內容扣除左邊nav和上方Tab)
  ============================*/
.sidebarContent {
  margin-left: 3.5rem;
  margin-top: 50px;
  width: calc(100% - 3.5rem);
  position: relative;
}

@media (width<=1024px) {
  .sidebarContent {
    margin-left: 0;
    width: 100%;
    height: calc(100% - 100px);
    margin-bottom: 4rem;
  }
}

.pageContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  padding: 1.25rem;
}

@media (width<=1024px) {
  .pageContent {
    padding-bottom: calc(1rem + 1.25rem);
  }
}

@media (width<=430px) {
  .pageContent {
    padding: .75rem;
    padding-bottom: calc(4rem + .75rem);
  }
}

.pageContent.h70 {
  min-height: 70vh;
}


/*============================
  --------上排按紐排版---------
  ============================*/
.filterRow {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: stretch;
  justify-content: space-between;
}

.filterRow>div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
}

@media (width<=1024px) {
  .filterRow>div {
    flex-wrap: wrap;
  }
}

.filterRow>div>* {
  margin-bottom: .5rem;
}

.filterRow.small>div>* {
  margin-bottom: 0;
}

.filterRow>div:first-child>* {
  margin-right: .5rem;
}

.filterRow>div:nth-child(2)>* {
  margin-left: .5rem;
}

.filterRow>div:nth-child(2)>*:first-child {
  margin-left: 0;
}

.filterRow.h-normal>div>* {
  height: 2.375rem;
}

.filterRow .filling38 {
  height: 2.375rem;
  width: 5rem;
}

/*filterRow下的隱藏區塊，用於叫出「進階篩選」的區塊*/
.filterRow2 {
  background: #D4E0FF;
  border: none;
  border-radius: 0.25rem;
  padding: .75rem;
  padding-bottom: calc(.75rem - .5rem);
}

/*取代modal-inputText START*/


.inputWithLabel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputWithLabel>* {
  width: 100%;
}

.inputWithLabel input,
.inputWithLabel textarea,
.inputWithLabel select,
.inputWithLabel .select,
.inputWithLabel .input-wrapper,
.inputWithLabel .input,
.inputWithLabel .order2 {
  order: 2;
}

.inputWithLabel *.h-L {
  height: 5rem;
}

.inputWithLabel *.h-normal {
  height: 2.375rem;
}

.inputWithLabel label {
  min-height: 1rem;
  order: 1;
  font-size: .75rem;
  line-height: 100%;
  color: #888;
}

.inputWithLabel label .characterCount {
  color: #AAA;
  margin-left: .5rem;
}

.inputWithLabel *[required]+label:after,
.inputWithLabel *.required+label:after {
  content: " *";
  font-size: .75rem;
  color: #F66F6F;
}


.inputWithLabel,
.inputWithError {
  position: relative;
}

.inputWithLabel.errorspace,
.inputWithError.errorspace {
  margin-bottom: 2rem;
}

.inputWithLabel .errortext,
.inputWithError .errortext {
  position: absolute;
  top: 100%;
}

.errortext {
  margin-top: .25rem;
  font-size: .75rem;
  line-height: 110%;
  color: #F66F6F;
  word-break: keep-all;
}

input.error,
textarea.error,
select.error,
div.input.error {
  border-color: #F66F6F !important;
}

input.error:focus,
textarea.error:focus,
select.error:focus,
div.input.error:focus {
  outline: #F66F6F 1px solid;
}

/*取代modal-inputText END*/

.width10 {
  width: 10rem;
}

.width5 {
  width: 5rem;
}

.width15 {
  width: 20rem;
}

.width20 {
  width: 20rem;
}

.width21 {
  width: 21rem;
  min-width: 21rem;
}

.minWidth10 {
  min-width: 10rem;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-90 {
  width: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.overflow-hidden {
  overflow: hidden;
}


/*輸入框樣式*/

textarea,
select {
  box-sizing: border-box;
  background: #FFFFFF88;
  border: #CCC 1px solid;
  border-radius: .25rem;
  padding: .5rem;
}

.input {
  box-sizing: border-box;
  padding: .375rem;
  cursor: pointer;
  border-radius: .25rem;
  padding-left: .75rem;
}

.Light .input {
  /* border: 1px solid #EEE; */
  background: #fff;
}

.Dark .input {
  /* border: 1px solid #EEE; */
  background: #222;
}

.Light .input.outline {
  border: 1px solid #ccc;
}

.Dark .input.outline {
  border: 1px solid #444;
}

.input .remark {
  font-size: 80%;
  padding: .25rem;
  border-radius: .25rem;
  margin-right: .5rem;
}

.Light .input .remark {
  background: #EBEEFB;
  color: #3D71F8;
}

.Dark .input .remark {
  background: #EBEEFB33;
  color: #DDD;
}

.fit-content {
  width: fit-content;
}

.input-leftpadding,
.input-rightpadding,
.input-sidepadding {
  position: relative;
}

.input-leftpadding .input {
  padding-left: 2.75rem;
}

.input-rightpadding .input {
  padding-right: 2.75rem;
}

.input-sidepadding .input {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.input-leftpadding.wide .input {
  padding-left: 5rem;
}

.input-rightpadding.wide .input {
  padding-right: 5rem;
}

.input-leftpadding>.text,
.input-rightpadding>.text,
.input-sidepadding>.text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: .75rem;
}

.input-leftpadding input:disabled+.text,
.input-rightpadding input:disabled+.text,
.input-sidepadding input:disabled+.text {
  color: #888;
}

.input-leftpadding>.icon,
.input-rightpadding>.icon,
.input-sidepadding>.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1.75rem;
  width: 1.75rem;
  border: none;
  border-radius: .25rem;
  padding: 0;
  pointer-events: none;
}

.input-leftpadding>.icon.small,
.input-rightpadding>.icon.small,
.input-sidepadding>.icon.small {
  height: 1.5rem;
  width: 1.5rem;
}

.input-leftpadding>div,
.input-sidepadding>div:nth-last-child(2) {
  left: .5rem;
}

.input-rightpadding>div,
.input-sidepadding>div:last-child {
  right: .5rem;
}

.custom-tooltip {
  white-space: nowrap;
  font-size: 80%;
  width: max-content;
}

.custom-tooltip .tooltip-inner {
  text-align: left !important;
  white-space: normal;
  max-width: 18rem;
}

.remark-div {
  background: #F5F5F5;
  padding: .25rem .5rem;
  border-radius: .25rem;
  align-items: center;
}

.remark .text {
  color: #888;
  font-size: .75rem;
}

.remark .icon {
  background: #888;
}

input.btn:read-only {
  cursor: pointer;
}

.modal-form>div>input,
.modal-inputText input,
textarea,
select,
.btn-dropdown>div,
.btn-search>input {
  box-sizing: border-box;
  background: #FFFFFF88;
  border: #CCC 1px solid;
  border-radius: .25rem;
  padding: .375rem;
}

.btn-search {
  position: relative;
}

.btn-search>input[type="search"] {
  padding-right: 2.5rem;
}

.btn-search>button[type="submit"],
.btn-search>button[type="button"] {
  position: absolute;
  right: .25rem;
  top: .25rem;
  height: 2rem;
  width: 2rem;
  border: 0;
  border-radius: .25rem;
  padding-left: 0;
  padding-right: 0;
}

.input-text {
  color: #000000;
  background: #F8F9FC;
  border: none;
  border-radius: 0.5rem;
  height: 3rem;
  text-align: right;
  padding: 1rem;
}

.input-text-number {
  color: #000000;
  background: #F8F9FC;
  border: none;
  border-radius: 0.5rem;
  height: 3rem;
  padding: 1rem;
}

/*滑鼠游標*/
.cursor-pointer {
  cursor: pointer
}

.box-style-white-shadow,
.box-style-purple-shadow {
  box-shadow: 0px 0px 0px 0.25rem rgba(159, 186, 255, 0.05);
  border-radius: 0.625rem;
  position: relative;
}

.Light .box-style-white-shadow {
  background: #fff;
  border: 1px solid #dce5ff;
}

.Dark .box-style-white-shadow {
  background: #000;
  border: 1px solid #dce5ff44;
}

.box-style-white-shadow:hover {
  border: 1px solid #90adf8;
  box-shadow: 0px 0px 5px 0.25rem rgba(159, 186, 255, 0.3);
}

.Light .box-style-purple-shadow {
  background: #D4E0FF;
}

.Dark .box-style-purple-shadow {
  background: #666;
}


@media (width <=990px) {
  .box-style-white-shadow .margin30 {
    margin: 2rem 1.5rem;
  }
}


.formula {
  position: relative;
}

.formula-icon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: #3D71F8;
  font-size: x-large;
}

/*透明度*/
.opacity2 {
  opacity: 0.2;
}



.liSquare {
  width: 0.5rem;
  height: 0.5rem;
  background: #3D71F8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

li {
  list-style: none;
  margin-bottom: 10px;
}

.borderLeft {
  border-left: 5px solid #3D71F8;
}


/*滾動條*/
.scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  scrollbar-gutter: stable;
}

.scroll::-webkit-scrollbar-button {
  display: none;
  background: transparent;
  border-radius: 4px;
  height: 0;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
  width: 0;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #9FBAFF00;
  border: 0;
}

.scroll:hover::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #9FBAFF44;
  border: 0;
}


/*border樣式*/
.border-alert {
  border-color: #F66F6F !important;
}

.custom-slider .rc-slider-dot {
  display: none;
}

.one-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Toastify__toast-theme--dark {
  background: #DCF2C4 !important;
  color: #000 !important;
}

.Toastify__toast-container--top-center {
  width: fit-content !important;
}

.Toastify__progress-bar-theme--dark {
  background: #7CC629 !important;
}

.Toastify__close-button {
  color: #7CC629 !important;
}

.pagination {
  display: flex;
  flex-flow: row wrap;
  padding-left: 0;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  list-style: none;
}

.pagination>.page-item {
  padding: .25rem .375rem;
}

.pagination>.page-item>.page-link {
  padding: .25rem .75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  background: #FFFFFF66;
  border-radius: .375rem;
  color: #0346F2AA;
}

.pagination>.page-item>.page-link:hover {
  color: #0346F2;
  background: #D4E0FF22;
  border-color: #9FBAFF;
}

.pagination>.page-item>.page-link:focus {
  color: #FFF;
  background: #0d6efd;
}

.pagination>.page-item>.page-link:active {
  color: #fff;
  background: #0d6efd;
  border-color: #0d6efd;
}

.pagination>.page-item.disabled>.page-link {
  color: #00000000;
  background: #00000000;
  border-color: #F8F9FC transparent;
}

.pagination>.page-item.active>.page-link {
  color: #fff;
  background: #000;
  border-color: #000;
}

/*----------chart&data-----------*/
.button {
  box-sizing: border-box;
  color: #FFF;
  font-size: 0.875rem;
  border: 0;
  border-radius: 0.25rem;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

.btn {
  box-sizing: border-box;
  border-radius: .25rem !important;
  font-size: .875rem !important;
  line-height: .875rem !important;
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  border: 0;
}

.btn.disabled {
  cursor: default !important;
}

.btn.h38,
.btn.h-normal,
.h38 {
  height: 2.375rem;
}

.button .icon {
  font-size: 1.5rem;
}

.btn svg,
.btn .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.btn-sub {
  font-size: 0.75rem;
}

.btn-sub .icon {
  width: 1rem;
  height: 1rem;
}

/*按鈕btn： icon圖示 + 文字text---*/
.btn-icontext>span:nth-child(1) {
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

.btn-icontext>span:nth-child(2) {
  margin-right: 0.5rem;
}

.btn-icontext>svg,
.btn-icontext>.icon {
  margin-right: 0.25rem;
}

.btn-icontext>span {
  margin-right: 0.5rem;
}

.btn-icontext-rwd>svg,
.btn-icontext-rwd>.icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.btn-icontext-rwd>span {
  margin-right: 0.5rem;
}

@media(width<=400px) {
  .btn-icontext-rwd>span {
    display: none;
  }
}

.btn-icontext.rwd>svg,
.btn-icontext.rwd>.icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.btn-icontext.rwd>span {
  margin-right: 0.5rem;
}

@media(width<=400px) {
  .btn-icontext.rwd>span {
    display: none;
  }
}

/*按鈕btn： 只有icon圖示---*/
.btn-onlyicon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-onlyicon .icon {
  width: 1.375rem;
  height: 1.375rem;
}

/* @media (width <=600px) {
  .btn-onlyicon .icon {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
  }
} */

/*按鈕btn動畫------------*/
.btn-icontext:hover .icon,
.btn-icontext-rwd:hover .icon,
.btn-onlyicon.bounce:hover svg,
.btn-onlyicon.bounce:hover .icon,
*:hover>.icon.bounce {
  animation: bounce 0.7s ease-in-out infinite alternate;
}

/*-----------滑鼠hover上下飄動的動畫 */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-5px);
  }
}

/*-----------滑鼠hover轉動的動畫 */
.animate-rotate {
  border-radius: 50%;
  transition: transform 0.5s ease;
}

.animate-rotate:hover {
  transform: rotate(90deg);
}

/*按鈕等級Lv1｜黑底+無框+白字-----*/
.Light .btn-lv1 {
  background: #000 !important;
  border: 0 !important;
  color: #FFF !important;
  fill: #FFF;
}

.Dark .btn-lv1 {
  background: #DDD !important;
  border: 0 !important;
  color: #000 !important;
  fill: #000;
}

.Light .btn-lv1 .icon {
  background: #FFF;
}

.Dark .btn-lv1 .icon {
  background: #000;
}

.Light .btn-lv1:hover {
  background: #222 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  color: #FFF;
}

.Dark .btn-lv1:hover {
  background: #FFF !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  color: #000;
}

.Light .btn-lv1:active {
  background: #000 !important;
}

.Dark .btn-lv1:active {
  background: #DDD !important;
}

.Light .btn-lv1:disabled {
  background: #CCC !important;
  color: #888 !important;
}

.Dark .btn-lv1:disabled {
  background: #888 !important;
  color: #222 !important;
}

.Light .btn-lv1:disabled .icon {
  background: #888;
}

.Dark .btn-lv1:disabled .icon {
  background: #222;
}

.Light .btn-lv1.disabled {
  background: #00000088 !important;
  color: #FFF !important;
}

.Dark .btn-lv1.disabled {
  background: #FFFFFF88 !important;
  color: #000 !important;
}

.Light .btn-lv1.disabled .icon {
  background: #FFF;
}

.Dark .btn-lv1.disabled .icon {
  background: #000;
}

.btn-lv1.disabled:hover {
  background: #00000066 !important;
}


/*按鈕等級Lv2｜藍底+無框+白字-----*/
.btn-lv2 {
  background: #3D71F8 !important;
  border: 0 !important;
  color: #FFF !important;
}

.btn-lv2 .icon {
  background: #FFF;
}

.btn-lv2:hover {
  background: #275ce2 !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.btn-lv2:active {
  background: #3D71F8 !important;
}

.btn-lv2:disabled {
  background: #CCC;
  color: #888;
}

.btn-lv2.disabled {
  background: #3D71F888;
  color: #FFF;
}

.btn-lv2.disabled:hover {
  background: #3D71F866;
}

/*按鈕等級Lv3｜透明底+藍框+藍字-----*/
.Light .btn-lv3 {
  background: #3D71F800 !important;
  border: 1px solid #3D71F8 !important;
  color: #3D71F8 !important;
  fill: #3D71F8 !important;
}

.Dark .btn-lv3 {
  background: #3D71F800 !important;
  border: 1px solid #9FBAFF !important;
  color: #9FBAFF !important;
  fill: #9FBAFF !important;
}

.Light .btn-lv3 .icon {
  background: #3D71F8 !important;
}

.Light .btn-lv3 .text {
  color: #3D71F8 !important;
}

.Dark .btn-lv3 .icon {
  background: #9FBAFF !important;
}

.Dark .btn-lv3 .text {
  color: #9FBAFF !important;
}

.Light .btn-lv3:hover {
  background: #3D71F820 !important;
}

.Dark .btn-lv3:hover {
  background: #9FBAFF20 !important;
}

.Light .btn-lv3:active {
  background: #3D71F840 !important;
}

.Dark .btn-lv3:active {
  background: #9FBAFF40 !important;
}

.btn-lv3:disabled {
  background: #44444411 !important;
  border: 1px solid #AAA !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.btn-lv3:disabled .icon {
  background: #AAA !important;
}

.btn-lv3:disabled .text {
  color: #AAA !important;
}

.Light .btn-lv3.disabled {
  background: #3D71F800 !important;
  border: 1px solid #3D71F888 !important;
  color: #3D71F888 !important;
  fill: #3D71F888 !important;
}

.Dark .btn-lv3.disabled {
  background: #9FBAFF00 !important;
  border: 1px solid #9FBAFF88 !important;
  color: #9FBAFFAA !important;
  fill: #9FBAFFAA !important;
}

.Light .btn-lv3.disabled .icon {
  background: #3D71F888 !important;
}

.Light .btn-lv3.disabled .text {
  color: #3D71F888 !important;
}

.Dark .btn-lv3.disabled .icon {
  background: #9FBAFFAA !important;
}

.Dark .btn-lv3.disabled .text {
  color: #9FBAFFAA !important;
}

.Light .btn-lv3.disabled:hover {
  background: #3D71F820 !important;
}

.Dark .btn-lv3.disabled:hover {
  background: #9FBAFF20 !important;
}

/*按鈕等級Lv4｜透明底+灰框+黑字-----*/
.btn-lv4 {
  background: #44444400 !important;
  border: 1px solid #CCC !important;
  color: #000 !important;
  fill: #000 !important;
}

.btn-lv4:hover {
  background: #44444408 !important;
}

.btn-lv4:active {
  background: #44444416 !important;
  font-weight: 500;
}

.btn-lv4:disabled {
  background: #AAAAAA11 !important;
  border: 1px solid #AAA !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.btn-lv4.disabled {
  background: #44444411 !important;
  border: 1px solid #AAA !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.btn-lv4.disabled:hover {
  background: #44444422 !important;
}

.btn-lv5 {
  background: rgba(61, 113, 248, .1);
  border: 0;
  color: #000;
  fill: #000;
}

.btn-lv5:hover {
  background: rgba(61, 113, 248, .2);
}

.btn-lv5:active {
  background: rgba(61, 113, 248, .3);
}

.btn-lv5:disabled {
  background: rgba(61, 113, 248, .05);
  color: rgba(61, 113, 248, .2);
}

.btn-lv5 .icon {
  background: #000;
}

.btn-lv5:disabled .icon {
  background: rgba(61, 113, 248, .2);
}


/*按鈕｜白底+無框+藍字-----*/
.btn-txtBlue-bgWhite {
  background: #FFF !important;
  border: none !important;
  color: #3D71F8 !important;
  fill: #3D71F8 !important;
}

.btn-txtBlue-bgWhite .icon {
  background: #3D71F8;
}

.btn-txtBlue-bgWhite:hover {
  background: #9FBAFF22 !important;
}

.btn-txtBlue-bgWhite:active {
  background: #9FBAFF44 !important;
  color: #0346F2 !important;
  fill: #0346F2 !important;
}

.btn-txtBlue-bgWhite:active .icon {
  background: #0346F2;
}

.btn-txtBlue-bgWhite:disabled {
  background: #FFFFFF00 !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.btn-txtBlue-bgWhite:disabled .icon {
  background: #AAA;
}

.btn-txtBlue-bgWhite.disabled {
  background: #9FBAFF22 !important;
  color: #8faaf0AA !important;
  fill: #8faaf0AA !important;
}

.btn-txtBlue-bgWhite.disabled .icon {
  background: #8faaf0AA;
}

.btn-txtBlue-bgWhite.disabled:hover {
  background: #9FBAFF22 !important;
  color: #8faaf0 !important;
  fill: #8faaf0 !important;
}

.btn-txtBlue-bgWhite.disabled:hover .icon {
  background: #8faaf0;
}

/*按鈕｜淺藍底+無框+藍字-----*/
.Light .btn-txtBlue-bgLightBlue {
  background: #D4E0FF88 !important;
  border: none !important;
  color: #3D71F8 !important;
  fill: #3D71F8 !important;
}

.Light .btn-txtBlue-bgLightBlue:hover {
  background: #D4E0FFCC !important;
}

.Light .btn-txtBlue-bgLightBlue:active {
  background: #D4E0FFFF !important;
  color: #0346F2 !important;
  fill: #0346F2 !important;
}

.Light .btn-txtBlue-bgLightBlue:disabled {
  background: #FFFFFF00 !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.Light .btn-txtBlue-bgLightBlue.disabled {
  background: #D4E0FF44 !important;
  color: #8faaf0AA !important;
  fill: #8faaf0AA !important;
}

.Light .btn-txtBlue-bgLightBlue.disabled:hover {
  background: #D4E0FF22 !important;
  color: #8faaf0 !important;
  fill: #8faaf0 !important;
}

.Light .btn-txtBlue-bgLightBlue .icon {
  background: #3D71F8;
}

.Light .btn-txtBlue-bgLightBlue:disabled .icon {
  background: #AAA;
}

.Light .btn-txtBlue-bgLightBlue.disabled .icon {
  background: #8faaf0;
}

/**/

.Dark .btn-txtBlue-bgLightBlue {
  background: #D4E0FF22 !important;
  border: none !important;
  color: #CCC !important;
  fill: #CCC !important;
}

.Dark .btn-txtBlue-bgLightBlue:hover {
  background: #D4E0FF44 !important;
}

.Dark .btn-txtBlue-bgLightBlue:active {
  background: #D4E0FF66 !important;
  color: #000 !important;
  fill: #000 !important;
}

.Dark .btn-txtBlue-bgLightBlue:disabled {
  background: #FFFFFF00 !important;
  color: #AAA !important;
  fill: #AAA !important;
}

.Dark .btn-txtBlue-bgLightBlue.disabled {
  background: #D4E0FF44 !important;
  color: #8faaf0AA !important;
  fill: #8faaf0AA !important;
}

.Dark .btn-txtBlue-bgLightBlue.disabled:hover {
  background: #D4E0FF22 !important;
  color: #8faaf0 !important;
  fill: #8faaf0 !important;
}

.Dark .btn-txtBlue-bgLightBlue .icon {
  background: #CCC;
}

.Dark .btn-txtBlue-bgLightBlue:disabled .icon {
  background: #AAA;
}

.Dark .btn-txtBlue-bgLightBlue.disabled .icon {
  background: #8faaf0AA;
}

/**/

.btn-txt-black {
  padding-left: .125rem !important;
  padding-right: .125rem !important;
}

.Light .btn-txt-black {
  background: #00000000;
  border: 0;
  color: #000;
}

.Light .btn-txt-black:hover {
  background: #EEE;
}

.Light .btn-txt-black:active {
  background: #CCC;
}

.Dark .btn-txt-black {
  background: #FFFFFF00;
  border: 0;
  color: #EEE;
}

.Dark .btn-txt-black:hover {
  background: #EEEEEE22;
}

.Dark .btn-txt-black:active {
  background: #EEEEEE33 !important;
}

.btnSwitch-text,
.btnSwitch-icon,
.btnSwitch-icontext,
.btnSwitch-icontext>div {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
  appearance: none;
  border: none;
  cursor: pointer;
}

.btnSwitch-icon>*,
.btnSwitch-icontext>div {
  padding: .5rem;
}

.btnSwitch-text>* {
  padding: .5rem .75rem;
}

.btnSwitch-text>*:first-child,
.btnSwitch-icon>*:first-child,
.btnSwitch-icontext>div:first-child {
  border-radius: .25rem 0 0 .25rem;
}

.btnSwitch-text>*:last-child,
.btnSwitch-icon>*:last-child,
.btnSwitch-icontext>div:last-child {
  border-radius: 0 .25rem .25rem 0;
}

.btnSwitch-icon .icon,
.btnSwitch-icontext .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.btnSwitch-icontext .text {
  visibility: collapse;
}

.btnSwitch-icontext>div.selected .text {
  visibility: visible;
  margin-left: .25rem;
}

.btnSwitch-icontext.blue>* {
  background: #EBEEFB;
  outline: 1px solid #3D71F866;
}

.btnSwitch-icontext.blue>* .text {
  color: #9FBAFF;
}

.btnSwitch-icontext.blue>* .icon {
  background: #9FBAFF;
}

.btnSwitch-icontext.blue>*.selected,
.btnSwitch-text.blue>*.selected {
  background: #3D71F8;
  outline: 1px solid #3D71F8;
}

.btnSwitch-icontext.blue>*.selected .text,
.btnSwitch-text.blue>*.selected {
  color: #FFF;
}

.btnSwitch-icontext.blue>*.selected .icon {
  background: #FFF;
}

.btnSwitch-text.blue>* {
  background: #FFF;
  outline: 1px solid #3D71F8;
  color: #3D71F8;
  border: 0;
}

.btnSwitch-icon.whiteBlack>* {
  background: #FFF;
  outline: 1px solid #CCC;
  border: 0;
}

.btnSwitch-icon.whiteBlack .icon {
  background: #000;
}

.btnSwitch-icon.whiteBlack>*:hover {
  background: #F5F5F5;
  outline: 1px solid #CCC;
}

.btnSwitch-icon.whiteBlack>*:active {
  background: #EEE;
  outline: 1px solid #CCC;
}

.btnSwitch-icon.whiteBlack>*:focus {
  outline: 1px solid #CCC;
}

/*----------------------------------*/

.btn-blue {
  background: #EBEEFB;
  color: #9FBAFF;
  padding: 0.5rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.modal-footer-custom {
  display: block;
  padding: 0.5rem 1rem;
}

.modal-footer-custom .btn-gp {
  width: 100%;
  height: 2.375rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
}

.modal-form .btn-gp {
  height: 2rem;
}

.modal-form .btn-gp button {
  padding: 0;
}

.modal-footer-custom .btn-gp>div>button {
  margin-right: 0.5rem;
}

/*---跳出視窗的Footer-按鈕群組 START ↓ ↓ ↓ */
.button-group {
  height: 2.375rem;
  margin-top: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
}

.button-group>div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
}

.button-group>div>button {
  margin-right: 0.5rem;
}

.button-group button:not(.btn-txt-black) {
  padding: 0 1rem;
}

/*---跳出視窗的Footer-按鈕群組 END ↑ ↑ ↑ */


/*滑動軸*/
.table-scroll-justify {
  box-sizing: border-box;
}

@media (width <=1104px) {

  /*因為sticky-top功能在任何overflow功能存在時，就會失效，所以把overflow改成只有在較窄的螢幕才會出發的功能，觸發時sticky-top就會失效*/
  #power_programlist .table-scroll-justify>div {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.table-scroll-justify>div::-webkit-scrollbar {
  height: 0.25rem;
}

.table-scroll-justify>div::-webkit-scrollbar-button {
  background: transparent;
  border-radius: 50%;
}

.table-scroll-justify>div::-webkit-scrollbar-track-piece {
  background: transparent;
}

.table-scroll-justify>div::-webkit-scrollbar-thumb {
  border-radius: 0.125rem;
  background: rgba(159, 186, 255, 0.4);
}

.table-scroll-justify>div::-webkit-scrollbar-track {
  box-shadow: transparent;
}


.ul-Description {
  padding-left: 1rem;
  color: #3D71F8;
  font-size: .75rem;
  line-height: 1.5;
}

.ul-Description>li {
  list-style-type: disc;
}

/*「Apps小程式」首頁 START*/
.card-menu-div {
  background: #fff;
  padding: 1.25rem;
  padding-bottom: 1.5rem;
  border-radius: 0.5rem;
  display: block;
  cursor: pointer;
}

.card-menu {
  height: 16rem;
  border-radius: .25rem;
  display: grid;
  place-content: center;
}

*:hover>.card-menu {
  filter: brightness(0.9) contrast(1.2);
}

.card-menu>.icon {
  height: 5rem;
  width: 5rem;
  background: #FFF;
}

.card-menu-div .title {
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 1rem 0 .5rem 0;
}

.card-menu-div .text {
  font-size: .75rem;
  color: #3D71F8;
  line-height: 1.25;
  margin: 0;
}

/*「Apps小程式」首頁 END*/

@media (width<=1000px) {
  .rwd-noshow-1000 {
    display: none;
  }
}

/*這個要放在這個檔案最後面，不然會被吃掉*/
/*識別瀏覽的裝置為「手機」或「平板」 (以「coarse」識別為非精確點擊裝置(手機、平板)，以「fine」識別為使用滑鼠的精確點擊裝置)*/
@media (pointer: coarse) {
  .mobile-noshow {
    display: none !important;
  }
}