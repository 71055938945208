.APSTable tr {
    position: relative;
}

/* .APSTable thead tr th:hover .icon {
    background-color: #AAA;
    width: .5rem;
    height: .75rem;
} */

/* .APSTable thead tr th .icon-clicked {
    background-color: #AAA;
    width: .5rem;
    height: .75rem;
} */

.APSTable tr td.inline {
    display: inline;
}

.APSTable tbody td *:not(:last-child) {
    margin-right: .25rem;
}

.APSTable tbody tr .btns {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
    height: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(2px);
    /* 背景模糊效果 */
    -webkit-backdrop-filter: blur(2px);
    /* Safari 支援 */
}

.Light .APSTable tbody tr .btns {
    background-color: #EBEEFBAA;
}
.Dark .APSTable tbody tr .btns {
    background-color: #182D6388;
}

@media (max-width: 1200px) {
    .APSTable tbody tr .btns.tableFuncBtn {
        height: auto;
    }
}

.APSTable tbody tr:hover .btns {
    display: flex;
}

.APSTable tbody tr .btns>.btn-item {
    margin-left: .625rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

.Light .APSTable tbody tr .btns>.btn-item>div {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #00000088;
}

.Light .APSTable tbody tr .btns>.btn-item:hover {
    background-color: #00000011;
}

.Light .APSTable tbody tr .btns>.btn-item:active {
    background-color: #00000022;
}

.Light .APSTable tbody tr .btns>.btn-item:hover>div {
    background-color: #000000BB;
}

.Light .APSTable tbody .tr .btns>.btn-item:active>div {
    background-color: #000000CC;
}

.Light .APSTable tbody tr .btns>.btn-item.red:hover {
    background-color: #FFE9E9;
}

.Light .APSTable tbody tr .btns>.btn-item.red:active {
    background-color: #ffd8d8;
}

.Light .APSTable tbody tr .btns>.btn-item.red>div {
    background-color: #F66F6F;
}

.Light .APSTable tbody tr .btns>.btn-item.red:active>div {
    background-color: #e94b4b;
}

.Light .APSTable tbody tr .btns>.btn-item.red.disabled>div {
    background-color: #e94b4b44;
}


.Dark .APSTable tbody tr .btns>.btn-item>div {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #FFFFFF88;
}

.Dark .APSTable tbody tr .btns>.btn-item:hover {
    background-color: #00000066;
}

.Dark .APSTable tbody tr .btns>.btn-item:active {
    background-color: #000000AA;
    border: 1px solid #FFFFFF44;
}

.Dark .APSTable tbody tr .btns>.btn-item:hover>div {
    background-color: #FFFFFF88;
}

.Dark .APSTable tbody .tr .btns>.btn-item:active>div {
    background-color: #FFFFFF;
}

.Dark .APSTable tbody tr .btns>.btn-item.red:hover {
    background-color: #F66F6F44;
}

.Dark .APSTable tbody tr .btns>.btn-item.red:active {
    background-color: #e94b4bCC;
}

.Dark .APSTable tbody tr .btns>.btn-item.red>div {
    background-color: #F66F6F;
}

.Dark .APSTable tbody tr .btns>.btn-item.red:active>div {
    background-color: #000000AA;
}

.Dark .APSTable tbody tr .btns>.btn-item.red.disabled>div {
    background-color: #e94b4b44;
}













/*===========================
  --下方是第一版的.table樣式--
  ===========================*/
.APSTable {
    /* display: block;
    width: 100%; */
    font-size: .875rem;
    /* background-color: #FFF; */
    margin: 1rem 0;
    cursor: default;
    overflow-y: auto;
}

.APSTable .thead {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #888;
    /* background-color: #FFF; */
    font-size: .75rem;
    position: sticky;
    top: 0;
}

.APSTable .thead.sticky {
    position: sticky;
}

.APSTable .thead .tr,
.APSTable .tbody .tr {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    border-bottom: #CCC 1px solid;
    box-sizing: initial;
}

.APSTable .tbody .tr:hover {
    background-color: #F5F5F5;
}

.APSTable .tbody .tr.clicked {
    background-color: #EBEEFB;
}

.APSTable .thead .tr .th,
.APSTable .thead .tr .td {
    padding: .125rem .25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1;
    background: #FFF;
}

/* .APSTable .thead .tr .td:hover .icon {
    background-color: #AAA;
    width: .5rem;
    height: .75rem;
} */

/* .APSTable .thead .tr .td .icon-clicked {
    background-color: #AAA;
    width: .5rem;
    height: .75rem;
} */

.APSTable .tbody .tr .th,
.APSTable .tbody .tr .td {
    display: flex;
    padding: .75rem .25rem;
    align-items: center;
    word-break: break-all;
    white-space: pre-wrap;
}

.APSTable .tr .td.inline {
    display: inline;
}

.APSTable .tbody .td *:not(:last-child) {
    margin-right: .25rem;
}

.APSTable .tbody .tr .btns {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
    background-color: #F5F5F5DD;
    height: 100%;
    box-sizing: border-box;
}

.APSTable .tbody .tr:hover .btns {
    display: flex;
}

.APSTable .tbody .tr .btns>.btn-item {
    margin-left: .625rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

.APSTable .tbody .tr .btns>.btn-item>div {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #00000044;
}

.APSTable .tbody .tr .btns>.btn-item:hover {
    background-color: #00000011;
}

.APSTable .tbody .tr .btns>.btn-item:active {
    background-color: #00000022;
}

.APSTable .tbody .tr .btns>.btn-item:hover>div {
    background-color: #000000BB;
}

.APSTable .tbody .tr .btns>.btn-item:active>div {
    background-color: #000000CC;
}

.APSTable .tbody .tr .btns>.btn-item.red:hover {
    background-color: #FFE9E9;
}

.APSTable .tbody .tr .btns>.btn-item.red:active {
    background-color: #ffd8d8;
}

.APSTable .tbody .tr .btns>.btn-item.red>div {
    background-color: #F66F6F;
}

.APSTable .tbody .tr .btns>.btn-item.red:active>div {
    background-color: #e94b4b;
}

.APSTable .tbody .tr .btns>.btn-item.red.disabled>div {
    background-color: #e94b4b44;
}

.spaceHeight1 {
    height: 1rem;
    width: 100%;
}

.spaceHeight2 {
    height: 2rem;
    width: 100%;
}

.spaceHeight3 {
    height: 3rem;
    width: 100%;
}

.stickyTop {
    position: sticky;
    top: 0;
    z-index: 1600;
}

.stickyTop.bgWhite {
    background: #FFF;
}

.APSTable-gp {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
}

.APSTable-gp>div:last-child,
.APSTable-gp>form:last-child {
    width: calc(100% - .375rem);
}


.APSTable-gp>.APSTable {
    margin: 0;
}

.APSTable-gp .gpcolor-left {
    width: .375rem;
    min-width: .375rem;
    height: auto;
    border-radius: .375rem 0 0 .375rem;
}

.APSTable-gp .gpcolor-left.gpcolor-flattop {
    border-radius: 0 0 0 .375rem;
}

.APSTable-gp .gpcolor-left.gpcolor-flatbottom {
    border-radius: .375rem 0 0 0;
}

.APSTable-gp .gpcolor-left.gpcolor-flatall {
    border-radius: 0;
}

.APSTable-gp .APStable {
    min-height: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.APSTable-check {
    width: 1.5rem;
}


.APSTable .f1 {
    flex: 1 1 0;
}

.APSTable .f2 {
    flex: 2;
}

.APSTable .f3 {
    flex: 3;
}

.APSTable .f4 {
    flex: 4;
}

.APSTable .f5 {
    flex: 5;
}

.APSTable .w1 {
    width: 1rem;
}

.APSTable .w2 {
    width: 2rem;
}

.APSTable .strong2 {
    font-weight: 700;
}

.APSTable .strong3 {
    font-weight: 700;
}

.Light .APSTable .strong2 {
    color: #000;
}

.Light .APSTable .strong3 {
    color: #3D71F8;
}

.Dark .APSTable .strong2 {
    /* color: #c2d3ff; */
    color: #FFF;
}

.Dark .APSTable .strong3 {
    color: #608dff;
}

/*增修紀錄：10/26 增加狀態欄樣式*/
.APSTable .status {
    font-size: .75rem;
    line-height: 1;
}

.APSTable .status-padding {
    padding: .5rem .5rem;
    border-radius: .25rem;
    width: fit-content;
}

.APSTable .progress-aps {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.APSTable .progress-aps .chart {
    box-sizing: border-box;
    width: 70%;
    height: 1rem;
    border-radius: .25rem;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.APSTable .progress-aps .chart>div {
    height: 100%;
    border-radius: .25rem;
}

.APSTable .progress-aps .text {
    line-height: 1;
    margin-left: .5rem;
}

.APSTable .progress-aps .value {
    font-size: .75rem;
}

.APSTable .progress-aps .unit {
    font-size: .625rem;
    margin-left: 0;
}

/*color mode-Light*/
.Light .APSTable .progress-aps .chart-black {
    outline: #9FBAFF 1px solid;
    background: #D4E0FF;
}

.Light .APSTable .progress-aps .chart-gray {
    outline: #CCCCCC 1px solid;
    background: #F5F5F5;
}

.Light .APSTable .progress-aps .chart-black>div {
    background: #000;
}

.Light .APSTable .progress-aps .chart-gray>div {
    background: #CCCCCC;
}

.Light .APSTable .progress-aps .value-black {
    color: black;
}

.Light .APSTable .progress-aps .value-gray {
    color: #AAA;
}

.Light .APSTable .progress-aps .unit-black {
    color: #666;
}

.Light .APSTable .progress-aps .unit-gray {
    color: #CCC;
}

/*color mode-Dark*/
.Dark .APSTable .progress-aps .chart-black {
    outline: #9FBAFF 1px solid;
    background: #000;
}

.Dark .APSTable .progress-aps .chart-gray {
    outline: #666 1px solid;
    background: #FFFFFF22;
}

.Dark .APSTable .progress-aps .chart-black>div {
    background: #9FBAFF;
}

.Dark .APSTable .progress-aps .chart-gray>div {
    background: #888;
}

.Dark .APSTable .progress-aps .value-black {
    color: #FFF;
}

.Dark .APSTable .progress-aps .value-gray {
    color: #AAA;
}

.Dark .APSTable .progress-aps .unit-black {
    color: #9FBAFFDD;
}

.Dark .APSTable .progress-aps .unit-gray {
    color: #888;
}

/*排程結果字眼*/
.schedulingResults {
    font-size: 1rem;
    font-weight: 700;
}

/*延遲延後樣式*/
.schedulingDelayedTag {
    color: #fff;
    border-radius: 0.65rem;
    padding: 0 0.25rem;
    background-color: #F66F6F;
    font-size: 0.625rem;
    height: 1rem;
    word-break: keep-all;
}

/*「已開工」的圓點*/

div.dot {
    display: grid;
    place-content: center;

}

div.dot>div {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
}

div.dot>.green {
    background: #7CC629;
}